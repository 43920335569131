import { useGetOcrIdQuery } from 'actions/orc/queries';
import React from 'react';
import {  useParams } from 'react-router-dom';
import {DEFAULT_API_IMAGE } from 'routes/api/endpoints';

function DetailFacture(){
        const { id } = useParams();
        
        const { data: getOneDataFacture } = useGetOcrIdQuery({id});
        const image = getOneDataFacture?.facture_image
        console.log(getOneDataFacture?.produits_associes);
 
        
     
  return (
    <div
    className="global"
    style={{
      backgroundColor: 'white',
      minHeight: '500px',
      borderRadius: '20px',
      padding: '15px',
    }}
  >
    <div
        className="header mb-3"
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          flexWrap: 'wrap',
        }}
      >
       <nav aria-label="breadcrumb" className='mt-2'>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href='#' onClick={() => window.history.back()}>Facture</a></li>
          <li className="breadcrumb-item active" aria-current="page">Détail</li>
        </ol>
      </nav>
      </div>
      <a href={`${DEFAULT_API_IMAGE}${image}`} target="_blank" rel="noopener noreferrer">
      <img src={`${DEFAULT_API_IMAGE}${image}`} width="200" height="180" /></a>

      <div className="mb-4">Listes des produits de la factures</div>
      <div className="d-flex justify-content-between mb-3 mx-3 text-black">
        <div className="">ID: <span className="fw-bold">{getOneDataFacture?.facture_ID}</span></div>
        <div className="">Date d&lsquo;émission: <span className="fw-bold">{getOneDataFacture?.date_emission}</span></div>
        <div className="">Date d&lsquo;échéance: <span className="fw-bold">{getOneDataFacture?.date_echeance}</span></div>
        <div className="">Total: <span className="fw-bold">{getOneDataFacture?.total_ht} FCFA</span></div>
      </div>

      <div className="mx-lg-3 text-white">
        <table className="table text-white">
          <thead>
            <tr className="text-black">
              <th scope="col">Nom</th>
              <th scope="col">Quantité</th>
              <th scope="col">Prix unitaire</th>
            </tr>
          </thead>
          <tbody>
            {getOneDataFacture?.produits_associes?.length > 0 ? (
              getOneDataFacture?.produits_associes?.map((produit, index) => (
                <tr key={index}>
                  <td>{produit?.nom}</td>
                  <td>{produit?.quantite}</td>
                  <td>{produit?.prix} FCFA</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">Aucun produit trouvé</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DetailFacture;
