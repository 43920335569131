import React, {useState} from "react";
import check from "../image/check.png";
import slash from "../image/slash.png";
import eyes from "../image/eyes.png";
import archive from "../image/archive.png";
import search from "../image/search.png";
import supprimer from "../image/trashs.png";
import "./table.css";

const HeaderButton = ({
  isButtonsEnabled,
  onEyesClick,
  onClickArchive,
  onSearchChange,
  onActivedClick,
  onClickDelete,
  placeholder,
}) => {
  const [searchTerm, setSearchTerm] = useState(""); // Correction ici

  const handleSearchChange = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
    if (onSearchChange) {
      onSearchChange(newSearchTerm);
    }
  };
  return (
    <div style={{height: "50px"}}>
      <hr style={{margin: "0 0"}} />
      <div
        className="header"
        style={{
          display: "flex",
          alignItems: "center",
        }}>
        <div
          className="header-title"
          style={{
            padding: "15px",
            flex: 1,
            display: "flex",
            alignItems: "center",
          }}>
          <button
            // onClick={() => handleImageClick('check')}
            style={{
              marginRight: "25px",
              cursor: "pointer",
              border: "none",
              background: "none",
              padding: 0,
              ...(isButtonsEnabled
                ? {}
                : {pointerEvents: "none", opacity: 0.5}),
            }}>
            <img src={check} alt="Check" style={imgbutton} />
          </button>
          <button
            onClick={onActivedClick}
            title="Déactiver "
            style={{
              marginRight: "25px",
              cursor: "pointer",
              border: "none",
              background: "none",
              padding: 0,
              display: "none",
              ...(isButtonsEnabled
                ? {}
                : {pointerEvents: "none", opacity: 0.5}),
            }}>
            <img src={slash} alt="Slash" style={imgbutton} />
          </button>
          <button
            onClick={onEyesClick}
            title="Voir"
            style={{
              marginRight: "25px",
              cursor: "pointer",
              border: "none",
              background: "none",
              padding: 0,
              ...(isButtonsEnabled
                ? {}
                : {pointerEvents: "none", opacity: 0.5}),
            }}>
            <img src={eyes} alt="Eyes" style={imgbutton} />
          </button>
          <button
            onClick={onClickArchive}
            title="Archiver"
            style={{
              marginRight: "25px",
              cursor: "pointer",
              border: "none",
              background: "none",
              padding: 0,
              ...(isButtonsEnabled
                ? {}
                : {pointerEvents: "none", opacity: 0.5}),
            }}>
            <img src={archive} alt="Archive" style={imgbutton} />
          </button>

          <button
            onClick={onClickDelete}
            title="Supprimer"
            style={{
              marginRight: "25px",
              cursor: "pointer",
              border: "none",
              background: "none",
              padding: 0,
              ...(isButtonsEnabled
                ? {}
                : {pointerEvents: "none", opacity: 0.5}),
            }}>
            <img src={supprimer} alt="Archive" style={imgbutton} />
          </button>
        </div>

        <div
          style={{
            // flex: 1,
            display: "flex",
            alignItems: "center",
          }}>
          <img src={search} alt="Eyes" style={imgbutton} />
          <input
            type="text"
            className=" lineSeearch ps-2 py-2"
            placeholder={placeholder}
            style={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "13px",
              border: "none",
              outline: "none",
            }}
            value={searchTerm} // Correction ici
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </div>
      </div>
      <hr style={{margin: "0 0"}} />
    </div>
  );
};

const imgbutton = {
  width: "18px",
  height: "18px",
};
export default HeaderButton;
