// import { useCreateClientMutation } from 'actions/dossiers/mutations';
import { useCreateOcrMutation } from 'actions/orc/mutations';
import { useGetOcrQuery } from 'actions/orc/queries';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FaRegTrashAlt } from 'react-icons/fa';
import { GoEye } from 'react-icons/go';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import HeaderFirst from 'utils/HeaderFirst';

const Facture = () => {
  const [creationFacture] = useCreateOcrMutation();
  const { data: getDataFacture } = useGetOcrQuery();
  const { clientID } = useParams();

  let totalMontantTotal = 0;

  getDataFacture?.data?.forEach((facture) => {
    totalMontantTotal += facture?.montant_total;
  });

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('image', data.image[0]);
    formData.append('dossier_id', clientID);

    // Envoyer les données à l'API
    creationFacture(formData)
      .unwrap()
      .then((res) => {
        console.log(res);
        toast.success('Création facture réussie !');
      })
      .catch((error) => {
        toast.warning('Échec !');
        console.log(error);
      });
  };
  const nom = (
    <div style={{ display: 'flex' }}>
      <h4 className="titre-dossier">Factures</h4>
      <span className="nbrDossier">{getDataFacture?.count}</span>
    </div>
  );

  const description = <div>Extraire les données de vos Factures</div>;

  return (
    <div
      className="global"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <div
        className="header mb-3"
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          flexWrap: 'wrap',
        }}
      >
        <HeaderFirst nom={nom} description={description} />
      </div>
      <form
        action=""
        method="post"
        style={{ padding: '15px' }}
        encType="multipart/form-data"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input type="hidden" />
        <div className="row w-100 gap-4 align-items-center justify-content-between">
          <div className="w-25">
            <div className="mb-3">
              <input
                type="file"
                name="image"
                {...register('image', { required: 'Nom obligatoire' })}
              />
            </div>
            <button
              type="submit"
              className="btn btn-outline-dark"
              style={{
                borderRadius: '31px',
                border: '1px solid #AEAEAE',
                padding: '8px',
              }}
              // onClick={openModal}
            >
              <span style={ajoutFou}>+ Extraire la facture</span>
            </button>
          </div>
        </div>
      </form>
      <div className="mb-5 mt-4 py-0" style={{ padding: '15px' }}>
        La somme totale de vos factures:{' '}
        <span style={{ fontWeight: '700' }}>{totalMontantTotal} Fcfa</span>
      </div>
      <div className="mx-lg-3 text-white">
        <table className="table text-white">
          <thead>
            <tr className="text-black">
              <th scope="col">ID</th>
              <th scope="col">date d&lsquo;emission</th>
              <th scope="col">date d&lsquo;echeance</th>
              <th scope="col">Total facture</th>
              <th scope="col">Détails</th>
            </tr>
          </thead>
          <tbody>
            {getDataFacture?.data?.map((facture) => (
              <tr key={facture?.facture_ID} className="factures_row">
                <th scope="row" className="pt-4">
                  {facture?.facture_ID}
                </th>
                <td className="pt-4">{facture?.date_emission}</td>
                <td className="pt-4">{facture?.date_echeance}</td>
                <td className="pt-4">{facture?.montant_total} Fcfa</td>
                <td className="pt-4">
                  <a href={`${facture?.facture_ID}`}>
                    <GoEye className="text-black fs-5" title="Détail" />
                  </a>
                  <FaRegTrashAlt
                    className="text-danger mx-2 fs-5"
                    title="Archiver"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
const ajoutFou = {
  fontFamily: 'Montserrat',
  fontWeight: '600',
  fontSize: '13px',
};

export default Facture;
