import React, {useState, useEffect} from "react";
import "./parametre.css";
import HeaderFirst from "utils/HeaderFirst";
import {useSelector} from "react-redux";
import {getUser} from "redux/features/userSliceWithTokenValidation";
import {useParams} from "react-router-dom";
import {useForm, Controller} from "react-hook-form";
import {toast} from "react-toastify";
import InputDetail from "components/Inputs/InputDetail";
import {useGetDossierQuery} from "actions/dossiers/queries";
import {useUpdateDossierClientMutation} from "actions/dossiers/mutations";

const ParametrePage = () => {
  const {control, handleSubmit, reset} = useForm();
  const userStatus = useSelector(getUser);
  const comptableId = userStatus?.id;
  const {clientID: id} = useParams();
  const {data: dossierData, refetch} = useGetDossierQuery(comptableId);
  const [updateDossierMutation] = useUpdateDossierClientMutation();
  const [logoUrl, setLogoUrl] = useState("");

  useEffect(() => {
    if (dossierData && dossierData.data && dossierData.data.length > 0) {
      const dossier = dossierData.data[0];
      setLogoUrl(dossier.logo);
      reset({
        nom_entreprise: dossier.nom_entreprise,
        adresse: dossier.adresse,
        telephone: dossier.telephone,
        email: dossier.email,
        description: dossier.description,
      });
    }
  }, [dossierData, reset]);

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogoUrl(URL.createObjectURL(file));
    }
  };

  const handleCancel = async () => {
    await refetch();
    toast.info("Modifications annulées");
  };

  const nom = (
    <div style={{display: "flex"}}>
      <h4 className="titre-dossier">
        <div>Param&egrave;tre</div>
      </h4>
    </div>
  );

  const onSubmit = async (formData) => {
    console.log("Valeurs des états lors de la soumission :", formData);

    try {
      // Utilisez formData au lieu des variables d'état individuelles
      if (dossierData) {
        const formDataToSend = new FormData();
        formDataToSend.append("archived", false);
        formDataToSend.append("id", dossierData?.data?.[0]?.id);
        formDataToSend.set("nom_entreprise", formData.nom_entreprise);
        formDataToSend.set("adresse", formData.adresse);
        formDataToSend.set("telephone", formData.telephone);
        formDataToSend.set("description", formData.description);

        if (formData.email) {
          formDataToSend.set("email", formData.email);
        }

        formDataToSend.set("description", formData.description);

        if (formData?.logo instanceof File) {
          formDataToSend.set("logo", formData.logo);
        }

        try {
          const res = await updateDossierMutation({
            id: id,
            body: formDataToSend,
            token: userStatus?.access,
          });
          console.log(
            "Nouvelles données après la mise à jour :",
            res.data.logo
          );
          refetch();

          toast.success("Informations du dossier modifié avec succès");
          console.log("response", res);
        } catch (error) {
          console.error("Échec de la modification", error);
        }
      }
    } catch (error) {
      console.error("Erreur de mutation:", error.message);
      toast.error("Erreur lors de la mise à jour du dossier.");
    }
  };

  return (
    <div
      className="global parametre-container"
      style={{
        backgroundColor: "white",
        minHeight: "500px",
        borderRadius: "20px",
        padding: "15px",
      }}>
      <HeaderFirst
        nom={nom}
        description={
          <div>Vous pouvez param&eacute;trer ici le dossier du client</div>
        }
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form-layout d-flex flex-column gap-3"
        style={{padding: "15px"}}>
        <div className="row">
          <div className="col-md-6">
            <Controller
              name="nom_entreprise"
              control={control}
              defaultValue={dossierData?.data?.[0]?.nom_entreprise || ""}
              render={({field}) => (
                <InputDetail label="Nom de l&#39;entreprise" {...field} />
              )}
            />
          </div>
          <div className="col-md-6">
            <Controller
              name="adresse"
              control={control}
              defaultValue={dossierData?.data?.[0]?.adresse || ""}
              render={({field}) => (
                <InputDetail label="Adresse de l&#39;entreprise" {...field} />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Controller
              name="telephone"
              control={control}
              defaultValue={dossierData?.data?.[0]?.telephone || ""}
              render={({field}) => (
                <InputDetail label="Numéro de téléphone" {...field} />
              )}
            />
          </div>
          <div className="col-md-6">
            <Controller
              name="email"
              control={control}
              defaultValue={dossierData?.data?.[0]?.email || ""}
              render={({field}) => <InputDetail label="E-mail" {...field} />}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Controller
              name="description"
              control={control}
              defaultValue={dossierData?.data?.[0]?.description || ""}
              render={({field}) => (
                <InputDetail
                  label="Description de l&#39;entreprise"
                  isTextarea={true}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="galerie col-md-4 d-flex flex-column gap-1">
            <span
              style={{color: "#068F7A", fontWeight: "bold"}}
              className="form-label">
              Modifier votre logo
            </span>
            <div className="card cardImage text-center">
              <label htmlFor="imageInput" style={{cursor: "pointer"}}>
                <img src={logoUrl} alt="Logo" style={{width: "100%"}} />
                <input
                  type="file"
                  id="imageInput"
                  accept="image/*"
                  style={{display: "none"}}
                  onChange={handleLogoChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="form-navigation-buttons d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-danger me-2 rounded-pill"
            onClick={handleCancel}>
            Annuler
          </button>
          <button type="submit" className="next-button">
            Enregistrer
          </button>
        </div>
      </form>
    </div>
  );
};

export default ParametrePage;
