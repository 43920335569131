import React, { useState, useEffect } from 'react';
import HeaderFirst from 'utils/HeaderFirst';
import InputDetail from 'components/Inputs/InputDetail';
import { toast } from 'react-toastify';
import ButtonP from 'components/buttons/ButtonP';
import { useGetJournalIdQuery, useGetTiersByDossierClientQuery } from 'actions/sage_clone/journaux/queries';
import {  useUpdateLivreJournalMutation } from 'actions/sage_clone/journaux/mutations';
import Ecriture from './Ecriture/Ecriture';
import { useGetCodesQuery } from 'actions/comptabilite/queries';
import { useGetCodeJournalQuery } from 'actions/sage_clone/code_journaux/queries';
import Etat from './Etats/GenererEtats';
import { useGetEcritureByLivreIdQuery } from 'actions/sage_clone/ecritures/queries';
import { useLocation, useNavigate } from 'react-router-dom'
import "./enregistrer.css"
import AddPieceJointe from './Ecriture/AddPieceJointe';

function Saisie() {

  const {state} = useLocation()
  const navigate = useNavigate()

  const [facture, setFacture] = useState({
    compte_general: 0,
    compte_tiers: 0,
    libelle: "",
    date: "",
    debit: 0,
    credit: 0,
    livre_journal: state.journalId,
    id:0
  });

  //Les codes
  const {data: codesResponse = []} = useGetCodesQuery({dossier_client:state.dossierId});
  const {data: codesTiersResponse=[]} = useGetTiersByDossierClientQuery({dossier_id:state.dossierId})

  const {
      data: codeJournalResponse= [],
    } = useGetCodeJournalQuery();
   
  const {
    data: journalData,
    isFetching,
    error,
    refetch
  } = useGetJournalIdQuery({ id: state.journalId })

  const [updateClient] = useUpdateLivreJournalMutation();

  const {data: ecritures} = useGetEcritureByLivreIdQuery({
    livre_journal: state.journalId,
  })


  useEffect(() => {
    refetch()
  },[ecritures])

  const [clientDetails, setClientDetails] = useState({
    numero_facture: '',
    reference: '',
    date: new Date(),
    total_credits: 0,
    total_debits: 0
  });


  useEffect(() => {
    if (journalData) {
      setClientDetails(journalData?.data[0]);
    }
  }, [journalData]);


  const saveChanges = async () => {
    try {
      await updateClient({
        id: state.journalId,
        ...clientDetails,
        file:null
      }).unwrap();
      toast.success('Mise à jour réussie.');
      
    } catch (error) {
      toast.error(
        "Une erreur s'est produite lors de la mise à jour."
      );
    }
  };

  if (isFetching) return <div>Chargement...</div>;
  if (error) return <div>Une erreur s&#39;est produite.</div>;

  const handleSaveLivre = () => {
    if(journalData.data[0].total_credits == journalData.data[0].total_debits){
      navigate(-1)
    }
    else{
      toast.error(
        "Le livre n'est pas équilibré. Veuillez revérifier vos écritures"
      );
    }
  }

  return (
    <div
      className="global client-detail-container"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <HeaderFirst
        nom={
          <h4 className="titre-dossier">
            Journal <span style={{color:"#009688"}}>{state.title}</span>  : {clientDetails.reference || 'Inconnu'}
          </h4>
        }
        description={
          <div>
            Vous pouvez ici visualiser ou modifier les détails du client.
          </div>
        }
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          saveChanges();
        }}
        className="d-flex flex-column gap-2"
        style={{ padding: '15px' }}
      >
        <div className="row">
          <div className="col-md-3">
            <InputDetail
              label="Numéro Facture"
              name="numero_facture"
              value={clientDetails.numero_facture}
              onChange={(e) =>
                setClientDetails({
                  ...clientDetails,
                  numero_facture: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
          <div className="col-md-3">
            <InputDetail
              label="Référence"
              name="reference"
              value={clientDetails.reference}
              onChange={(e) =>
                setClientDetails({
                  ...clientDetails,
                  reference: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
          {/*<div className="col-md-3">
            <InputDetail
              type="date"
              label="Date"
              name="date"
              value={clientDetails.date}
              onChange={(e) =>
                setClientDetails({
                  ...clientDetails,
                  date: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>*/}
          
          <div className="col-md-3 pt-3">
            <ButtonP type="submit" text="Modifier" />
          </div>
        </div>


      </form>
        {/* Ajouter une ecriture comptable */}
        <hr />
        <Ecriture
          codes={codesResponse || []}
          codesTiers={codesTiersResponse || []}
          codeJournalResponse={codeJournalResponse.data || []}
          livre_journal={state.journalId}
          facture={facture} 
          setFacture={setFacture}
        >
          <h2
            style={{color: " #009688", fontSize: "20px", fontWeight: 700}}>
            &nbsp;CREER UN NOUVEAU DOSSIER CLIENT
          </h2>
        </Ecriture>
        {/**********************************/}


        <hr />
        {/* Affichage des écritures liées au journal*/}
        <Etat 
          ecritures={ecritures}
          id_journal={state.journalId}
          setFacture={setFacture}
        />
        {/**********************************/}
        <AddPieceJointe journal_id={state.journalId} dossier_id={state.dossierId} />

        <div style={{
          display:"flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
          gap: "20px"
        }}>
          <button
            onClick={handleSaveLivre}
            style={{borderRadius:"8px",padding:"5px 50px"}}
            className='enregistrer'
          >
            Enregistrer
          </button>
          <button
            onClick={() => navigate(-1)}
            style={{borderRadius:"8px",padding:"5px 50px"}}
            className='enregistrer'
          >
            Annuler
          </button>
        </div>
    </div>
  );
}




export default Saisie;

