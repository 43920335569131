import { ApiMananger } from "actions/rtkquery";
import { ADD_PRODUITS_API_ROUTE, DELETE_PRODUITS_API_ROUTE, UPDATE_PRODUITS_API_ROUTE } from 'routes/api/endpoints';

const produitsApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    // Mutation for creating new Produit instances
    addProduit: build.mutation({
      query: (produitData) => ({
        url: ADD_PRODUITS_API_ROUTE, // Ensure this route points to your 'produits/' endpoint
        method: 'POST',
        body: produitData,
      }),
      invalidatesTags: ['INVALIDATE_PRODUITS'],
    }),

    // Mutation for updating an existing Produit instance
    updateProduit: build.mutation({
      query: ({ id, ...updateData }) => ({
        url: `${UPDATE_PRODUITS_API_ROUTE}${id}/`, // Construct the URL with the produit ID
        method: 'PUT',
        body: updateData,
      }),
      invalidatesTags: ['INVALIDATE_PRODUITS'],
    }),

    // Mutation for deleting an existing Produit instance
    deleteProduit: build.mutation({
      query: (id) => ({
        url: `${DELETE_PRODUITS_API_ROUTE}${id}/`, // Construct the URL with the produit ID
        method: 'DELETE',
      }),
      invalidatesTags: ['INVALIDATE_PRODUITS'],
    }),
  }),
  overrideExisting: false,
});

// Export hooks for using these mutations
export const {
  useAddProduitMutation,
  useUpdateProduitMutation,
  useDeleteProduitMutation,
} = produitsApi;
