import React from 'react';
import Modal from 'react-modal';
import './ConfirmationModal.css';

// You might need to set the app element for accessibility reasons, typically done once in your application root
Modal.setAppElement('#root');

const ConfirmModal = ({
  isOpen,
  onRequestClose,
  onConfirm,
  title,
  message,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Confirmation"
    className="Modal"
    overlayClassName="Overlay"
  >
    <h2>{title}</h2>
    <p>{message}</p>
    <div className="d-flex gap-3">
      <button onClick={onConfirm} className="btn btn-danger">
        Confirmer
      </button>
      <button onClick={onRequestClose} className="btn btn-secondary">
        Annuler
      </button>
    </div>
  </Modal>
);

export default ConfirmModal;
