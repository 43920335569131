import {useEffect, useState} from "react";
/*import {
  useUpdateCollaborateurMutation,
  useDeleteCollaborateurMutation,
} from 'actions/Collaborateurs/mutations';*/
import {toast} from "react-toastify";
//import { useGetCollaborateurQuery } from 'actions/Collaborateurs/queries';
import {useGetCollaborateurQuery} from "actions/collaborateur/queries";
import {
  useDeleteCollaborateurMutation,
  useUpdateCollaborateurMutation,
} from "actions/collaborateur/mutation";
import useGetCurrentUser from "hooks/useGetCurrentUser";

const useCollaborateurData = () => {
  const {
    data: collaborateurResponse,
    isLoading: isLoadingCollaborateur,
    refetch,
  } = useGetCollaborateurQuery();
  const currentUser = useGetCurrentUser();

  const [updateCollaborateur] = useUpdateCollaborateurMutation();
  const [deleteCollaborateur] = useDeleteCollaborateurMutation();

  const [updatedCollaborateurs, setUpdatedCollaborateurs] = useState([]);

  useEffect(() => {
    if (
      !isLoadingCollaborateur &&
      collaborateurResponse?.data &&
      Array.isArray(collaborateurResponse.data)
    ) {
      const enhancedCollaborateurs = collaborateurResponse.data.map(
        (collaborateur) => ({
          ...collaborateur,
          displayName: `${collaborateur.nom} (${collaborateur.id})`,
        })
      );
      setUpdatedCollaborateurs(
        enhancedCollaborateurs.filter(
          (collaborateur) =>
            collaborateur?.encadreur_expert_comptable === currentUser.id
        )
      );
      refetch();
    }
  }, [isLoadingCollaborateur, collaborateurResponse, refetch]);

  const handleUpdateCollaborateur = async (collaborateurId, updateData) => {
    try {
      await updateCollaborateur({
        id: collaborateurId,
        ...updateData,
      }).unwrap();
      refetch(); // Refetch Collaborateur data from the backend
    } catch (error) {
      toast.error(`Update error: ${error.data?.error || error.message}`);
      console.error("Request failed:", error);
    }
  };

  const handleDeleteCollaborateur = async (collaborateurId) => {
    try {
      await deleteCollaborateur({id: collaborateurId}).unwrap();
      toast.success("Collaborateur supprimé avec succès.");
      refetch();
    } catch (error) {
      console.log(collaborateurId);
      toast.error(`Deletion error: ${error.data?.error || error.message}`);
    }
  };

  return {
    updatedCollaborateurs,
    handleUpdateCollaborateur,
    handleDeleteCollaborateur,
    refetch,
  };
};

export default useCollaborateurData;
