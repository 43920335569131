import React from 'react';
import PaimentCardForm from './PaimentCardForm';
import bank from '../../image/Ellipse 19.png';
import batim from '../../image/Ellipse 18.png';
import wave from '../../image/Ellipse 17.png';
import orangeMoney from '../../image/Ellipse 16.png';

export default function PaimentDetail({
  totalCaisse,
  totalBanque,
  totalWave,
  totalOrangeMoney,
  onCardClick,
}) {
  return (
    <div>
      <div className="d-flex flex-wrap flex-lg-nowrap gap-3">
        <PaimentCardForm
          somme={totalCaisse}
          explain={'Caisse'}
          icon={<img src={bank} />}
          color={`#009688`}
          onClick={() => onCardClick('caisse')}
        />
        <PaimentCardForm
          somme={totalBanque}
          explain={'Banque'}
          icon={<img src={batim} />}
          color={'#283767'}
          onClick={() => onCardClick('banque')}
        />
        <PaimentCardForm
          somme={totalWave}
          explain={'Wave'}
          icon={<img src={wave} className="bg-white p-1 rounded-circle" />}
          color={'#1DC8FF'}
          onClick={() => onCardClick('wave')}
        />
        <PaimentCardForm
          somme={totalOrangeMoney}
          explain={'Orange Money'}
          icon={
            <img src={orangeMoney} className="bg-white p-1 rounded-circle" />
          }
          color={'#FF9F43'}
          onClick={() => onCardClick('orange_money')}
        />
      </div>
    </div>
  );
}
