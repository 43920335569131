import { ApiMananger } from 'actions/rtkquery';
import {
  ADD_CODEJOURNAUX_API_ROUTE,
  UPDATE_CODEJOURNAUX_API_ROUTE,
  DELETE_CODEJOURNAUX_API_ROUTE
} from 'routes/api/endpoints';

const codeJournauxApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    // Mutation for creating a new fournisseur instance
    createCodeJournal: build.mutation({
      query(body) {
        return {
          url: ADD_CODEJOURNAUX_API_ROUTE,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['CODE_JOURNAL'],
    }),

    // Mutation for updating an existing fournisseur instance
    updateCodeJournal: build.mutation({
      query: ({ id, ...updateData }) => ({
        url: `${UPDATE_CODEJOURNAUX_API_ROUTE}${id}/`,
        method: 'PUT',
        body: updateData,
      }),
      invalidatesTags: ['CODE_JOURNAL'], // Invalidate the cache tags to refetch fournisseur-related data
    }),

    // Mutation for deleting an existing fournisseur instance
    deleteCodeJournal: build.mutation({
      query: ({id}) => ({
        url: `${DELETE_CODEJOURNAUX_API_ROUTE}${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CODE_JOURNAL'], // Invalidate to refetch fournisseur-related data
    }),

    // More fournisseur-related mutations and queries can be added here...
  }),
  overrideExisting: false,
});

export const {
  useCreateCodeJournalMutation,
  useUpdateCodeJournalMutation,
  useDeleteCodeJournalMutation,
} = codeJournauxApi;
