import React from 'react';

function TransactionDetails({ transactions, fournisseurId, produitsData }) {
  function formatDate(datetime) {
    const date = new Date(datetime);
    const day = date.getDate().toString().padStart(2, '0');
    const monthNames = [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month}, ${year}`;
  }
  return (
    <div style={{ padding: '15px' }}>
      <h3 className="py-2">Transactions du fournisseur</h3>
      {transactions &&
      transactions.transactions &&
      Array.isArray(transactions.transactions) &&
      transactions.transactions.filter(
        (transaction) =>
          transaction.fournisseurs_details &&
          transaction.fournisseurs_details.id === parseFloat(fournisseurId)
      ).length > 0 ? (
        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Produits</th>
              <th>Quantité</th>
              <th>Prix unitaire (FCFA)</th>
              <th>Type</th>
              <th>Montant Total (FCFA)</th>
            </tr>
          </thead>
          <tbody>
            {transactions.transactions
              .filter(
                (transaction) =>
                  transaction.fournisseurs_details &&
                  transaction.fournisseurs_details.id ===
                    parseFloat(fournisseurId)
              )
              .map((filteredTransaction) => (
                <tr key={filteredTransaction.id}>
                  <td>{formatDate(filteredTransaction.created_at)}</td>
                  <td className="align-middle">
                    <ul className="list-unstyled mb-0">
                      {filteredTransaction.produits_associes.map((produit) => (
                        <li key={produit.produit}>
                          {produitsData?.data.find(
                            (p) => p.id === produit.produit
                          )?.nom || 'Nom inconnu'}
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td className="align-middle">
                    <ul className="list-unstyled mb-0">
                      {filteredTransaction.produits_associes.map((produit) => (
                        <li key={produit.produit}>{produit.quantite}</li>
                      ))}
                    </ul>
                  </td>
                  <td className="align-middle">
                    <ul className="list-unstyled mb-0">
                      {filteredTransaction.produits_associes.map((produit) => (
                        <li key={produit.produit}>{produit.prix} Fcfa</li>
                      ))}
                    </ul>
                  </td>
                  <td style={{ color: '#991B1B' }} className="fw-bold">
                    {filteredTransaction.type}
                  </td>
                  <td>{filteredTransaction.montant_total} Fcfa</td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <div>Aucune transaction trouvée.</div>
      )}
    </div>
  );
}

export default TransactionDetails;
