import { ApiMananger } from "actions/rtkquery";
import { GET_CODEJOURNAUX_API_ROUTE } from "routes/api/endpoints";

const sageApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({

    getCodeJournal: build.query({
        query: ({comptable}) => `${GET_CODEJOURNAUX_API_ROUTE}?comptable=${comptable}`,
        providesTags: ["INVALIDATE_CODEJOURNAL"],
    }),
    getCodeJournalId: build.query({
        query: ({ id}) => `${GET_CODEJOURNAUX_API_ROUTE}?id=${id}`,
        providesTags: ["INVALIDATE_CODEJOURNAL"],
    }),
}),
});

export const {
useGetCodeJournalIdQuery,
useGetCodeJournalQuery
} = sageApi;
