import SpliTemplateScreen from 'layouts'
// import { useAppContext } from 'layouts/appConexte'
import NavbarComponent from 'layouts/navbar/NavbarComponent'
import SidebarDossierComponent from 'layouts/sidebarDossierCient/SidebarDossierComponent'
// import SidebarDossierComponent from 'layouts/sidebarDossierCient/SidebarDossierComponent'
import React from 'react'
import { Outlet } from 'react-router-dom'

const DashboardAppShellClient = () => {
 
  return (
    <SpliTemplateScreen>
      {/* <NavbarComponent logo={logo}/> */}
      <NavbarComponent />
      <SidebarDossierComponent
        activeLinkColor={"#FFFFFF"}
        activeLinkBgColor={"#009688"}
        colorOnHover={"white"}
        linkColor="#4B5563"
        color="#4B5563"
        />
      <Outlet 
      
      />
    </SpliTemplateScreen>
  )
}

export default DashboardAppShellClient