import React from "react"

/**
 * ! Personnaliser votre erreur boundary
 *
 * @return {JSX}
 */
const ErrorBoundary = () => {
    return (
        <div>ErrorBoundary</div>
    )
}

export default ErrorBoundary