import React, {useEffect,useRef} from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { usePDF } from 'react-to-pdf';

import Table from "react-bootstrap/Table";
import "./style/style.css";
import {useGetBalance_CabinetQuery} from "actions/cabinet/queries";

const Balance = ({dossier_id}) => {
  const {data: balance, refetch} = useGetBalance_CabinetQuery({
    dossier_id: dossier_id,
  });

   // pour excel
   const tableRef = useRef(null);
   const { onDownload } = useDownloadExcel({
     currentTableRef: tableRef.current,
     filename: 'Balance Générale table',
     sheet: 'BalanceGénérale'
   })  
 
   // pour pdf
   const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});
 
   const myRef = useRef(null);
 
 
   useEffect(function() {
     tableRef.current = myRef.current;
     targetRef.current = myRef.current;
   }, [myRef.current]);

    useEffect(() => {
      refetch();
    }, [balance]);

  console.log(balance);
  return (
    <div style={{marginLeft: "20px"}}>
      <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
        <h1
          style={{
            fontSize: "25px",
            fontWeight: "bold",
            marginLeft: "0px",
            padding: "10px 0px",
          }}>
          Balance Générale
        </h1>
        <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
          <button onClick={onDownload}> Export excel </button>
          <button onClick={() => toPDF()}>Download PDF</button>
        </div>

      </div>
      <Table striped bordered hover ref={myRef}>
        <thead>
          <tr>
            <th>Numéro Compte</th>
            <th>Libellé</th>
            <th>Débit</th>
            <th>Crédit</th>
            <th>Solde_Débiteur</th>
            <th>Solde_Créditeur</th>
          </tr>
        </thead>
        <tbody>
          {
            balance &&
            balance["balance"]?.map((ecriture, index) => {
                return(
                  <tr key={index}>
                    <td>{ecriture["numero_comptable"]}</td>
                    <td>{ecriture["libelle"]}</td>
                    <td>{ecriture["debit"]}</td>
                    <td>{ecriture["credit"]}</td>
                    <td>
                        {
                            ecriture["solde_debiteur"] == 0?(
                                <>-</>
                          ):(
                              <>{ecriture["solde_debiteur"]}</>
                          )
                      }
                  </td>
                  <td>
                      {
                          ecriture["solde_crediteur"] == 0?(
                              <>-</>
                          ):(
                              <>{ecriture["solde_crediteur"]}</>
                          )
                      }
                  </td>
                  
                </tr>
              )
            })
          }
          {
              balance &&(
                  <tr>
                      <td>-</td>
                      <td className='data'>TOTAL</td>
                      <td className='data'>{balance["total_debits"]}</td>
                      <td className='data'>{balance["total_credits"]}</td>
                      <td className='data'>{balance["total_crediteur"]}</td>
                      <td className='data'>{balance["total_debiteur"]}</td>
                      
                  </tr>
              )
          }
          
        </tbody>
      </Table>
    </div>
  );
};

export default Balance;
