import React from "react";
import "./button.css";

export default function ButtonWhite({
  type,
  text,
  disabled,
  onClick,
  className,
}) {
  return (
    <div>
      <button
        type={type}
        className={`add-button ${className} rounded-pill`}
        disabled={disabled}
        onClick={onClick}>
        {text}
      </button>
    </div>
  );
}
