import React from 'react';
import { BsFileEarmarkExcelFill } from 'react-icons/bs';

function FilePreview({ filePath }) {
  if (!filePath) {
    return <span className="fw-bold fs-6">Pas de pièce jointe.</span>;
  }

  const fileName = filePath.split('/').pop();
  const fileExtension = fileName.split('.').pop().toLowerCase();

  const renderPreview = () => {
    switch (fileExtension) {
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        return (
          <img
            src={filePath}
            alt={`Aperçu de ${fileName}`}
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        );
      case 'pdf':
        return (
          <div>
            <iframe
              src={filePath}
              style={{ width: '100%', height: '250px' }}
              frameBorder="0"
              aria-label={`Aperçu du fichier ${fileName}`}
            />
            <a
              href={filePath}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary fw-bold text-white fs-6"
              style={{
                backgroundColor: '#068f7a',
                borderColor: '#068f7a',
              }}
            >
              Ouvrir le fichier
            </a>
          </div>
        );
      case 'xlsx':
      case 'xls':
        return (
          <div className="d-flex gap-3 flex-column justify-content-between">
            <div className="d-flex flex-column align-items-center gap-3">
              <BsFileEarmarkExcelFill className="fs-1 text-success" />
              <span>{fileName}</span>
            </div>
            <a
              href={filePath}
              download
              className="btn btn-primary fw-bold text-white fs-6"
              style={{
                backgroundColor: '#068f7a',
                borderColor: '#068f7a',
              }}
            >
              Télécharger le fichier
            </a>
          </div>
        );
      default:
        return (
          <p>
            Format de fichier non pris en charge pour l&apos;aperçu.{' '}
            <a href={filePath} download>
              Téléchargez le fichier
            </a>
          </p>
        );
    }
  };

  return <div>{renderPreview()}</div>;
}

export default FilePreview;
