import { ApiMananger } from "actions/rtkquery";
import { GET_FACTURE_CABINET_API_ROUTE } from "routes/api/endpoints";

const factureApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({

    getFacture: build.query({
        query: () => `${GET_FACTURE_CABINET_API_ROUTE}`,
        providesTags: ["INVALIDATE_FACTURE"],
    }),
    getFactureId: build.query({
        query: ({ id }) => `${GET_FACTURE_CABINET_API_ROUTE}?id=${id}`,
        providesTags: ["INVALIDATE_FACTURE"],
    }),
    getFactureByDossierClientId: build.query({
        query: (id) => `${GET_FACTURE_CABINET_API_ROUTE}?dossier_client=${id}`,
        providesTags: ["INVALIDATE_FACTURE"],
    }),
    
}),
});

export const {
useGetFactureIdQuery,
useGetFactureQuery,
useGetFactureByDossierClientIdQuery
} = factureApi;
