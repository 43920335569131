import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderFirst from 'utils/HeaderFirst';
import DepensesTable from './DepensesTable'; // Assume you've created this component
import useDepensesData from './useDepensesData'; // Custom hook for fetching and enhancing data
import './client.css'; // Adjust the CSS import path as needed
import { ADD_DEPENSE } from 'routes/navigation/navigationPaths';
import HeaderButton from 'utils/headerButton';
import TablePaginators from 'components/Pagination/TablePaginator';
import { toast } from 'react-toastify';

const DepensesPage = () => {
  const { clientID: depense_id } = useParams();
  console.log(depense_id);
  const { updatedDonnees, handleUpdateDepense } = useDepensesData(depense_id);
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [serachTerm, setSearchedTerm] = useState('');
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [viewArchived, setViewArchived] = useState(false);

  // Define the action handler for TablePaginator
  const handleActionSelected = (action) => {
    if (action === 'archive') {
      setViewArchived(!viewArchived); // Toggle between viewing archived and active depenses
    } else {
      console.log(`Action selected: ${action}`);
      // Handle other actions as necessary
    }
  };

  const filteredData = updatedDonnees
    .filter((depense) => (viewArchived ? depense.archived : !depense.archived))
    .filter((depense) =>
      depense.fournisseurs_details?.nom
        ?.toLowerCase()
        .includes(serachTerm.toLowerCase())
    );

  const currentPageData = filteredData.slice(
    paginationIndex * pageSize,
    (paginationIndex + 1) * pageSize
  );

  const onEyesClick = () => {
    // Assuming only one row can be selected at a time for viewing details
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      navigate(`detailDepense/${selectedRowId}?edit=true`);
    } else {
      console.log('No row selected or multiple selections made');
      toast.warning(
        "Tu ne peux sélectionner qu'une sortie pour voir les détails ou modifier"
      );
    }
  };
  const onClickArchive = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];

      // Optional: Check if the depense is archived before attempting to update
      const depense = updatedDonnees.find((d) => d.id === selectedRowId);

      const newArchivedStatus = !depense.archived;
      await handleUpdateDepense(selectedRowId, { archived: newArchivedStatus });
      toast.info(
        `Dépense ${newArchivedStatus ? 'archivée' : 'désarchivée'} avec succès.`
      );
      // Consider refetching updated data here to reflect changes
    } else {
      toast.warning('Veuillez sélectionner exactement une dépense à archiver.');
    }
  };

  // Handle changing the page
  const handlePageChange = (newIndex) => {
    setPaginationIndex(newIndex);
  };

  const handleSearchChange = (newSearchTerm) => {
    setSearchedTerm(newSearchTerm);
  };

  const handleRowSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows((prev) => [...prev, row.id]);
    } else {
      setSelectedRows((prev) => prev.filter((id) => id !== row.id));
    }
  };

  const handleFormDepense = () => {
    navigate(ADD_DEPENSE);
  };

  const ajoutFou = {
    fontFamily: 'Montserrat',
    fontWeight: '600',
    fontSize: '13px',
  };

  const nom = (
    <div style={{ display: 'flex' }}>
      <h4 className="titre-dossier">Sorties</h4>
      <span className="nbrDossier">{filteredData?.length}</span>
    </div>
  );
  return (
    <div
      className="depenses-page"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <div className="d-flex align-items-center flex-wrap pb-3">
        <HeaderFirst
          nom={nom}
          description={
            <div>Voici toutes vos sorties {viewArchived && 'archivées'}</div>
          }
        />
        <button
          type="button"
          className="btn btn-outline-dark"
          style={{
            borderRadius: '31px',
            border: '1px solid #AEAEAE',
            padding: '8px',
          }}
          onClick={handleFormDepense}
        >
          <span style={ajoutFou}>+ Ajouter une dépense</span>
        </button>
      </div>
      <HeaderButton
        isButtonsEnabled={selectedRows.length > 0}
        placeholder={'Rechercher une dépense'}
        onSearchChange={handleSearchChange}
        onEyesClick={onEyesClick}
        onClickArchive={onClickArchive}
      />
      {updatedDonnees ? (
        <DepensesTable
          data={currentPageData.filter((depense) =>
            depense.fournisseurs_details.nom
              .toLowerCase()
              .includes(serachTerm.toLowerCase())
          )}
          selectedRows={selectedRows}
          onSelectRow={handleRowSelect}
        />
      ) : (
        <div className="d-flex align-items-center justify-content-center fs-4">
          <p>Aucune transaction à afficher.</p>
        </div>
      )}
      {!currentPageData ||
        (currentPageData.length == 0 && (
          <div className="d-flex justify-content-center fs-5">
            <p>Aucune dépense à afficher.</p>
          </div>
        ))}
      {currentPageData && (
        <TablePaginators
          paginationIndex={paginationIndex}
          setPaginationIndex={handlePageChange}
          dataLength={filteredData.length}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onActionSelected={handleActionSelected}
          isViewingArchived={viewArchived}
        />
      )}
    </div>
  );
};

export default DepensesPage;
