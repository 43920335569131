import React, { useState } from 'react';
import HeaderFirst from 'utils/HeaderFirst';
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from 'redux/features/userSliceWithTokenValidation';
import { useSelector } from 'react-redux';
import { useDeleteTransactionMutation } from 'actions/transactions/mutations';
import { toast } from 'react-toastify';
import ConfirmModal from 'utils/ConfirmationModal';
import { useGetProduitsQuery } from 'actions/produits/queries';
import { useGetTransactionIdQuery } from 'actions/transactions/queries';

function formatDate(datetime) {
  const date = new Date(datetime);
  const day = date.getDate().toString().padStart(2, '0');
  // Define an array of month names
  const monthNames = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];
  // Use the getMonth() method to get the month name from the array
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  return `${day} ${month}, ${year}`;
}

function formatTime(datetime) {
  const date = new Date(datetime);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours} h ${minutes} min`;
}
function formatSourcePaiement(source_paiement) {
  return source_paiement === 'orange_money'
    ? 'Orange Money'
    : source_paiement === 'wave'
      ? 'Wave'
      : source_paiement === 'banque'
        ? 'Banque'
        : source_paiement === 'caisse'
          ? 'Caisse'
          : 'Autre'; // Default case if none of the above matches
}

function formatType(type) {
  return type === 'entree' ? 'Entrée' : 'Sortie';
}
function formatCompteComptable(compte_comptable) {
  return compte_comptable === 'banque' ? 'Banque' : 'Caisse';
}

function TransactionDetails() {
  const { clientID: dossier_id } = useParams();
  const { id, type } = useParams();
  const navigate = useNavigate();
  const { data: getAllTransactions } = useGetTransactionIdQuery({
    dossier_id,
    id,
    type,
  });
  console.log(getAllTransactions);
  const transaction = getAllTransactions;
  console.log(transaction);
  const userStatus = useSelector(getUser);
  const [deleteTransaction] = useDeleteTransactionMutation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [transactionToDelete, setTransactionToDelete] = useState(null);

  const { data: getProduitData } = useGetProduitsQuery({
    dossier_id: dossier_id,
  });

  const onDelete = (transaction) => {
    setTransactionToDelete(transaction);
    setIsConfirmModalOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    if (transactionToDelete) {
      const parts = transactionToDelete.split('-');
      const id = parts[0];
      const key = parts.slice(1).join('-');
      const transactionData = getAllTransactions?.transactions[key];
      const type = transactionData?.type;

      try {
        await deleteTransaction({ id: id, type: type }).unwrap();
        toast.success('Transaction supprimée avec succès');
        setIsConfirmModalOpen(false); // Close the modal upon success
        navigate(-1);
      } catch (error) {
        toast.error(
          `Échec de la suppression de la transaction : ${error.message}`
        );
        setIsConfirmModalOpen(false); // Close the modal upon failure
        console.error('Request failed:', error);
      }
    }
  };

  if (!transaction) {
    return (
      <div>Transaction introuvable ou toujours en cours de chargement...</div>
    );
  }

  const productDetails = transaction.produits_associes.map((prod, index) => {
    const productDetail = getProduitData?.data.find(
      (p) => p.id === prod.produit
    );
    return {
      key: index,
      Produit: productDetail?.nom || 'Produit inconnu',
      Quantité: prod.quantite,
      Prix: `${prod.prix} Fcfa`,
    };
  });

  console.log(productDetails);

  const formattedTransaction = {
    'Date de la transaction': formatDate(transaction.updated_at),
    'Heure de la transaction': formatTime(transaction.updated_at),
    Intitulé: transaction.intitule,
    [type === 'entree' ? 'Client' : 'Fournisseur']:
      type === 'entree'
        ? transaction.client_details?.nom || 'Information non disponible'
        : transaction.fournisseurs_details?.nom || 'Information non disponible',
    Type: formatType(type),
    Montant: new Intl.NumberFormat('fr-FR').format(transaction.montant_total)+ ' FCFA',
    Quantité: transaction.quantite,
    Produit: transaction?.produits_details?.nom || 'Information non disponible',
    'Moyen de paiement': formatSourcePaiement(transaction.source_paiement),
    'Lieu du paiement': transaction.lieu || 'Dakar',
    'Transaction effectuée par':
      userStatus?.first_name || 'Utilisateur inconnu',
    'Compte comptable': formatCompteComptable(transaction.compte_comptable),
    'Taxe': transaction?.taxes +" %",
    'Remise': transaction?.remise +" %",
  };

  const transactionTextColor = type === 'sortie' ? '#991B1B' : '#009688';

  return (
    <div
      className="transaction-page-details"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
        fontFamily: 'Montserrat',
      }}
    >
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onRequestClose={() => setIsConfirmModalOpen(false)}
        onConfirm={handleDeleteConfirmation}
        title="Confirmer la suppression"
        message="Êtes-vous sûr de vouloir supprimer cette transaction ? Cette action est irréversible."
      />
      <div className="d-flex align-items-center">
        <HeaderFirst
          nom={
            <div>
              <h4 className="titre-dossier">
                Transaction nº: {transaction?.id}
              </h4>
              <h6 className="fw-bold" style={{ color: transactionTextColor }}>
                Type {formatType(type)}
              </h6>
            </div>
          }
          description={<div>Voici les détails de la transaction.</div>}
        />
      </div>
      <div
        style={{
          padding: '15px',
          margin: '20px auto',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr', // Creates three columns of equal width
          gridGap: '30px',
        }}
      >
        {Object.entries(formattedTransaction).map(([key, value]) => {
          if (key !== 'Produit' && key !== 'Quantité') {
            // Skipping 'Produit' and 'Quantité' from general details
            return (
              <div
                key={key}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  alignItems: 'start',
                  marginBottom: '10px',
                }}
              >
                <span style={{ fontWeight: 'bold' }}>{key}</span>
                <span>{value}</span>
              </div>
            );
          }
          return null;
        })}
      </div>
      <div style={{ padding: '15px' }}>
        <strong>Produits Associés:</strong>
        <ul>
          {productDetails.map((detail) => (
            <li key={detail.key}>
              Produit: <strong>{detail.Produit}</strong>, Quantité:{' '}
              <strong>{detail.Quantité}</strong>, Prix unitaire:{' '}
              <strong>{detail.Prix}</strong>
            </li>
          ))}
        </ul>
      </div>
      <div className="d-flex flex-column gap-2" style={{ padding: '15px' }}>
        <p>
          Cette transaction peut contenir des erreurs. Vous pouvez toujours la
          modifier ou la supprimer.
        </p>
        <div className="d-flex justify-content-end">
          <button
            style={{
              backgroundColor: '#ff4d4f',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              padding: '10px 20px',
              cursor: 'pointer',
            }}
            onClick={() => onDelete(transaction.id)}
          >
            Supprimer
          </button>
        </div>
      </div>
    </div>
  );
}

export default TransactionDetails;
