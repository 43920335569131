// TransactionHeader.jsx
import React from 'react';
import HeaderFirst from 'utils/HeaderFirst';

const TransactionHeader = ({ transactionCount, viewArchived }) => (
  <div className="d-flex align-items-center">
    <HeaderFirst
      nom={
        <div style={{ display: 'flex' }}>
          <h4 className="titre-dossier">Transactions</h4>
          <span className="nbrDossier">{transactionCount}</span>
        </div>
      }
      description={
        <div>
          Voici la liste des transactions effectuées
          {viewArchived && 'et qui sont archivées'}.
        </div>
      }
    />
  </div>
);

export default TransactionHeader;
