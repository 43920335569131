// Modal.js
import React from 'react';
import '../../components/Modal.css';
// import { useDispatch } from "react-redux";
import { useFormContext} from 'react-hook-form';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import ButtonP from 'components/buttons/ButtonP';
import {  useGetCodesSalariesQuery} from 'actions/comptabilite/queries';
import { useCreateSalariesMutation } from 'actions/salaries/mutations';

const ModalClient = ({ isOpen, onClose, onDossierCreated, clients,refetch }) => {
  const { clientID: id } = useParams();
  /*const {
    data: codes,
    refetch
  } = useGetCodesComptablesQuery();*/

  const {
    data: codes=[],
  } = useGetCodesSalariesQuery()

  console.log("Je vous affiche les clients: ",clients)
  console.log("Les numéros de compte: ",codes)


  const [creationClient] = useCreateSalariesMutation();
  const {
    register,
    handleSubmit,
    //setValue,
    formState: { errors },
    reset,
  } = useFormContext();


  const onSubmit = (data) => {
    const clientData = { ...data, dossier_id: id };
    creationClient(clientData)
      .unwrap()
      .then((res) => {
        console.log(res);
        toast.success('Création client réussi !');
        reset();
        refetch();
        onDossierCreated();
      })
      .catch((error) => {
        console.log(error);
      });
  };


  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div
            className="modal-content bg-white"
            onClick={(e) => e.stopPropagation()}
            style={{ borderRadius: '20px' }}
          >
            <h2 className="modal-header">Ajouter un client</h2>
            <hr />
            <form
              className="fournisseur-form"
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group">
                <label htmlFor="nom" className="form-label">
                  Prénom et nom
                </label>
                <input
                  className="form-controll"
                  type="text"
                  id="nom"
                  placeholder="Prénom et nom"
                  {...register('nom', { required: 'Nom obligatoire' })}
                />
                {errors.nom && (
                  <div className="text-danger">{errors.nom.message}</div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="telephone" className="form-label">
                  Numéro de téléphone
                </label>
                <input
                  className="form-controll"
                  type="tel"
                  id="telephone"
                  name="telephone"
                  placeholder="777777777"
                  {...register('telephone', {
                    required: 'Téléphone obligatoire de 08 caractères'
                  })}
                />
                {errors.telephone && (
                  <div className="text-danger">{errors.telephone.message}</div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="adresse" className="form-label">
                  Adresse
                </label>
                <input
                  className="form-controll"
                  type="text"
                  id="adresse"
                  placeholder="Adresse"
                  {...register('adresse')}
                />
                {errors.adresse && (
                  <div className="text-danger">{errors.adresse.message}</div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  E-mail
                </label>
                <input
                  className="form-controll"
                  type="email"
                  id="email"
                  placeholder="email@example.com"
                  {...register('email', { required: 'Email obligatoire' })}
                />
                {errors.email && (
                  <div className="text-danger">{errors.email.message}</div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="fonction" className="form-label">
                  Fonction
                </label>
                <input
                  className="form-controll"
                  type="text"
                  id="fonction"
                  name="fonction"
                  placeholder="La fonction du salarié"
                  {...register('fonction', { required: 'Fonction obligatoire', minLength:4})}
                />

                {errors.fonction && (
                  <div className="text-danger">
                    {errors.fonction.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="compte_comptable" className="form-label">
                  Compte Comptable
                </label>
                <select name="compte_comptable" className='form-controll' id="compte_comptable" placeholder='Les comptes clients'
                  {...register('compte_comptable', { required: 'Compte comptable obligatoire', minLength:4})}>
                     <option value="" selected disabled hidden>Selectionner votre compte client</option>
                    {
                      codes?.map((code_client,index) => (
                        <option value={code_client.code} key={index}>{code_client.combine}</option>
                      ))
                    }
                </select>
                {errors.compte_comptable && (
                  <div className="text-danger">{errors.compte_comptable.message}</div>
                )}
              </div>

              <div className="modal-footer">
                <ButtonP
                  text={'Annuler'}
                  onClick={onClose}
                  className={'btn btn-danger py-2 px-4'}
                  
                />
                <ButtonP text={'Enregistrer'} type={'submit'}/>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalClient;
