import React, { useState } from 'react';
import './codeJournaux.css';
import HeaderButton from 'utils/headerButton';
import CustomBootstrapTable from 'utils/reactTable';
import HeaderFirst from 'utils/HeaderFirst';
import { FormProvider, useForm } from 'react-hook-form';
import arrow from '../../image/arrow-left.png';
import { useNavigate } from 'react-router-dom';
import useCodeJournalData from './useCodeJournalData';
import { toast } from 'react-toastify';
import TablePaginators from 'components/Pagination/TablePaginator';
//import ModalExcel from 'pagesDossierClient/fournisseurs/ModalExcel/ModalExcel';
import ModalCodeJournal from './Modal';
//import { HiCloudUpload } from "react-icons/hi";


function CodeJournaux() {
  const [searchTerm, setSearchTerm] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const { updatedcodeJournals, handleUpdateCodeJournal, handleDeleteCodeJournal,refetch } =
  useCodeJournalData();
  const [viewArchived, setViewArchived] = useState(false);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  //const [isModalFileOpen, setIsModalFileOpen] = useState(false)
  
  const filteredData = updatedcodeJournals
    .filter((codeJournal) =>
      viewArchived ? codeJournal.archived : !codeJournal.archived
    )
    .filter((codeJournal) =>
      codeJournal.code_journal.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const currentPageData = filteredData.slice(
    paginationIndex * pageSize,
    (paginationIndex + 1) * pageSize
  );

  const handleActionSelected = (action) => {
    if (action === 'archive') {
      setViewArchived(!viewArchived);
    } else {
      console.log(`Action selected: ${action}`);
      // Handle other actions as necessary
    }
  };

  const handlePageChange = (newIndex) => {
    setPaginationIndex(newIndex);
  };

  const handleSearchChange = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  };

  const handleRowSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows((prev) => [...prev, row.id]);
    } else {
      setSelectedRows((prev) => prev.filter((id) => id !== row.id));
    }
  };

  const onClickDetails = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const detailFournisseurURL = `details/${selectedRowId}/`;
      navigate(detailFournisseurURL);
    }
  };

  const onClickArchive = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const codeJournal = updatedcodeJournals.find(
        (f) => f.id === selectedRowId
      );
      if (!codeJournal) {
        toast.error('Code journal non trouvé.');
        return;
      }

      try {
        await handleUpdateCodeJournal(selectedRowId, {
          archived: !codeJournal.archived,
        });
        toast.info(
          `Mise à jour ${
            codeJournal.archived ? 'désarchivé' : 'archivé'
          } avec succès.`
        );
      } catch (error) {
        toast.error(
          'Une erreur est survenue lors de la mise à jour du codeJournal.'
        );
      }
    } else {
      toast.warning(
        'Veuillez sélectionner exactement un codeJournal à archiver ou désarchiver.'
      );
    }
  };

  const onClickDelete = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const codeJournal = updatedcodeJournals.find(
        (f) => f.id === selectedRowId
      );
      if (!codeJournal) {
        toast.error('Code journal non trouvé.');
        return;
      }

      try {
        await handleDeleteCodeJournal(selectedRowId)
        refetch()
      } catch (error) {
        toast.error(
          'Une erreur est survenue lors de la mise à jour du codeJournal.'
        );
      }
    } else {
      toast.warning(
        'Veuillez sélectionner exactement un codeJournal à archiver ou désarchiver.'
      );
    }
  };

  const columns = [
    {
      dataField: 'code_journal',
      text: 'Code',
      formatter: (cell) => <div>{cell}</div>,
    },
    {
      dataField: 'type_journal',
      text: 'Intitulé',
      formatter: (cell) => <div>{cell}</div>,
    },
    
  ];

  const handleDossierCreated = () => {
    setIsModalOpen(false);
  };

  const nom = (
    <div style={{ display: 'flex' }}>
      <h4 className="titre-dossier">CodeJournaux</h4>
      <span className="nbrDossier">{filteredData?.length}</span>
    </div>
  );

  const description = <div>Voici tous vos codes journaux</div>;


  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const methods = useForm();

  return (
    <div
      className="global"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <div
        className="header mb-3"
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          flexWrap: 'wrap',
        }}
      >
        <HeaderFirst nom={nom} description={description} />
        <button
          type="button"
          className="btn btn-outline-dark"
          style={{
            borderRadius: '31px',
            border: '1px solid #AEAEAE',
            padding: '8px',
          }}
          onClick={openModal}
        >
          <span style={ajoutFou}>+ Ajouter un code</span>
        </button>

        {isModalOpen && (
          <FormProvider {...methods}>
            <ModalCodeJournal
              isOpen={isModalOpen}
              onClose={closeModal}
              onDossierCreated={handleDossierCreated}
              
            >
              <h2 style={{ color: '#009688', fontSize: '20px', fontWeight: 700 }}>
                <img src={arrow} alt="Arrow" />
                &nbsp;CREER UN NOUVEAU DOSSIER FOURNISSEUR
              </h2>
            </ModalCodeJournal>
          </FormProvider>
        )}
      </div>
      <HeaderButton
        placeholder={'Rechercher un codeJournal'}
        onSearchChange={handleSearchChange}
        isButtonsEnabled={selectedRows?.length > 0}
        onClickArchive={onClickArchive}
        onEyesClick={onClickDetails}
        onClickDelete={onClickDelete}
      />
      <div>
        <CustomBootstrapTable
          keyField="id"
          data={currentPageData}
          columns={columns}
          selectedRows={selectedRows}
          onSelectRow={handleRowSelect}
          wrapperClasses="table-wrapper"
        />
      </div>
      {currentPageData && currentPageData?.length > 0 && (
        <TablePaginators
          paginationIndex={paginationIndex}
          setPaginationIndex={handlePageChange}
          dataLength={filteredData.length}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onActionSelected={handleActionSelected}
          isViewingArchived={viewArchived}
        />
      )}
    </div>
  );
}

const ajoutFou = {
  fontFamily: 'Montserrat',
  fontWeight: '600',
  fontSize: '13px',
};

export default CodeJournaux;
