import { ApiMananger } from "actions/rtkquery";
import { ACTIVATION_API_ROUTE, LOGIN_API_ROUTE, REGISTER_API_ROUTE } from "routes/api/endpoints";

const userApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
        loginUser: build.mutation({
            query(body) {
                return {
                    url: LOGIN_API_ROUTE,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["INVALIDATE_USERS"],
        }),
        registerUser: build.mutation({
            query(body) {
                return {
                    url: REGISTER_API_ROUTE,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["INVALIDATE_USERS"],
        }),
        activationUser: build.mutation({
            query(body) {
                return {
                    url: ACTIVATION_API_ROUTE,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["INVALIDATE_USERS"],
        }),

    
        forgetPassword: build.mutation({
            query(body) {
                return {
                    url: "API.USER_API.FORGET_PASSWORD()",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["INVALIDATE_USERS"],
        }),
        otpVerification: build.mutation({
            query(body) {
                return {
                    url: "API.USER_API.OTP_VERIFICATION()",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["INVALIDATE_USERS"],
        }),
        resetPassword: build.mutation({
            query(body) {
                return {
                    url: "API.USER_API.RESET_PASSWORD()",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["INVALIDATE_USERS"],
        }),
        updatePassword: build.mutation({
            query(body) {
                return {
                    url: "",
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["INVALIDATE_USERS"],
        }),
    }),
});

export const {
    useLoginUserMutation,
    useRegisterUserMutation,
    useActivationUserMutation,
    useUpdatePasswordMutation,
    useForgetPasswordMutation,
    useResetPasswordMutation,
    useOtpVerificationMutation,
} = userApi;