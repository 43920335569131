import {ApiMananger} from "actions/rtkquery";
import {GET_USER_DATA} from "routes/api/endpoints";

const PermissionsApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query({
      query: () => `${GET_USER_DATA}`,
      providesTags: ["USERS"],
    }),
    getUsersById: build.query({
      query: (id) => `${GET_USER_DATA}${id}/`,
      providesTags: ["USERS"],
    }),
  }),
});

export const {useGetUsersQuery, useGetUsersByIdQuery} = PermissionsApi;
