
import { ApiMananger } from "actions/rtkquery";
import { ADD_OCR_API_ROUTE } from "routes/api/endpoints";

const ocrApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({

        createOcr: build.mutation({
            query(body) {
                return {
                    url: ADD_OCR_API_ROUTE,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["INVALIDATE_OCR","dossie","INVALIDATE_DOSSIER"],
        }),
     


    }),
});

export const {
    useCreateOcrMutation,
   
} = ocrApi;