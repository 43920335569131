import {ApiMananger} from "actions/rtkquery";
import {ADD_ROLE_API_ROUTE} from "routes/api/endpoints";

const PermissionsApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    updatePermissionStatus: build.mutation({
      query: ({roleId, permId, updateData}) => ({
        url: `${ADD_ROLE_API_ROUTE}${roleId}/permissions/${permId}/update-status/`,
        method: "PATCH",
        body: updateData,
      }),
      invalidatesTags: ["PERMISSIONS"],
    }),
  }),
  overrideExisting: false,
});

export const {useUpdatePermissionStatusMutation} = PermissionsApi;
