import React, { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { BsUpload, BsFileEarmarkPdf, BsFileEarmarkExcel } from 'react-icons/bs';
import { IoMdCloseCircle } from 'react-icons/io';

function FileUpload({ onFileSelect }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024) {
        alert('La taille du fichier est >1 MB.'); // You may replace it with a toast
        return;
      }
      setSelectedFile(file);
      onFileSelect(file);
    }
  };

  const clearSelection = () => {
    setSelectedFile(null);
    onFileSelect(null); // Notify parent component about the clearance
    fileInputRef.current.value = ''; // Reset the file input
  };

  const getFileIcon = (fileType) => {
    if (fileType.includes('pdf')) {
      return <BsFileEarmarkPdf className="fs-2" />;
    } else if (
      fileType.includes('spreadsheetml') ||
      fileType.includes('excel')
    ) {
      return <BsFileEarmarkExcel className="fs-2" />;
    } else {
      return null; // Implement more types if needed
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <Form.Group controlId="piece_joint">
      <div
        className="upload-area"
        onClick={!selectedFile ? triggerFileInput : undefined}
        style={{ cursor: 'pointer' }}
      >
        {selectedFile ? (
          <>
            {selectedFile.type.startsWith('file/') ? (
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="File Preview"
                className="file-preview"
              />
            ) : (
              <div className="p-3 text-center">
                {getFileIcon(selectedFile.type)}
                <div className="d-flex flex-column mt-3">
                  <span>{selectedFile.name}</span>
                </div>
              </div>
            )}
            <div className="text-center mt-2">
              <button
                className="btn btn-outline-danger border-0"
                onClick={clearSelection}
              >
                <IoMdCloseCircle className="fs-3" />
              </button>
            </div>
          </>
        ) : (
          <div className="p-3 text-center">
            <BsUpload className="fs-2" />
            <div className="d-flex flex-column mt-3">
              <span>
                Déposez ici pour joindre ou{' '}
                <span style={{ color: '#068f7a' }}>télécharger</span>
              </span>
              <span>image, pdf ou .xlsx</span>
            </div>
          </div>
        )}
        <Form.Control
          ref={fileInputRef}
          type="file"
          name="file"
          accept="image/*,.pdf,.xlsx"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          onClick={(event) => (event.currentTarget.value = '')}
        />
      </div>
    </Form.Group>
  );
}

export default FileUpload;
