import React, { useState } from 'react';
import CustomBootstrapTable from 'utils/reactTable';
import fileLogo from '../../image/Featured icon.png';
import { FaFileInvoice, FaRegTrashAlt } from 'react-icons/fa';
import { GoEye } from 'react-icons/go';
import Facture from './Facture';
import { FiPaperclip } from 'react-icons/fi';
//import { useGetProduitsQuery } from 'actions/produits/queries';
import { useParams } from 'react-router-dom';
import { DEFAULT_API_IMAGE } from 'routes/api/endpoints';

const TransactionTable = ({
  data,
  handleRowClick,
  selectedRows,
  onSelectRow,
  onEyeClick,
  onDeleteClick,
}) => {
  console.log({data});
  const rowEvents = {
    onClick: (e, row) => {
      console.log('Table row clicked:', row); // Check if this logs
      handleRowClick(row);
    },
  };
  // modal facture
  const { clientID: dossier_id } = useParams();
  console.log({dossier_id});

  const [selectedRowData, setSelectedRowData] = useState(false);
  const [isFactureModalOpen, setIsFactureModalOpen] = useState(false);
  /*const { data: getProduitData, isLoading: isProduitDataLoading } =
    useGetProduitsQuery({ dossier_id });*/

  const handleOpenFactureModal = (rowData) => {
    setIsFactureModalOpen(true);
    setSelectedRowData(rowData);
  };

  const handleCloseFactureModal = () => {
    setIsFactureModalOpen(false);
  };

  /*if (isProduitDataLoading) {
    return <div>Chargement des produits...</div>;
  }*/

  const columns = [
    {
      // Use 'key' as the keyField to ensure unique keys
      dataField: 'key',
      text: 'Nom du fournisseur / Client',
      formatter: (cell, row) => {
        const displayText =
          row?.fournisseurs ||
          (row?.fournisseurs_details && row?.fournisseurs_details.nom) ||
          (row?.client_details && row?.client_details.nom);

        return (
          <div className="d-flex align-items-center gap-2">
            <img src={fileLogo} alt="File Icon" /> {displayText}
          </div>
        );
      },
    },
    {
      dataField: 'updated_at',
      text: 'Date',
      formatter: (cell) => {
        // Parse the date string into a Date object
        const date = new Date(cell);

        // Format the date and time parts
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        // Combine the parts into the desired format
        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

        // Render the formatted date string
        return <div>{formattedDate}</div>;
      },
    },
    /*{
      dataField: 'produit',
      text: 'Produit',
      formatter: (cell, row) => {
        return (
          <div>
            {row.produits_associes.map((associe, idx) => {
              const productDetail = getProduitData?.data.find(
                (p) => p.id === associe.produit
              );
              return (
                <div key={idx}>
                  {productDetail ? productDetail.nom : 'Produit inconnu'}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      dataField: 'prix', // Assuming this is the field for the amount
      text: 'Prix',
      formatter: (cell, row) => {
        // Determine if the row is a depense or vente based on the row type
        const isDepense = row.type === 'entree';
        const isVente = row.type === 'sortie';

        const totalPrice = row.produits_associes.reduce(
          (sum, prod) => sum + parseFloat(prod.prix || 0),
          0
        );
        // Format the amount with + or - prefix and apply color
        let formattedAmount = `${totalPrice} FCFA`; // Default format
        let textColor = ''; // Default text color

        if (isDepense) {
          textColor = '#009688'; // Typically a shade of red for expenses
          formattedAmount = new Intl.NumberFormat('fr-FR').format(totalPrice)+ ' FCFA'; // Prefix with + for income
        } else if (isVente) {
          textColor = '#991B1B'; // A shade of green for income
          formattedAmount = new Intl.NumberFormat('fr-FR').format(totalPrice)+ ' FCFA'; // Prefix with - for expenses
        }

        return <div style={{ color: textColor }}>{formattedAmount}</div>;
      },
    },*/
    {
      dataField: 'type', // Assuming you have a field that distinguishes between depense and vente
      text: 'Type',
      formatter: (cell) => {
        // //   // Display 'Sortie' for depense and 'Entrée' for vente
        const displayType = cell && cell === 'sortie' ? 'Sortie' : 'Entrée';
        const textColor = cell && cell === 'sortie' ? '#991B1B' : '#064E3B';
        const bkgrCOlor = cell && cell === 'sortie' ? '#FEF2F2' : '#ECFDF5';

        return (
          <div
            style={{
              color: textColor,
              padding: '1px',
              backgroundColor: bkgrCOlor,
              fontWeight: 'bold',
              textAlign: 'center',
              borderRadius: '5px',
            }}
          >
            {displayType}
          </div>
        );
      },
    },
    {
      dataField: 'source_paiement',
      text: 'Paiement par',
      formatter: (cell) => {
        let paymentMethod = '';
        switch (cell) {
          case 'wave':
            paymentMethod = 'Wave';
            break;
          case 'orange_money':
            paymentMethod = 'Orange Money';
            break;
          case 'banque':
            paymentMethod = 'Banque';
            break;
          case 'caisse':
            paymentMethod = 'Caisse';
            break;
          default:
            paymentMethod = 'Non défini';
            break;
        }
        return <div>{paymentMethod}</div>;
      },
    },

    {
      dataField: 'montant_total',
      text: 'total',
      formatter: (cell,row) => {
         // Determine if the row is a depense or vente based on the row type
         const isDepense = row.type === 'entree';
         const isVente = row.type === 'sortie';

         // Format the amount with + or - prefix and apply color
        let formattedAmount = '' // Default format
        let textColor = ''; // Default text color

        if (isDepense) {
          textColor = '#009688'; // Typically a shade of red for expenses
          formattedAmount = new Intl.NumberFormat('fr-FR').format(cell)+ ' FCFA'; // Prefix with + for income
        } else if (isVente) {
          textColor = '#991B1B'; // A shade of green for income
          formattedAmount = new Intl.NumberFormat('fr-FR').format(cell)+ ' FCFA'; // Prefix with - for expenses
        }
      
      //return <div>{cell} Fcfa</div>
      return <div style={{ color: textColor }}>{formattedAmount}</div>;
      }
    },
    {
      dataField: 'piece_joint',
      text: <FiPaperclip />,
      formatter: (cell, row) => (
        <div className="styleColonne" onClick={() => handleRowClick(row)}>
          {cell ? (
            <a href={`${DEFAULT_API_IMAGE}${cell}`} target="_blank" rel="noopener noreferrer">
              Pièce jointe
            </a>
          ) : (
            <span>Pas de pièce jointe</span>
          )}
        </div>
      ),
    },
    {
      dataField: 'id',
      text: 'Action',
      formatter: (row) => (
        <div>
          <div className="d-flex fs-5 gap-3">
            <FaRegTrashAlt
              className="text-danger"
              onClick={(e) => {
                e.stopPropagation(); // Prevent the row click event
                onDeleteClick(row); // Call the passed delete handler
              }}
            />
            <GoEye
              onClick={(e) => {
                e.stopPropagation(); // Prevent click from bubbling to the row's onClick event
                onEyeClick(row);
              }}
            />
            <FaFileInvoice onClick={() => handleOpenFactureModal(row)} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Facture
        isOpen={isFactureModalOpen}
        onClose={handleCloseFactureModal}
        rowData={selectedRowData}
        data={data}
      ></Facture>
      <CustomBootstrapTable
        keyField="key"
        data={data}
        columns={columns}
        rowEvents={rowEvents}
        selectedRows={selectedRows} // Forward the prop
        onSelectRow={onSelectRow} // Forward the prop
      />
    </div>
  );
};

export default TransactionTable;
