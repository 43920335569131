import React from 'react';

export default function PaimentCardForm({
  color,
  icon,
  somme,
  explain,
  onClick,
}) {
  return (
    <div
      className="w-100 w-md-25"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <div className="card border-0">
        <div
          className={`card-body justify-content-between text-end text-white d-flex align-items-center gap-3`}
          style={{
            backgroundColor: color,
            fontFamily: 'Montserrat',
            borderRadius: '5.41px',
          }}
        >
          <div className="iconCard d-flex">
            <div
              className={`d-flex align-items-center justify-content-center`}
              style={{ width: '40px', height: '40px' }}
            >
              <span className="fs-4 text-white">{icon}</span>
            </div>
          </div>
          <div className="explain d-flex flex-column">
            <span className="fw-bold">{somme}</span>
            <span style={{ fontSize: '13px' }}>{explain}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
