// Modal.js
import React, {useState} from "react";
import "../../components/Modal.css";
import {useCreateFournisseurMutation} from "actions/fournisseurs/mutations";
// import { useDispatch } from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import {useGetFournisseurQuery} from "actions/fournisseurs/queries";
import ButtonP from "components/buttons/ButtonP";
import {useGetCodesFournisseursQuery} from "actions/comptabilite/queries";
import CreatableSelect from "react-select/creatable";
import Switch from "components/SwitchButton/Switch";

const ModalFournisseur = ({
  isOpen,
  onClose,
  onDossierCreated,
  fournisseurs,
}) => {
  const {clientID: dossier_id} = useParams();

  const {data: codes = []} = useGetCodesFournisseursQuery();

  const [lettrage, setLettrage] = useState(false);

  console.log(dossier_id);
  // const dispatch = useDispatch();

  let numeros = fournisseurs.map((fournisseur) => fournisseur.compte_comptable);
  console.log("Les numéros de compte: ", numeros);

  const [creationFournisseur] = useCreateFournisseurMutation();
  const {data: getFournisseur, refetch} = useGetFournisseurQuery({
    dossier_id,
  });

  console.log({getFournisseur});

  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    reset,
  } = useFormContext();

  const refetchData = async () => {
    try {
      // Utilisez l'async/await pour attendre la résolution de la requête de refetch
      const refetchResult = await refetch();

      // Assurez-vous que la requête de refetch s'est terminée avec succès avant de traiter les données
      if (refetchResult && refetchResult.status === "fulfilled") {
        console.log("Refetch des données réussi");
      } else {
        console.error("Échec du refetch des données");
      }
    } catch (error) {
      console.error("Erreur lors du refetch des données", error);
    }
  };

  const onSubmit = (data) => {
    const fournisseurData = {
      ...data,
      dossier_id: dossier_id,
      lettrage: lettrage,
      compte_comptable: data.compte_comptable?.value.toString() || null,
    };
    console.log(fournisseurData);
    creationFournisseur(fournisseurData)
      .unwrap()
      .then((res) => {
        console.log(res);
        toast.success("Création fournisseur réussi !");
        reset();
        refetchData();
        onDossierCreated();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const options = codes?.map((code_fournisseur) => ({
    value: code_fournisseur.code,
    label: code_fournisseur.combine,
  }));

  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div
            className="modal-content bg-white"
            onClick={(e) => e.stopPropagation()}
            style={{borderRadius: "20px"}}>
            <h2 className="modal-header">Ajouter un fournisseur</h2>
            <hr />
            <form
              className="fournisseur-form"
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label htmlFor="nom" className="form-label">
                  Prénom et nom
                </label>
                <input
                  className="form-controll"
                  type="text"
                  id="nom"
                  placeholder="Prénom et nom"
                  {...register("nom", {required: "Nom obligatoire"})}
                />
                {errors.nom && (
                  <div className="text-danger">{errors.nom.message}</div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="telephone" className="form-label">
                  Numéro de téléphone
                </label>
                <input
                  className="form-controll"
                  type="tel"
                  id="telephone"
                  placeholder="777777777"
                  {...register("telephone", {
                    required: "Téléphone obligatoire",
                  })}
                />
                {errors.telephone && (
                  <div className="text-danger">{errors.telephone.message}</div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="adresse" className="form-label">
                  Adresse
                </label>
                <input
                  className="form-controll"
                  type="text"
                  id="adresse"
                  placeholder="Adresse"
                  {...register("adresse")}
                />
                {errors.adresse && (
                  <div className="text-danger">{errors.adresse.message}</div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  E-mail
                </label>
                <input
                  className="form-controll"
                  type="email"
                  id="email"
                  placeholder="email@example.com"
                  {...register("email", {required: "Email obligatoire"})}
                />
                {errors.email && (
                  <div className="text-danger">{errors.email.message}</div>
                )}
              </div>

              {/*<div className="form-group">
                <label htmlFor="compte_comptable" className="form-label">
                  Compte Comptable
                </label>
                <input
                  className="form-controll"
                  type="number"
                  id="compte_comptable"
                  name="compte_comptable"
                  placeholder="Commence par 401..."
                  {...register('compte_comptable', {required: 'Compte comptable obligatoire', minLength:4})}
                />
                {errorCompte != "" && (
                  <div className="text-danger">
                    {errorCompte}
                  </div>
                )}
                
              </div>*/}
              {/* Compte Comptable with react-select */}
              <div className="form-group">
                <label htmlFor="compte_comptable" className="form-label">
                  Compte Comptable
                </label>
                <Controller
                  control={control}
                  name="compte_comptable"
                  rules={{required: "Compte comptable obligatoire"}}
                  render={({field}) => (
                    <CreatableSelect
                      {...field}
                      options={options}
                      placeholder="Sélectionner ou créer un compte fournisseur"
                      isClearable
                    />
                  )}
                />
                {errors.compte_comptable && (
                  <div className="text-danger">
                    {errors.compte_comptable.message}
                  </div>
                )}
              </div>

              {/* Lettrage Checkbox */}
              <div className="form-group d-flex align-items-end gap-3">
                <label htmlFor="lettrage" className="form-label">
                  Lettrage
                </label>
                <Switch
                  isOn={lettrage}
                  handleToggle={() => setLettrage(!lettrage)}
                  colorOne="#068f7a"
                  colorTwo="#ccc"
                />
              </div>

              <div className="modal-footer">
                <ButtonP
                  text={"Annuler"}
                  onClick={onClose}
                  className={"btn btn-danger py-2 px-4"}
                />
                <ButtonP text={"Enregistrer"} type={"submit"} />
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalFournisseur;
