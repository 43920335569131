import React from "react";
import {DEFAULT_API_IMAGE} from "routes/api/endpoints";

function CollaborateursIcon({collaborateurs}) {
  // Styles for the profile circle
  const profileStyle = {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    backgroundColor: "#f0f0f0",
    border: "2px solid #bbb",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666666",
  };

  // Styles for the extra count circle
  const extraCountStyle = {
    ...profileStyle,
    backgroundColor: "#e0f7fa",
    color: "#00796b",
  };

  // Styles for the add new circle
  const addNewStyle = {
    ...profileStyle,
    border: "2px dashed #bbb",
  };

  // Function to extract initials
  const getInitials = (firstName, lastName) => {
    const firstNameInitial = firstName ? firstName[0] : "";
    const lastNameInitial = lastName ? lastName[0] : "";
    return firstNameInitial + lastNameInitial;
  };

  // Limiting the displayed collaborators to 2
  const displayedCollaborateurs = collaborateurs.slice(0, 2);
  const extraCount = collaborateurs.length - displayedCollaborateurs.length;

  return (
    <div style={{display: "flex", position: "relative"}}>
      {displayedCollaborateurs.map((collaborateur, index) => (
        <div
          key={index}
          style={{
            ...profileStyle,
            zIndex: displayedCollaborateurs.length + index,
            left: `${index * 20}px`,
          }}>
          {collaborateur.photoURL ? (
            <img
              src={
                collaborateur.photoURL.startsWith("http")
                  ? collaborateur.photoURL
                  : `${DEFAULT_API_IMAGE}${collaborateur.photoURL}`
              }
              alt="Profile"
              style={{width: "30px", height: "30px", borderRadius: "50%"}}
            />
          ) : (
            <div>
              {getInitials(collaborateur.first_name, collaborateur.last_name)}
            </div>
          )}
        </div>
      ))}
      {extraCount > 0 && (
        <div
          style={{
            ...extraCountStyle,
            zIndex: 1 + displayedCollaborateurs.length,
            left: `${displayedCollaborateurs.length * 20}px`,
          }}>
          +{extraCount}
        </div>
      )}
      <div
        style={{
          ...addNewStyle,
          zIndex: 0,
          left: `${
            (displayedCollaborateurs.length + (extraCount > 0 ? 1 : 0)) * 20
          }px`,
        }}
        className="ms-3">
        +
      </div>
    </div>
  );
}

export default CollaborateursIcon;
