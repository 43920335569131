import { ApiMananger } from "actions/rtkquery";
import { GET_LIVREECRITURES_API_ROUTE, GET_LIVREECRITURESByMonth_API_ROUTE, GET_LIVREJOURNAUX_API_ROUTE, GET_TIERS_BYDOSSIER_API_ROUTE } from "routes/api/endpoints";

const journalApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({

    getJournal: build.query({
        query: () => `${GET_LIVREJOURNAUX_API_ROUTE}`,
        providesTags: ["INVALIDATE_LIVREJOURNAL"],
    }),
    getJournalId: build.query({
        query: ({id}) => `${GET_LIVREJOURNAUX_API_ROUTE}?id=${id}`,
        providesTags: ["INVALIDATE_LIVREJOURNAL"],
    }),
    getJournalByDossierClientId: build.query({
        query: (dossier_id) => `${GET_LIVREJOURNAUX_API_ROUTE}?dossier_client=${dossier_id}`,
        providesTags: ["INVALIDATE_LIVREJOURNAL"],
    }),
    getJournalEcrituresByMonthId: build.query({
        query: ({dossier_id,livre_specific,month}) => `${GET_LIVREECRITURESByMonth_API_ROUTE}?dossier_client=${dossier_id}&livre_specific=${livre_specific}&month=${month}`,
        providesTags: ["INVALIDATE_LIVREJOURNAL"],
    }),
    getJournalEcrituresByCodeJournalId: build.query({
        query: ({id,dossier}) => `${GET_LIVREECRITURES_API_ROUTE}?id=${id}&dossier=${dossier}`,
        providesTags: ["INVALIDATE_LIVREJOURNAL"],
    }),
    getTiersByDossierClient: build.query({
        query: ({dossier_id}) => `${GET_TIERS_BYDOSSIER_API_ROUTE}?dossier_client=${dossier_id}`,
        providesTags: ["INVALIDATE_TIERS"],
    }),
}),
});

export const {
useGetJournalIdQuery,
useGetJournalQuery,
useGetJournalByDossierClientIdQuery,
useGetJournalEcrituresByMonthIdQuery,
useGetJournalEcrituresByCodeJournalIdQuery,
useGetTiersByDossierClientQuery
} = journalApi;
