import React from 'react'
import { useGetLivreJournalQuery } from 'actions/comptabilite/queries';
import Table from 'react-bootstrap/Table';
import { useEffect } from 'react';
import "./style/style.css"


const LivreJournal = ({dossier_id}) => {
    const {
        data: livrejournal,
        refetch
      } = useGetLivreJournalQuery({dossier_id: dossier_id});

        useEffect(() => {
          refetch()
        },[livrejournal])

        console.log(livrejournal)
  return (
    <div>
        <h2>Livre Journal</h2>
      <Table striped bordered hover >
      <thead>
        <tr>
          <th>Date</th>
          <th>Code Debit</th>
          <th>Code Credit</th>
          <th>Libellé</th>
          <th>Crédit</th>
          <th>Débit</th>
        </tr>
      </thead>
      <tbody>
      {
        livrejournal &&
          livrejournal["journal"]?.map((ecriture, index) => {
            return(
              <tr key={index}>
                <td>{ecriture["date"]}</td>
                <td>{ecriture["code_debit"]}</td>
                <td>{ecriture["code_credit"]}</td>
                <td>{ecriture["libelle"]}</td>
                <td>{ecriture["debit"]}</td>
                <td>{ecriture["credit"]}</td>
              </tr>
            )
          })
        }

        {livrejournal&& (
            <tr>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td className='data'>TOTAL</td>
              <td className='data'>{livrejournal["total_debits"]}</td>
              <td className='data'>{livrejournal["total_credits"]}</td>             
          </tr>
          )}
        
      </tbody>
    </Table>

    </div>
  )
}

export default LivreJournal