import React from "react";
import Roots from "./routes/Routes";

function App() {
  return (
    <div>
      <Roots />
    </div>
  );
}

export default App;
