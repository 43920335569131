import {ApiMananger} from "actions/rtkquery";
import {
  ADD_FACTURE_CABINET_API_ROUTE,
  UPDATE_FACTURE_CABINET_API_ROUTE,
  DELETE_FACTURE_CABINET_API_ROUTE,
} from "routes/api/endpoints";

const factureApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    // Mutation for creating a new collaborateur instance
    createFacture: build.mutation({
      query(data) {
        return {
          url: `${ADD_FACTURE_CABINET_API_ROUTE}?dossier_id=${data["dossier_id"]}`,
          method: "POST",
          body: data["formData"],
          formData: true,
        };
      },
      invalidatesTags: ["FACTURE"],
    }),

    // Mutation for updating an existing collaborateur instance
    updateFacture: build.mutation({
      query: ({id, ...updateData}) => ({
        url: `${UPDATE_FACTURE_CABINET_API_ROUTE}${id}/`,
        method: "PUT",
        body: updateData,
      }),
      invalidatesTags: ["FACTURE"], // Invalidate the cache tags to refetch collaborateur-related data
    }),

    // Mutation for deleting an existing collaborateur instance
    deleteFacture: build.mutation({
      query: ({id}) => ({
        url: `${DELETE_FACTURE_CABINET_API_ROUTE}${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["FACTURE"], // Invalidate to refetch collaborateur-related data
    }),

    // More collaborateur-related mutations and queries can be added here...
  }),
  overrideExisting: false,
});

export const {
  useCreateFactureMutation,
  useUpdateFactureMutation,
  useDeleteFactureMutation,
} = factureApi;
