import {ApiMananger} from "actions/rtkquery";
import {
  GET_ROLE_API_ROUTE,
  GET_EXPERTCOMPTABLE_DOSSIERS_API_ROUTE,
} from "routes/api/endpoints";

const RolesApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getRoles: build.query({
      query: () => GET_ROLE_API_ROUTE,
      providesTags: ["Roles"],
    }),
    getExpertDossiers: build.query({
      query: (id) => `${GET_EXPERTCOMPTABLE_DOSSIERS_API_ROUTE}/${id}/`,
      providesTags: ["ExpertDossiers"], // Use a specific tag for expert dossiers
    }),
  }),
});

export const {useGetRolesQuery, useGetExpertDossiersQuery} = RolesApi;
