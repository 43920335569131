import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { PUBLIC_PATH } from "../routes/navigation/navigationPaths";
import useGetAuthenticate from "hooks/useIsAuthenticatedUser";

function RequireAuth() {
  const isAuthenticated = useGetAuthenticate();

  const location = useLocation();

  return isAuthenticated ? (
    <>
      <Outlet />
    </>
  ) : (
      <Navigate to={PUBLIC_PATH} state={{ from: location }} replace />
  );
}

export default RequireAuth;
