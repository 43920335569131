import React, {useRef} from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { usePDF } from 'react-to-pdf';

import Table from "react-bootstrap/Table";
import {useEffect} from "react";
import "./style/style.css";
import {useGetLivreJournal_CabinetQuery} from "actions/cabinet/queries";

const LivreJournal = ({dossier_id}) => {
  const {data: livrejournal, refetch} = useGetLivreJournal_CabinetQuery({
    dossier_id: dossier_id,
  });
  // pour excel
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'LivreJournal table',
    sheet: 'LivreJournal'
  })  

  // pour pdf
  const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});

  const myRef = useRef(null);


  useEffect(function() {
    tableRef.current = myRef.current;
    targetRef.current = myRef.current;
  }, [myRef.current]);

  useEffect(() => {
    refetch();
  }, [livrejournal]);

  const convert = (dateString) => {
    const dateToTime = date => date.toLocaleString('fr-FR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric'
    });
    
    //const userOffset = new Date().getTimezoneOffset()*60*1000;
    const localDate = new Date(dateString);
    //const utcDate = new Date(localDate.getTime() + userOffset);

    return `${dateToTime(localDate)}`
  }



  console.log(livrejournal);
  return (
    <div style={{marginLeft: "20px"}}>
      <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
        <h1
          style={{
            fontSize: "25px",
            fontWeight: "bold",
            marginLeft: "0px",
            padding: "10px 0px",
          }}>
          Livre Journal
        </h1>
        <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
          <button onClick={onDownload}> Export excel </button>
          <button onClick={() => toPDF()}>Download PDF</button>
        </div>

      </div>


      <Table bordered hover ref={myRef}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Code Debit</th>
            <th>Code Credit</th>
            <th>Libellé</th>
            <th>Débit</th>
            <th>Crédit</th>
          </tr>
        </thead>
        <tbody>
          {
            livrejournal &&
              livrejournal["journal"]?.map((ecriture, index) => {
                return(
                  <tr key={index}>
                    <td>{convert(ecriture["update_at"])}</td>
                    <td>{ecriture["code_debit"]}</td>
                    <td>{ecriture["code_credit"]}</td>
                    <td>{ecriture["libelle"]}</td>
                    <td>{ecriture["debit"]}</td>
                    <td>{ecriture["credit"]}</td>
                  </tr>
                )
              })
            }

          {livrejournal&& (
              <tr>
                <td>-</td>
                <td>-</td>
              <td>-</td>
              <td className='data'>TOTAL</td>
              <td className='data'>{livrejournal["total_debits"]}</td>
              <td className='data'>{livrejournal["total_credits"]}</td>             
          </tr>
          )}
        
      </tbody>
      </Table>
    </div>
  );
};

export default LivreJournal;
