import {
  CLIENT,
  FOURNISSEURS,
  PARAMETRE_PATH,
  PRODUCTS_PATH,
  SOLDES,
  TRANSACTION,
  //FACTURE,
  COMPTABILITE
} from './navigationPaths';
import { HiHome } from 'react-icons/hi';
import { MdAccountBalance, MdDashboard } from 'react-icons/md';
import { CgLayoutPin } from 'react-icons/cg';
import {
  RiArrowLeftRightLine,
  RiSettingsFill,
  RiUserAddLine,
} from 'react-icons/ri';
/*
|--------------------------------------------------------------------------
! Sidebar Items
|--------------------------------------------------------------------------
|
| Here we can add sidebar links, paths and icons
|
*/
export const linksDossier = [
  { path: PRODUCTS_PATH, icon: HiHome, label: "Revenir à l'accueil" },
  { path: SOLDES, icon: MdDashboard, label: 'Tableau de Bord' },
  { path: FOURNISSEURS, icon: CgLayoutPin, label: 'Fournisseurs' },
  { path: CLIENT, icon: RiUserAddLine, label: 'Clients' },
  { path: TRANSACTION, icon: RiArrowLeftRightLine, label: 'Transactions' },
  { path: COMPTABILITE, icon: MdAccountBalance, label: 'Comptabilité' },
  { path: PARAMETRE_PATH, icon: RiSettingsFill, label: 'Paramètres' },

  // { path: DETAIL_FOURNISSEURS_PATH, icon: RiPercentFill, label: "DETAIL" },
  //{ path: FACTURE, icon: HiHome, label: 'Facture' },
];
