import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
//import axios from 'axios';
//URL
//import { GET_LIVRE_JOURNAL } from 'routes/api/endpoints';
import LivreJournal from './components/LivreJournal';
import GrandLivre from './components/GrandLivre';
import Balance from './components/Balance';


const ComptabilitePage = () => {
  const { clientID: dossier_id } = useParams();
  const [aff_livre,setAffLivre] = useState(true)
  const [aff_grandlivre,setAffGrandLivre] = useState(false)
  const [aff_balance,setAffBalance] = useState(false)

  const handleLivre = (e) =>{
    e.preventDefault()
    setAffLivre(true)
    setAffGrandLivre(false)
    setAffBalance(false)
  }
  const handleGrandLivre = (e) =>{
    e.preventDefault()
    setAffLivre(false)
    setAffGrandLivre(true)
    setAffBalance(false)
  }
  const handleBalance = (e) =>{
    e.preventDefault()
    setAffLivre(false)
    setAffGrandLivre(false)
    setAffBalance(true)
  }
  return (
    <div>
      <div className='buttons_compta'>
        <button className='' onClick={handleLivre}>Livre journal</button>
        <button className='' onClick={handleGrandLivre}>Grand Livre</button>
        <button className='' onClick={handleBalance}>Balance</button>
      </div>
      {
        aff_livre && <LivreJournal dossier_id={dossier_id} />
      }
      {
        aff_grandlivre && <GrandLivre dossier_id={dossier_id} />
      }
      {
        aff_balance && <Balance dossier_id={dossier_id} />
      }

    </div>
  )
}

export default ComptabilitePage