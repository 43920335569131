// ProductForm.js
import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { BiTrash } from 'react-icons/bi';

function ProductForm({
  product,
  index,
  handleProductChange,
  removeProduct,
  productOptions,
  totalProducts,
}) {

  //console.log("Les produits: ",productOptions)
  return (
    <div className="d-flex row mb-2">
      <Col className="col-6">
        <Form.Group controlId={`product-${index}`}>
          <Form.Label className="mb-2">Produit</Form.Label>
          <Form.Control
            as="select"
            name="produit"
            value={product.produit || ''}
            onChange={(e) => handleProductChange(index, e)}
            className="rounded rounded-pill"
          >
            <option value="">Sélectionner un produit</option>
            {productOptions?.map((prodOption) => (
              <option key={prodOption.id} value={prodOption.id}>
                {prodOption.nom}
              </option>
            ))}
            <option value="ajouterProduit">Ajouter un produit</option>
          </Form.Control>
        </Form.Group>
      </Col>
      <Col className="col-6">
        <div className="d-flex align-items-end gap-3">
          <Form.Group controlId={`quantity-${index}`} className="w-50">
            <Form.Label className="mb-2">Quantité</Form.Label>
            <Form.Control
              type="number"
              name="quantite"
              value={product.quantite || 1}
              onChange={(e) => handleProductChange(index, e)}
              className="rounded rounded-pill"
            />
          </Form.Group>
          <Form.Group controlId={`price-${index}`} className="w-50">
            <Form.Label className="mb-2">Prix</Form.Label>
            <Form.Control
              type="number"
              name="prix"
              value={product.prix || ''}
              onChange={(e) => handleProductChange(index, e)}
              className="rounded rounded-pill"
            />
          </Form.Group>
          {totalProducts > 1 && (
            <BiTrash
              className="fs-2 text-danger"
              style={{ cursor: 'pointer' }}
              onClick={() => removeProduct(index)}
            />
          )}
        </div>
      </Col>
    </div>
  );
}

export default ProductForm;
