/* eslint-disable react/react-in-jsx-scope */
import HomePage from 'pages/Home/HomePage';
import {
  ACCEUIL_PATH,
  ARCHIVE_PATH,
  CLIENT,
  DEPENSE,
  DESACTIVES_PATH,
  DETAIL_CLIENTS_PATH,
  DETAIL_FOURNISSEURS_PATH,
  DOSSIER_PATH,
  FOURNISSEURS,
  // PARAMETRE_PATH,
  PRODUCTS_PATH,
  SOLDES,
  VENTE,
  COMPTABILITE
  // AJOUTE_VENTE
} from '../navigationPaths';
import RequireAuth from 'layouts/RequireAuth';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import DashboardAppShell from 'layouts/AppShells/DashboardAppShell';
// import { Outlet } from "react-router-dom";
import ForderListPage from 'pages/ForderPage/ForderListPage';
import { Navigate } from 'react-router-dom';
import DashboardAppShellClient from 'layouts/AppShells/DashboardAppShellClient';
import Accueil from 'pagesDossierClient/accueil/accueil';
import DosierDesactivePage from 'pages/Desactives/DosierDesactivePage';
import DossierArchivePage from 'pages/DossierArchive/DossierArchivePage';
// import DossierActifPage from "pages/DossierActif/DossierActifPage";
import ParametrePage from 'pages/Parametre/ParametrePage';
import ClientPage from 'pagesDossierClient/Client/ClientPage';
import Fournisseurs from 'pagesDossierClient/fournisseurs/fournisseurs';
import DetailFournisseurs from 'pagesDossierClient/fournisseurs/detailFournisseurs';
import DepensesPage from 'pagesDossierClient/depenses/DepensesPage';
import VentePage from 'pagesDossierClient/ventes/Vente';
// import AjoutVente from 'pagesDossierClient/ventes/AjoutVente';
import AddDepensesForm from 'components/AddDepenses/AddDepensesForm';
import AddVenteForm from 'pagesDossierClient/ventes/AddVenteForm/AddVenteForm';
import DepenseDetail from 'pagesDossierClient/depenses/DepenseDetail';
import DetailModifPage from 'pagesDossierClient/ventes/detailModifVente';
import TransactionPage from 'pagesDossierClient/transactions/TransactionPage';
import TransactionDetails from 'pagesDossierClient/transactions/TransactionDetail';
import Facture from 'pagesDossierClient/facture/facture';
import DetailFacture from 'pagesDossierClient/facture/DetailFacture';
import ClientDetails from 'pagesDossierClient/Client/ClientDetails';
//import Balance from 'pagesDossierClient/balance/Balance';
import ComptabilitePage from 'pagesDossierClient/comptabilite/ComptabilitePage';
// import { Navigate } from "react-router-dom";



export const privateRoutes = [
  {
    path: ACCEUIL_PATH,
    errorElement: <ErrorBoundary />,

    element: <RequireAuth />,
    children: [
      {
        path: '*',
        element: <Navigate to={ACCEUIL_PATH} />,
      },
      {
        path: '',
        element: <DashboardAppShell />,
        children: [
          {
            path: '',
            element: <HomePage />,
          },
          {
            path: PRODUCTS_PATH,
            element: <ForderListPage />,
            children: [
              {
                path: '',
                element: <ForderListPage />,
              },
              {
                path: ':produitId',
                element: <p>Vous venez d&apos;ajouter un produit</p>,
              },
            ],
          },
          {
            path: DESACTIVES_PATH,
            element: <DosierDesactivePage />,
          },
          {
            path: ARCHIVE_PATH,
            element: <DossierArchivePage />,
          },
        ],
      },
      {
        path: DOSSIER_PATH,
        element: <DashboardAppShellClient />,
        children: [
          {
            path: SOLDES,
            element: <Accueil />,
          },
          {
            path: FOURNISSEURS,
            element: <Fournisseurs />,
          },
          {
            path: `${FOURNISSEURS}details/${DETAIL_FOURNISSEURS_PATH}`,
            element: <DetailFournisseurs />,
          },
          {
            path: CLIENT,
            element: <ClientPage />,
          },
          {
            path: `${CLIENT}details/${DETAIL_CLIENTS_PATH}`,
            element: <ClientDetails />,
          },
          {
            path: DEPENSE,
            element: <DepensesPage />,
          },
          {
            path: VENTE,
            element: <VentePage />,
          },
          {
            path: 'ventes/add-vente',
            element: <AddVenteForm />,
          },
          {
            path: `ventes/:id`,
            element: <DetailModifPage />,
          },
          {
            path: `depenses/add-depense`,
            element: <AddDepensesForm />,
          },
          {
            path: `depenses/detailDepense/:id`,
            element: <DepenseDetail />,
          },
          {
            path: 'transactions',
            element: <TransactionPage />,
          },
          {
            path: COMPTABILITE,
            element: <ComptabilitePage />
          },
          {
            path: 'transactions/:type/:id',
            element: <TransactionDetails />,
          },
          {
            path: 'parametre/',
            element: <ParametrePage />,
          },
          {
            path: 'facture/',
            element: <Facture />,
          },
          {
            path: 'facture/:id',
            element: <DetailFacture />,
          },
        ],
      },
      {
        path: '/nope',
        element: <p>Page vide</p>,
      },
    ],
  },
];
