import { ApiMananger } from "actions/rtkquery";
import { GET_Grand_Livre, GET_LIVRE_JOURNAL, GET_BALANCE_GENERAL,GET_CODES_COMPTABLES, GET_CODES } from "routes/api/endpoints";

const clientApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({

    getLivreJournal: build.query({
        query: ({dossier_id}) => `${GET_LIVRE_JOURNAL}?dossier_id=${dossier_id}`,
        providesTags: ["INVALIDATE_CLIENT"],
    }),
    getGrandLivre: build.query({
      query: ({dossier_id}) => `${GET_Grand_Livre}?dossier_id=${dossier_id}`,
      providesTags: ["INVALIDATE_CLIENT"],
    }),
    getBalance: build.query({
      query: ({dossier_id}) => `${GET_BALANCE_GENERAL}?dossier_id=${dossier_id}`,
      providesTags: ["INVALIDATE_CLIENT"],
    }),
    getCodesComptables: build.query({
      query: () => `${GET_CODES_COMPTABLES}`,
      providesTags: ["INVALIDATE_CLIENT"],
    }),
    getCodes: build.query({
      query: ({dossier_client}) => `${GET_CODES}?dossier_client=${dossier_client}`,
      providesTags: ["INVALIDATE_CLIENT"],
    }),
    getCodesFournisseurs: build.query({
      query: () => `${GET_CODES_COMPTABLES}fournisseurs/`,
      providesTags: ["INVALIDATE_CLIENT"],
    }),
    getCodesClients: build.query({
      query: () => `${GET_CODES_COMPTABLES}clients/`,
      providesTags: ["INVALIDATE_CLIENT"],
    }),
    getCodesSalaries: build.query({
      query: () => `${GET_CODES_COMPTABLES}salaries/`,
      providesTags: ["INVALIDATE_CLIENT"],
    }),
    getCodesTiers: build.query({
      query: () => `${GET_CODES_COMPTABLES}tiers/`,
      providesTags: ["INVALIDATE_CLIENT"],
    }),


  }),
});

export const {
  useGetGrandLivreQuery,
  useGetLivreJournalQuery,
  useGetBalanceQuery,
  useGetCodesComptablesQuery,
  useGetCodesQuery,
  useGetCodesClientsQuery,
  useGetCodesFournisseursQuery,
  useGetCodesSalariesQuery,
  useGetCodesTiersQuery
} = clientApi;
