import React from "react";
import CollaborateursIcon from "./CollaborateursIcon";

const RoleCard = ({title, description, tags, buttonColor, collaborateurs}) => (
  <div
    className="card m-0 mb-4 p-4"
    style={{
      flex: 1,
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      height: "360px",
      fontFamily: "Montserrat",
    }}>
    <div className="card-body">
      <div className="d-flex pb-2 justify-content-between align-items-center ">
        <h5 className="card-title">{title}</h5>
        <CollaborateursIcon collaborateurs={collaborateurs} />
      </div>
      <p className="card-text" style={{fontWeight: 400, fontSize: "15px"}}>
        {description}
      </p>
      <div className="d-flex flex-wrap">
        {Array.isArray(tags) &&
          tags.map((tag, index) => (
            <span key={index} className="badge bg-secondary me-2 mb-2">
              {tag}
            </span>
          ))}
      </div>
      <button
        type="button"
        className="btn mt-3 m-0 mb-5"
        style={{
          backgroundColor: buttonColor,
          position: "absolute",
          bottom: 0,
          color: "white",
          padding: "5px 17px",
          borderRadius: "40px",
          fontWeight: 400,
          fontSize: "15px",
        }}>
        {title}
      </button>
    </div>
  </div>
);

export default RoleCard;
