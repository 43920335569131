// TablePaginators.js

import React from 'react';
import { Pagination, Button, ButtonGroup } from 'react-bootstrap';
import '../../pagesDossierClient/depenses/client.css'; // Make sure this path is correct for your project

const TablePaginators = ({
  paginationIndex,
  setPaginationIndex,
  dataLength,
  pageSize,
  setPageSize,
  onActionSelected,
  isViewingArchived,
  buttonHide,
}) => {
  // Calculate the number of pages
  const pageCount = Math.ceil(dataLength / pageSize);

  // Functions for handling clicks
  const handlePageChange = (newIndex) => {
    if (newIndex === 'prev') {
      setPaginationIndex(Math.max(0, paginationIndex - 1));
    } else if (newIndex === 'next') {
      setPaginationIndex(Math.min(pageCount - 1, paginationIndex + 1));
    } else {
      setPaginationIndex(newIndex);
    }
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setPaginationIndex(0); // Reset to first page with new page size
  };

  const handleAction = (action) => {
    if (onActionSelected) {
      onActionSelected(action);
    }
  };

  // Determine the range of records being viewed
  const firstRecordIndex = paginationIndex * pageSize;
  const lastRecordIndex = Math.min(firstRecordIndex + pageSize, dataLength);

  return (
    <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center paginator-container gap-3 gap-lg-0">
      <div className="d-flex align-items-center gap-2">
        <span>Page </span>
        <Pagination>
          <Pagination.Item onClick={() => handlePageChange(paginationIndex)}>
            {paginationIndex + 1}
          </Pagination.Item>
        </Pagination>
        <span>
          {firstRecordIndex + 1} à {lastRecordIndex} sur {dataLength}
        </span>
        <Pagination>
          <Pagination.Prev
            onClick={() => handlePageChange('prev')}
            disabled={paginationIndex === 0}
          />
          <Pagination.Next
            onClick={() => handlePageChange('next')}
            disabled={paginationIndex === pageCount - 1}
          />
        </Pagination>
        <ButtonGroup className="d-none d-lg-block">
          {[20, 50, 100, 500].map((size) => (
            <Button
              key={size}
              variant={size === pageSize ? 'primary' : 'secondary'}
              onClick={() => handlePageSizeChange(size)}
            >
              {size}
            </Button>
          ))}
        </ButtonGroup>
      </div>
      <ButtonGroup className={`d-flex gap-2 action-buttons ${buttonHide}`}>
        <Button
          variant={isViewingArchived ? 'primary' : 'outline-secondary'}
          onClick={() => handleAction('archive')}
        >
          {isViewingArchived ? 'Actives' : 'Archivées'}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default TablePaginators;
