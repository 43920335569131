import React from 'react'

const DossierCheckBox = ({handleDossiersChecked,dossier,index}) => {
  return (
    <div className="d-flex row mb-2">
        <div className="d-flex align-items-center gap-1">
          <div className="">{dossier.nom_entreprise}</div>
          <input
              type='checkbox'
              name="dossier_assignes"
              onChange={(e) => handleDossiersChecked(index, e,dossier)}
              className="rounded rounded-pill"
            />

        </div>
    </div>
  )
}

export default DossierCheckBox