import React,{useEffect} from 'react'
import { useGetBalanceQuery } from 'actions/comptabilite/queries'
import Table from 'react-bootstrap/Table';
import "./style/style.css"

const Balance = ({dossier_id}) => {
    const {
        data: balance,
        refetch
      } = useGetBalanceQuery({dossier_id: dossier_id});
    
        
        useEffect(() => {
          refetch()
        },[balance])

        console.log(balance)
  return (
    <div>
        <h2>Balance Générale</h2>
        <Table striped bordered hover>
      <thead>
        <tr>
          <th>Numéro Compte</th>
          <th>Libellé</th>
          <th>Débit</th>
          <th>Crédit</th>
          <th>Solde_Débiteur</th>
          <th>Solde_Créditeur</th>
        </tr>
      </thead>
      <tbody>
      {
        balance &&
        balance["balance"]?.map((ecriture, index) => {
            return(
              <tr key={index}>
                <td>{ecriture["numero_comptable"]}</td>
                <td>{ecriture["libelle"]}</td>
                <td>{ecriture["debit"]}</td>
                <td>{ecriture["credit"]}</td>
                <td>
                    {
                        ecriture["solde_debiteur"] == 0?(
                            <>-</>
                        ):(
                            <>{ecriture["solde_debiteur"]}</>
                        )
                    }
                </td>
                <td>
                    {
                        ecriture["solde_crediteur"] == 0?(
                            <>-</>
                        ):(
                            <>{ecriture["solde_crediteur"]}</>
                        )
                    }
                </td>
                
              </tr>
            )
          })
        }
        {
            balance &&(
                <tr>
                    <td>-</td>
                    <td className='data'>TOTAL</td>
                    <td className='data'>{balance["total_debits"]}</td>
                    <td className='data'>{balance["total_credits"]}</td>
                    <td className='data'>{balance["total_crediteur"]}</td>
                    <td className='data'>{balance["total_debiteur"]}</td>
                    
                </tr>
            )
        }
        
      </tbody>
    </Table>
        
    </div>
  )
}

export default Balance