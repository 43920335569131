// Modal.js
import React, {useState, useEffect} from "react";
import "./Modal.css";
// import { useDispatch } from "react-redux";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
//import ButtonP from 'components/buttons/ButtonP';
import {useCreateFactureMutation} from "actions/facture_comp/mutations";
import ProductLine from "./File/ProductLine";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import FileUpload from "./File/FileUpload";

const ModalClient = ({
  isOpen,
  onClose,
  onDossierCreated,
  factures,
  codes,
  refetch,
  dossier_id,
}) => {
  //const { clientID: id } = useParams();
  const navigate = useNavigate();
  console.log("Factures avant insertion: ", factures);
  const [addFacture] = useCreateFactureMutation();
  //const [load,setLoad] = useState(false)
  //console.log( codes.sort((a,b) => (a.libelle > b.libelle) ? 1 : ((b.libelle > a.libelle) ? -1 : 0)))

  const [facture, setFacture] = useState({
    name: "",
    dossier_client: dossier_id,
    type: "Achat",
    mode_payment: "Caisse",
    somme: 0,
    produits_associes: [
      {
        description: "",
        code: 0,
        quantite: 1,
        prix_unitaire_ht: 0,
        taxe: 0,
        prix_total_ttc: 0,
      },
    ],
  });

  const handleProductChange = (index, event) => {
    const {name, value} = event.target;
    let updatedProduitsAssocies = [...facture.produits_associes];

    // Ensure the product exists in the array or create a new entry if needed
    if (!updatedProduitsAssocies[index]) {
      updatedProduitsAssocies[index] = {
        description: "",
        quantite: 1,
        prix_unitaire_ht: 0,
        taxe: 0,
        prix_total_ttc: 0,
      };
    }

    // Update the specific product's property based on the input field name
    updatedProduitsAssocies[index][name] =
      name === "description" ? value : Number(value);

    setFacture({...facture, produits_associes: updatedProduitsAssocies});
  };

  // Handle input changes
  const handleInputChange = (event) => {
    const {name, value, type, checked} = event.target;

    setFacture((prevState) => {
      if (type === "checkbox") {
        return {...prevState, [name]: checked};
      } else if (name == "name" || name == "type" || name == "mode_payment") {
        const strValue = value ? value : "";
        return {...prevState, [name]: strValue};
      } else {
        return {...prevState, [name]: parseInt(value, 10)};
      }
    });
  };

  // fichier joint
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Verify all necessary fields are populated.
    const requiredFields = ["somme", "type", "name", "mode_payment"];
    const areFieldsFilled = requiredFields.every(
      (field) => facture[field] && facture[field].toString().trim() !== ""
    );

    if (!areFieldsFilled || !facture.produits_associes.length) {
      toast.error("Tous les champs sont obligatoires.");
      return;
    }

    // Construct the main data payload as a JSON object.
    let dataPayload = {
      name: facture.name,
      type: facture.type,
      dossier_client: facture.dossier_client,
      somme: facture.somme,
      mode_payment: facture.mode_payment,
      produits_associes: facture.produits_associes,
    };

    // Check for the presence of a file to be uploaded.
    if (selectedFile) {
      const formData = new FormData();

      // Append scalar fields directly
      formData.append("name", facture.name);
      formData.append("type", facture.type);
      formData.append("mode_payment", facture.mode_payment);
      formData.append("dossier_client", facture.dossier_client);
      formData.append("somme", facture.somme);
      formData.append("file", selectedFile);
      formData.append("produits", JSON.stringify(facture.produits_associes));

      // Perform the POST request
      try {
        await addFacture({dossier: dossier_id, formData: formData}).unwrap();
        refetch();
        toast.success("Facture ajoutée avec succès");
        onDossierCreated();

        onClose();
      } catch (error) {
        console.error("Requête échouée:", error);
        toast.error(
          `Erreur: ${error.data?.message}` || "Echec d'ajout de dépense"
        );
      }
    } else {
      // If there is no file, send JSON data directly.
      try {
        await addFacture(
          {dossier: dossier_id, formData: dataPayload},
          {
            headers: {"Content-Type": "application/json"},
          }
        ).unwrap();
        refetch();
        toast.success("Facture ajoutée avec succès");
        onDossierCreated();
        onClose();
      } catch (error) {
        console.error("Requête échouée:", error);
        toast.error(
          `Error: ${error.data?.message}` || `Échec de l'ajout d'une facture`
        );
      }
    }
  };

  const addProduct = () => {
    setFacture((prevState) => ({
      ...prevState,
      produits_associes: [
        ...prevState.produits_associes,
        {
          description: "",
          quantite: 1,
          prix_unitaire_ht: 0,
          taxe: 0,
          prix_total_ttc: 0,
        },
      ],
    }));
  };

  const removeProduct = (index) => {
    setFacture((prevState) => ({
      ...prevState,
      produits_associes: prevState.produits_associes.filter(
        (_, i) => i !== index
      ),
    }));
  };

  // This useEffect is for calculate automatically the total price
  useEffect(() => {
    var somme = 0;

    const total = facture.produits_associes.map((produit) => {
      somme += parseInt(produit.prix_total_ttc);
      return somme;
    });

    const montant_total = total[total.length - 1];
    console.log("Somme Total: ", montant_total);
    setFacture({...facture, somme: montant_total});
  }, [facture.produits_associes]);
  ///////////////////////////////////////////////////////////////////

  return (
    <div style={{overflow: "scroll"}}>
      {isOpen && (
        <div className="modal-overlay w-full" onClick={onClose}>
          <div
            className="modal-content-cabinet bg-white"
            onClick={(e) => e.stopPropagation()}
            style={{borderRadius: "20px"}}>
            <h2 className="modal-header">Ajouter un Document</h2>
            <hr />
            <div className="container-background w-100 w-md-75 px-0 px-md-5">
              <Container fluid className="container-style p-4 mb-5">
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                  <div className="d-flex flex-column align-items-center gap-5">
                    <div className="w-100 d-flex flex-column gap-2">
                      <Row className="row-gap-2">
                        <Col sm={6}>
                          <Form.Group controlId="formFournisseur">
                            <Form.Label className="mb-2">Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              value={facture.name}
                              onChange={handleInputChange}
                              className="rounded rounded-pill"
                            />
                          </Form.Group>
                        </Col>

                        <Col sm={6}>
                          <Form.Group controlId="formIntitule">
                            <Form.Label className="mb-2">Somme</Form.Label>
                            <Form.Control
                              type="number"
                              name="somme"
                              value={facture.somme}
                              onChange={handleInputChange}
                              disabled={true}
                              className="rounded rounded-pill"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {/* ... Other input fields ... */}
                      <Row className="row-gap-2">
                        <Col sm={6}>
                          <Form.Group controlId="formSourcePaiement">
                            <Form.Label className="mb-2">Type</Form.Label>
                            <Form.Control
                              as="select"
                              name="type"
                              value={facture.type}
                              onChange={handleInputChange}
                              className="rounded rounded-pill">
                              <option value="">- Choisissez -</option>
                              <option value="Achat">Achat</option>
                              <option value="Vente">Vente</option>
                              <option value="Salaire">Salaires</option>
                              <option value="Immobilisation">Immobilisations</option>
                              <option value="Autres">Autres</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        <Col sm={6}>
                          <Form.Group controlId="formSourcePaiement">
                            <Form.Label className="mb-2">
                              Mode de Paiement
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="mode_payment"
                              value={facture.mode_payment}
                              onChange={handleInputChange}
                              className="rounded rounded-pill">
                              <option value="">- Choisissez -</option>
                              <option value="Caisse">Caisse</option>
                              <option value="Banque">Banque</option>
                              <option value="Mobile">Mobile Money</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                      </Row>

                      <Row>
                        <Col>
                          {facture.produits_associes.map((product, index) => (
                            <ProductLine
                              key={index}
                              product={product}
                              index={index}
                              handleProductChange={handleProductChange}
                              removeProduct={removeProduct}
                              totalProducts={facture.produits_associes?.length}
                              codes={codes}
                            />
                          ))}
                          <Button
                            onClick={addProduct}
                            className="mt-3 rounded rounded-pill"
                            style={{
                              backgroundColor: "#068f7a",
                              borderColor: "#068f7a",
                            }}>
                            Ajouter un produit
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Form.Group
                        controlId="fileUploadControl"
                        className="w-100">
                        <FileUpload onFileSelect={handleFileSelect} />
                      </Form.Group>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={12} className="text-center">
                        <div className="d-flex gap-3">
                          <button
                            onClick={() => navigate(-1)}
                            className="btn btn-danger rounded rounded-pill px-4">
                            Annuler
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary rounded rounded-pill px-4"
                            style={{
                              backgroundColor: "#068f7a",
                              borderColor: "#068f7a",
                            }}
                            disabled={
                              !facture.name || !facture.somme || !facture.type
                            } // Add other fields as necessary
                          >
                            Enregistrer
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {/* ... Other input fields ... */}
                </Form>
              </Container>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalClient;
