import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetDepenseByIdQuery } from 'actions/depenses/queries';
import HeaderFirst from 'utils/HeaderFirst';
import InputDetail from 'components/Inputs/InputDetail';
import useDepensesData from './useDepensesData';
import './client.css';
import FilePreview from 'components/FilePreview/FilePreview';
import { useGetProduitsQuery } from 'actions/produits/queries';
import Product from 'components/Product/Product';

const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  return new Date(dateString)
    .toLocaleDateString('fr-FR', options)
    .replace(',', '');
};

export default function DepenseDetail() {
  const { id } = useParams();
  const { id: depense_id } = useParams();
  const { clientID: dossier_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const isEditing = new URLSearchParams(location.search).get('edit') === 'true';
  const {
    data: getDepense,
    error: depenseError,
    isLoading: isLoadingDepense,
  } = useGetDepenseByIdQuery({ id, dossier_id });
  const depenseDetail = getDepense?.data[0] || [];
  const { data: produitsData } = useGetProduitsQuery({ dossier_id });
  const { handleUpdateDepense } = useDepensesData(depense_id);
  const [updateData, setUpdateData] = useState({
    date: '',
    intitule: '',
    description: '',
    quantite: '',
    prix: '',
    produits_associes: [],
  });

  useEffect(() => {
    if (depenseDetail && isEditing) {
      // Assuming produitsData is already loaded and available
      const enrichedProduits = (depenseDetail.produits_associes || []).map(
        (prodAssoc) => {
          // Attempt to enrich each product with additional details if available
          const productDetail =
            produitsData?.data?.find((p) => p.id === prodAssoc.produit) || {};

          return {
            id: prodAssoc.produit, // Keep the product ID
            nom: productDetail.nom || prodAssoc.nom || 'Inconnu',
            quantite: prodAssoc.quantite?.toString() || '', // Convert quantite to string
            prix: prodAssoc.prix?.toString() || '', // Convert prix to string
          };
        }
      );

      setUpdateData({
        date: depenseDetail.updated_at_at || '',
        intitule: depenseDetail.intitule || '',
        description: depenseDetail.description || '',
        produits_associes: enrichedProduits,
      });
      console.log(updateData);
    }
  }, [depenseDetail, isEditing, produitsData]);

  const handleQuantiteChange = (index, newQuantite) => {
    setUpdateData((prevState) => {
      const updatedProduits = [...prevState.produits_associes];
      updatedProduits[index].quantite = newQuantite.toString();
      return { ...prevState, produits_associes: updatedProduits };
    });
  };

  const handlePrixChange = (index, newPrix) => {
    setUpdateData((prevState) => {
      const updatedProduits = [...prevState.produits_associes];
      updatedProduits[index].prix = newPrix.toString();
      return { ...prevState, produits_associes: updatedProduits };
    });
  };

  const setDepenseDetail = async (e) => {
    e.preventDefault();
    const submitData = {
      ...updateData,
      produits_associes: updateData.produits_associes.map((prod) => ({
        produit: prod.id, // Assuming prod.id contains the product ID.
        quantite: parseFloat(prod.quantite), // Ensure quantite is a number.
        prix: prod.prix.toString(), // Ensure prix is a string.
      })),
    };

    // Call the update function with the prepared data.
    await handleUpdateDepense(id, submitData);
    navigate(-1); // Redirect after successful update.
  };

  const nom = (
    <div style={{ display: 'flex' }}>
      <h4 className="titre-dossier">Détail de sortie: {depenseDetail?.id}</h4>
    </div>
  );

  if (isLoadingDepense) return <div>Chargement des détails de dépense...</div>;
  if (depenseError) return <div>Une erreur est survenue pour la dépense.</div>;

  return (
    <div className="depense-detail-container">
      <div className="d-flex align-items-center">
        <HeaderFirst
          nom={nom}
          description={
            <div>Vous pouvez voir ou modifier les détails cette sortie</div>
          }
        />
      </div>
      <form
        className="form-layout"
        onSubmit={setDepenseDetail}
        style={{ padding: '15px' }}
      >
        <div className="input-row row">
          <div className="col-md-6">
            <InputDetail
              label="Fournisseur"
              name="fournisseur"
              disabled
              value={depenseDetail?.fournisseurs_details?.nom}
              onChange={(e) =>
                setDepenseDetail({
                  ...depenseDetail,
                  fournisseur: e.target.value,
                })
              }
              className={'custom-input'}
            />
          </div>
          <div className="col-md-6">
            <InputDetail
              label="Date"
              name="date"
              type="text"
              disabled
              value={formatDate(depenseDetail.updated_at)}
              onChange={(e) =>
                setDepenseDetail({ ...depenseDetail, date: e.target.value })
              }
              className={'custom-input'}
            />
          </div>
        </div>

        {/* Add other input fields as necessary */}
        <div className="input-row row">
          <div className="col-md-6">
            <InputDetail
              label="Intitulé"
              name="intitule"
              value={updateData.intitule}
              onChange={(e) =>
                setUpdateData({ ...updateData, intitule: e.target.value })
              }
              className={'custom-input'}
            />
          </div>
          <div className="col-md-6">
            <InputDetail
              label="Description"
              name="description"
              value={updateData.description}
              onChange={(e) =>
                setUpdateData({ ...updateData, description: e.target.value })
              }
              className={'custom-input'}
            />
          </div>
        </div>
        {updateData.produits_associes &&
        updateData.produits_associes.length > 0 ? (
          updateData.produits_associes.map((prod, index) => (
            <Product
              key={prod.id}
              id={prod.id}
              index={index}
              nom={prod.nom}
              quantite={prod.quantite}
              prix={prod.prix}
              onQuantiteChange={handleQuantiteChange}
              onPrixChange={handlePrixChange}
            />
          ))
        ) : (
          <p>No products associated.</p>
        )}
        <div className="input-row d-flex">
          <div className="pieceContainer col-md-6">
            <p
              style={{ color: '#068F7A', fontWeight: 'bold' }}
              className="form-label"
            >
              Piece jointe
            </p>
            <Link to={depenseDetail?.piece_joint}>
              <div className="card py-3">
                <div className="card-body d-flex align-items-center justify-content-center text-center">
                  <FilePreview filePath={depenseDetail?.piece_joint} />
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="form-navigation-buttons">
          <button type="submit" className="next-button">
            Modifier
          </button>
        </div>
      </form>
    </div>
  );
}
