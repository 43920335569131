import { ApiMananger } from 'actions/rtkquery';
import {
  CLIENT_FILE_API_ROUTE,
  FOURNISSEUR_FILE_API_ROUTE,
  SALARIES_FILE_API_ROUTE,
} from 'routes/api/endpoints';

const importerApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    // Mutation for adding clients by file
    addFileClients: build.mutation({
      query(data) {
        return {
          url: `${CLIENT_FILE_API_ROUTE}`,
          method: "POST",
          body: data["formData"],
          formData: true,
        };
      },
      invalidatesTags: ['CLIENTS'],
    }),

     // Mutation for adding fournisseurs by file
     addFileFournisseurs:  build.mutation({
        query(data) {
          return {
            url: `${FOURNISSEUR_FILE_API_ROUTE}`,
            method: "POST",
            body: data["formData"],
            formData: true,
          };
        },
        invalidatesTags: ['FOURNISSEURS'],
      }),

     // Mutation for adding salariés by file
     addFileSalaries: build.mutation({
        query(data) {
          return {
            url: `${SALARIES_FILE_API_ROUTE}`,
            method: "POST",
            body: data["formData"],
            formData: true,
          };
        },
        invalidatesTags: ['SALARIES'],
      }),

    
    // You can add more depense-related mutations and queries here if necessary...
  }),
  overrideExisting: false,
});

export const {
  useAddFileClientsMutation,
  useAddFileFournisseursMutation,
  useAddFileSalariesMutation,
} = importerApi;
