import React,{useEffect} from 'react'
import { useGetGrandLivreQuery } from 'actions/comptabilite/queries'
import Table from 'react-bootstrap/Table';
import "./style/style.css"

const GrandLivre = ({dossier_id}) => {
    const {
        data: grandlivre,
        refetch
      } = useGetGrandLivreQuery({dossier_id: dossier_id});
    
        
        useEffect(() => {
          refetch()
        },[grandlivre])

        console.log(grandlivre)
  return (
    <div>
        <h2>GrandLivre</h2>
        <Table striped bordered hover>
      <thead>
        <tr>
          <th>Numéro Compte</th>
          <th>Libellé</th>
          <th>Crédit</th>
          <th>Débit</th>
        </tr>
      </thead>
      <tbody>
      {
        grandlivre &&
        grandlivre["grand-livre"]?.map((ecriture, index) => {
            return(
              <tr key={index}>
                <td>{ecriture["numero_comptable"]}</td>
                <td>{ecriture["libelle"]}</td>
                <td>{ecriture["debit"]}</td>
                <td>{ecriture["credit"]}</td>
              </tr>
            )
          })
        }
        
        {grandlivre && (
          <tr>
            <td>-</td>
            <td className='data'>TOTAL</td>
            <td className='data'>{grandlivre["total_debits"]}</td>
            <td className='data'>{grandlivre["total_credits"]}</td>             
        </tr>
        )}
            
      </tbody>
    </Table>
        
    </div>
  )
}

export default GrandLivre