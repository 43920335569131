import { ApiMananger } from "actions/rtkquery";
import { GET_VENTE_API_ROUTE } from "routes/api/endpoints";

const venteApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
      
    getVente: build.query({
        query: ({ dossier_id }) => `${GET_VENTE_API_ROUTE}?dossier_id=${dossier_id}`,
        providesTags: ["INVALIDATE_VENTE"],
    }),
    getVenteId: build.query({
        query: ({ dossier_id, id }) => `${GET_VENTE_API_ROUTE}?dossier_id=${dossier_id}&id=${id}`,
        providesTags: ["INVALIDATE_VENTE"],
    }),
}),
});

export const {
useGetVenteQuery,
useGetVenteIdQuery,
} = venteApi;
