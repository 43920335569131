// ProductModal.js
import React,{useState,useEffect} from 'react';
import { Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useGetCodesComptablesQuery } from 'actions/comptabilite/queries';

function ProductModal({
  showModal,
  handleClose,
  formMethods,
  creationProduit,
  dossier_id,
  produits
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = formMethods;

  const {
    data: codes=[],

  } = useGetCodesComptablesQuery();


  console.log("Je vous affiche les produits: ",produits)

  let numeros = produits?.map((produit) => produit.code_comptable.toString())
  console.log("Les numéros de compte: ",numeros)

  const [errorCompte, setErrorCompt] = useState("") //Permet d'indiquer   à l'utilisateur que le numero de compte existe ou pas
  //const numeroCompte = watch({name:"code_comptable"}) // Cible le numero de compte 
  const watchCodeCompte = watch("code_comptable", "");

  const onSubmit = (data) => {
    const produitData = { ...data, dossier_id: dossier_id };
    creationProduit(produitData)
      .unwrap()
      .then((res) => {
        console.log(res);
        reset();
        toast.success('Création de produit réussi !');
        handleClose();
        reset();
      })
      .catch((error) => {
        toast.error('Une erreru est survenue');
        toast.error(`Error: ${error.data?.message}` || 'Failed to add product');
        console.error('Request failed:', error);
      });
  };

  useEffect(() => {
    // Example action when firstName changes
    console.log('Numero compte has changed:', watchCodeCompte);

    if (numeros?.includes(watchCodeCompte)){
      setErrorCompt("Ce Numero de compte existe déjà")
    } 
    else if (watchCodeCompte.length < 2 && watchCodeCompte.length >= 1){
      setErrorCompt("Numero de compte trop court")
    }
    else if (codes?.includes(watchCodeCompte)){
      setErrorCompt("Ce Numero est un numero du PLAN COMPTABLE SYSCOHADA, créez une subdivision")
    }
    else{
      setErrorCompt("")   
    }
  
  },[watchCodeCompte,codes])



  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un produit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="formNom">
            <Form.Label>Nom</Form.Label>
            <Form.Control
              {...register('nom', { required: 'Ce champ est requis' })}
              className="rounded rounded-pill"
            />
            {errors.nom && (
              <span className="text-danger">{errors.nom.message}</span>
            )}
          </Form.Group>
          <Form.Group controlId="formCodeComptable">
            <Form.Label>Code comptable</Form.Label>
            <Form.Control
              {...register('code_comptable', {
                required: 'Ce champ est requis',
              })}
              type="number"
              className="rounded rounded-pill"
              name="code_comptable"
            />
            
            {errorCompte != "" && (
              <span className="text-danger">
                {errorCompte}
              </span>
            )}
            {errors.code_comptable && (
              <span className="text-danger">
                {errors.code_comptable.message}
              </span>
            )}
          </Form.Group>

          <Form.Group controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              {...register('description')}
              className="rounded"
            />
          </Form.Group>

          <div className="mt-5 d-flex gap-2">
            <button
              className="btn btn-secondary px-3 rounded rounded-pill"
              onClick={handleClose}
            >
              Fermer
            </button>
            <button
              style={{
                backgroundColor: '#068f7a',
                borderColor: '#068f7a',
              }}
              type="submit"
              className="btn btn-primary px-3 rounded rounded-pill"
              disabled={errorCompte != ""?true:false}
            >
              Sauvegarder
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ProductModal;
