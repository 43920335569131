import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import HeaderFirst from 'utils/HeaderFirst';
import InputDetail from 'components/Inputs/InputDetail';
import './client.css';
import FilePreview from 'components/FilePreview/FilePreview';
import Product from 'components/Product/Product';
import { useGetVenteIdQuery } from 'actions/ventes/queries';
import useVentesData from './useVentesData';
import { toast } from 'react-toastify';
import { useGetProduitsQuery } from 'actions/produits/queries';
// import { DEFAULT_API_IMAGE } from 'routes/api/endpoints';

const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  return new Date(dateString)
    .toLocaleDateString('fr-FR', options)
    .replace(',', '');
};

const DetailModifPage = () => {
  const { id } = useParams();
  const { clientID: dossier_id } = useParams();
  const navigate = useNavigate();
  const {
    data: venteDetail,
    isLoading: isLoadingVente,
    error: venteError,
  } = useGetVenteIdQuery({ id, dossier_id });
  const { handleUpdateVente } = useVentesData(id);
  const { data: produitsData } = useGetProduitsQuery({ dossier_id });
  const [updateData, setUpdateData] = useState({
    date: '',
    intitule: '',
    description: '',
    produits_associes: [],
  });

  useEffect(() => {
    // Check if venteDetail.data exists and has at least one item
    if (venteDetail && venteDetail.data && venteDetail.data.length > 0) {
      const venteData = venteDetail.data[0];
      const enrichedProduits = (venteData.produits_associes || []).map(
        (prodAssoc) => {
          // Attempt to enrich each product with additional details if available
          const productDetail =
            produitsData?.data?.find((p) => p.id === prodAssoc.produit) || {};

          return {
            id: prodAssoc.produit, // Keep the product ID
            nom: productDetail.nom || prodAssoc.nom || 'Inconnu',
            quantite: prodAssoc.quantite?.toString() || '', // Convert quantite to string
            prix: prodAssoc.prix?.toString() || '', // Convert prix to string
          };
        }
      );

      setUpdateData({
        date: formatDate(venteData.updated_at),
        produits_associes: enrichedProduits,
        montant_total: venteData.montant_total.toString(),
        description: venteData.description,
        intitule: venteData.intitule || '',
      });
    }
  }, [venteDetail]);

  const handleQuantiteChange = (index, newQuantite) => {
    setUpdateData((prevState) => {
      const updatedProduits = [...prevState.produits_associes];
      updatedProduits[index].quantite = newQuantite.toString();
      return { ...prevState, produits_associes: updatedProduits };
    });
  };

  const handlePrixChange = (index, newPrix) => {
    setUpdateData((prevState) => {
      const updatedProduits = [...prevState.produits_associes];
      updatedProduits[index].prix = newPrix.toString();
      return { ...prevState, produits_associes: updatedProduits };
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const submitData = {
      ...updateData,
      produits_associes: updateData.produits_associes.map((prod) => ({
        produit: prod.id, // Assuming prod.id contains the product ID.
        quantite: parseFloat(prod.quantite), // Ensure quantite is a number.
        prix: prod.prix.toString(), // Ensure prix is a string.
      })),
    };
    await handleUpdateVente(id, submitData);
    toast.success('Mise à jour réussi');
    navigate(-1);
  };

  const nom = (
    <div style={{ display: 'flex' }}>
      <h4 className="titre-dossier">
        Détail de vente: {venteDetail?.data?.[0]?.id || 'Chargement...'}
      </h4>
    </div>
  );

  if (isLoadingVente) return <div>Chargement des détails de vente...</div>;
  if (venteError) return <div>Une erreur est survenue pour la vente.</div>;

  return (
    <div className="vente-detail-container">
      <div className="d-flex align-items-center">
        <HeaderFirst
          nom={nom}
          description={
            <div>Vous pouvez voir ou modifier les détails de cette entrée</div>
          }
        />
      </div>
      <form
        className="form-layout"
        onSubmit={onSubmit}
        style={{ padding: '15px' }}
      >
        <div className="input-row row">
          <div className="col-md-6">
            <InputDetail
              label="Client"
              name="client"
              disabled
              value={venteDetail.data[0].client_details.nom}
              className={'custom-input'}
            />
          </div>
          <div className="col-md-6">
            <InputDetail
              label="Date"
              name="date"
              type="text"
              disabled
              value={updateData.date}
              className={'custom-input'}
            />
          </div>
        </div>

        <div className="input-row row">
          <div className="col-md-6">
            <InputDetail
              label="Intitulé"
              name="intitule"
              value={updateData.intitule}
              onChange={(e) =>
                setUpdateData({ ...updateData, intitule: e.target.value })
              }
              className={'custom-input'}
            />
          </div>
          <div className="col-md-6">
            <InputDetail
              label="Description"
              name="description"
              value={updateData.description}
              onChange={(e) =>
                setUpdateData({ ...updateData, description: e.target.value })
              }
              className={'custom-input'}
            />
          </div>
        </div>

        {updateData.produits_associes &&
        updateData.produits_associes.length > 0 ? (
          updateData.produits_associes.map((prod, index) => (
            <Product
              key={prod.id}
              id={prod.id}
              index={index}
              nom={prod.nom}
              quantite={prod.quantite}
              prix={prod.prix}
              onQuantiteChange={handleQuantiteChange}
              onPrixChange={handlePrixChange}
            />
          ))
        ) : (
          <p>Aucun produit associé.</p>
        )}

        <div className="input-row d-flex">
          <div className="pieceContainer col-md-6">
            <p
              style={{ color: '#068F7A', fontWeight: 'bold' }}
              className="form-label"
            >
              Pièce jointe
            </p>
            <Link to={venteDetail?.data[0]?.piece_joint}>
            <div className="card py-3">
              <div className="card-body d-flex align-items-center justify-content-center text-center">
                <FilePreview filePath={venteDetail.data[0]?.piece_joint} />
              </div>
            </div>
            </Link>
          </div>
        </div>

        <div className="form-navigation-buttons">
          <button type="submit" className="next-button">
            Modifier
          </button>
        </div>
      </form>
    </div>
  );
};

export default DetailModifPage;
