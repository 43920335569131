import React from "react";
import PropTypes from "prop-types"; // Import PropTypes from 'prop-types'

const ResourceDataMap = ({ resourceData, resourceItem: ResourceItem, resourceName }) => {

  return (
    <div style={{ marginBottom:"-20px"}}>
      {resourceData.map((resource, i) => (
        <ResourceItem key={i} {...{ [resourceName]: resource }}/>
      ))}
    </div>
  );
};

// Add PropTypes validation for the props
ResourceDataMap.propTypes = {
  resourceData: PropTypes.array.isRequired,
  resourceItem: PropTypes.elementType.isRequired,
  resourceName: PropTypes.string.isRequired,
};

export default ResourceDataMap;
