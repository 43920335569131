import { ApiMananger } from 'actions/rtkquery';
import {
  ADD_FOURNISSEUR_API_ROUTE,
  UPDATE_FOURNISSEUR_API_ROUTE,
  DELETE_FOURNISSEUR_API_ROUTE,
} from 'routes/api/endpoints';

const fournisseurApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    // Mutation for creating a new fournisseur instance
    createFournisseur: build.mutation({
      query(body) {
        return {
          url: ADD_FOURNISSEUR_API_ROUTE,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['FOURNISSEUR'],
    }),

    // Mutation for updating an existing fournisseur instance
    updateFournisseur: build.mutation({
      query: ({ id, ...updateData }) => ({
        url: `${UPDATE_FOURNISSEUR_API_ROUTE}${id}/`,
        method: 'PUT',
        body: updateData,
      }),
      invalidatesTags: ['FOURNISSEUR'], // Invalidate the cache tags to refetch fournisseur-related data
    }),

    // Mutation for deleting an existing fournisseur instance
    deleteFournisseur: build.mutation({
      query: ({id}) => ({
        url: `${DELETE_FOURNISSEUR_API_ROUTE}${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['FOURNISSEUR'], // Invalidate to refetch fournisseur-related data
    }),

    // More fournisseur-related mutations and queries can be added here...
  }),
  overrideExisting: false,
});

export const {
  useCreateFournisseurMutation,
  useUpdateFournisseurMutation,
  useDeleteFournisseurMutation,
} = fournisseurApi;
