// Modal.js
import React, { useEffect } from 'react';
import './Modal.css'; // Ensure this path matches your file structure
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/features/userSliceWithTokenValidation'; // Adjust according to your project structure
import { useCreateDossierMutation } from 'actions/dossiers/mutations'; // Adjust according to your project structure
import ButtonP from 'components/buttons/ButtonP'; // Adjust according to your project structure

const Modal = ({ isOpen, onClose, onDossierCreated }) => {
  const userStatus = useSelector(getUser);
  const [createDossier] = useCreateDossierMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useFormContext();

  // Handle Enter key press to submit the form
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const onSubmit = (data) => {
    const dossierData = { ...data, comptable: userStatus?.id };
    createDossier(dossierData)
      .unwrap()
      .then(() => {
        toast.success('Création du dossier réussi !');
        reset();
        onDossierCreated();
      })
      .catch((error) => {
        console.error(error);
        toast.error('Erreur lors de la création du dossier.');
      });
  };

  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div
            className="modal-content bg-white"
            onClick={(e) => e.stopPropagation()}
            style={{ borderRadius: '20px' }}
          >
            <h2 className="modal-header">Ajouter un dossier</h2>
            <hr />
            <form
              className="auth-form"
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              {/* Company Name Input */}
              <div className="form-group">
                <label htmlFor="nom_entreprise" className="form-label">
                  Nom de l&#39;entreprise
                </label>
                <input
                  className="form-control custom-rounded"
                  id="nom_entreprise"
                  placeholder="Nom de l'entreprise"
                  {...register('nom_entreprise', {
                    required: 'Nom de l’entreprise obligatoire',
                  })}
                />
                {errors.nom_entreprise && (
                  <div className="text-danger">
                    {errors.nom_entreprise.message}
                  </div>
                )}
              </div>

              {/* Address Input */}
              <div className="form-group">
                <label htmlFor="adresse" className="form-label">
                  Adresse de l&#39;entreprise
                </label>
                <input
                  className="form-control custom-rounded"
                  id="adresse"
                  placeholder="Adresse"
                  {...register('adresse', { required: 'Adresse obligatoire' })}
                />
                {errors.adresse && (
                  <div className="text-danger">{errors.adresse.message}</div>
                )}
              </div>

              {/* Phone Number Input */}
              <div className="form-group">
                <label htmlFor="telephone" className="form-label">
                  Numéro de téléphone
                </label>
                <input
                  className="form-control custom-rounded"
                  id="telephone"
                  placeholder="Téléphone"
                  type="tel"
                  {...register('telephone', {
                    required: 'Numéro de téléphone obligatoire',
                  })}
                />
                {errors.telephone && (
                  <div className="text-danger">{errors.telephone.message}</div>
                )}
              </div>

              {/* Email Input */}
              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  E-mail
                </label>
                <input
                  className="form-control custom-rounded"
                  id="email"
                  placeholder="E-mail"
                  type="email"
                  {...register('email', { required: 'E-mail obligatoire' })}
                />
                {errors.email && (
                  <div className="text-danger">{errors.email.message}</div>
                )}
              </div>

              {/* Modal Footer */}
              <div className="modal-footer">
                <ButtonP
                  text={'Annuler'}
                  onClick={onClose}
                  className={'btn btn-danger py-2 px-4'}
                />
                <ButtonP text={'Enregistrer'} type={'submit'} />
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
