import { ApiMananger } from "actions/rtkquery";
import { GET_ECRITURESCOMPTABLES_API_ROUTE } from "routes/api/endpoints";

const ecritureApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({

    getEcriture: build.query({
        query: () => `${GET_ECRITURESCOMPTABLES_API_ROUTE}`,
        providesTags: ["INVALIDATE_ECRITURE"],
    }),
    getEcritureId: build.query({
        query: ({id}) => `${GET_ECRITURESCOMPTABLES_API_ROUTE}?id=${id}`,
        providesTags: ["INVALIDATE_ECRITURE"],
    }),
    getEcritureByDossierClientId: build.query({
        query: (dossier_id) => `${GET_ECRITURESCOMPTABLES_API_ROUTE}?dossier_client=${dossier_id}`,
        providesTags: ["INVALIDATE_ECRITURE"],
    }),
    getEcritureByLivreId: build.query({
        query: ({livre_journal}) => `${GET_ECRITURESCOMPTABLES_API_ROUTE}?livre_journal=${livre_journal}`,
        providesTags: ["INVALIDATE_ECRITURE"],
    }),
}),
});

export const {
useGetEcritureByDossierClientIdQuery,
useGetEcritureIdQuery,
useGetEcritureQuery,
useGetEcritureByLivreIdQuery
} = ecritureApi;
