const { createSlice } = require('@reduxjs/toolkit');

const useSliceWithTokenValidation = createSlice({
  name: 'auth',
  initialState: {
    accessToken: null,
    refreshToken: null,
    isAuthenticated: false,
    currentUser: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      state.refreshToken = action.payload.refresh;
      state.accessToken = action.payload.access;
      state.isAuthenticated = true; // Mettre à jour isAuthenticated à true lors de la connexion réussie
      state.currentUser = action.payload;
      console.log(action.access);
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
      console.log('setRefreshToken', state);
    },
    setAccessToken: (state, action) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload;
      console.log('setAccessToken', state.accessToken);
    },

    setSkeep(state) {
      state.isSkeep = true;
    },

    logout: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      state.currentUser = null;
    },
  },
});

export const getUserStatus = (state) => state?.auth?.isAuthenticated;
export const getUser = (state) => state?.auth?.currentUser;
export const getUsers = (state) => state?.auth;

export const getSkeep = (state) => state?.user?.isSkeep;

export const { setCredentials, setAccessToken, logout, setSkeep } =
  useSliceWithTokenValidation.actions;

export const loginSuccess = (state) => {
  state.isAuthenticated = true;
};
export default useSliceWithTokenValidation.reducer;
