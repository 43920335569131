import React from 'react';
import './button.css';

export default function ButtonP({ type, text, disabled, onClick, className }) {
  return (
    <div>
      <button
        type={type}
        className={`next-button ${className} rounded-pill`}
        disabled={disabled}
        onClick={onClick}
      >
        {text}
      </button>
    </div>
  );
}
