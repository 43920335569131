import React, { useState } from 'react';
import HeaderFirst from 'utils/HeaderFirst';
import CustomBootstrapTable from 'utils/reactTable';
import { useNavigate, useParams } from 'react-router-dom';
import './client.css';
import TablePaginators from './TablePaginator';
import { FiPaperclip } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/features/userSliceWithTokenValidation';
import { ADD_VENTE } from 'routes/navigation/navigationPaths';
import HeaderButton from 'utils/headerButton';
import useVentesData from './useVentesData';
import { toast } from 'react-toastify';

const VentePage = () => {
  //user connected
  const userStatus = useSelector(getUser);
  const navigate = useNavigate();
  const { clientID: dossier_id } = useParams();
  console.log(dossier_id);
  const { updatedVentes, handleUpdateVente } = useVentesData(dossier_id);
  const donnees = updatedVentes;
  console.log({ donnees });
  const [selectedRows, setSelectedRows] = useState([]);
  const [serachTerm, setSearchedTerm] = useState('');

  const handleRowSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows((prev) => [...prev, row.id]);
    } else {
      setSelectedRows((prev) => prev.filter((id) => id !== row.id));
    }
  };

  const handleSearchChange = (newSearchTerm) => {
    setSearchedTerm(newSearchTerm);
  };

  

  const columns = [
    {
      dataField: 'id',
      text: 'N°',
      formatter: (cell, row) => (
        <div className="styleColonne" onClick={() => handleRowSelect(row)}>
          {cell}
        </div>
      ),
    },
    {
      dataField: 'client_details',
      text: 'Nom du client',
      formatter: (cell, row) => (
        <div className="styleColonne" onClick={() => handleRowSelect(row)}>
          {cell.nom}
        </div>
      ),
    },
    {
      // dataField: 'username',
      text: 'Fait par',
      formatter: (cell, row) => (
        <div className="styleColonne" onClick={() => handleRowSelect(row)}>
          {userStatus?.first_name}
        </div>
      ),
    },
    {
      dataField: 'updated_at',
      text: 'Date',
      formatter: (cell, row) => {
        // Parse the date string into a Date object
        const date = new Date(cell);

        // Format the date and time parts
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        // Combine the parts into the desired format
        const formattedDate = `${day}/${month}/${year} à ${hours}:${minutes}`;

        // Render the formatted date string
        return <div onClick={() => handleRowSelect(row)}>{formattedDate}</div>;
      },
    },
    {
      dataField: 'archived',
      text: 'Statut',
      formatter: (cell, row) => {
        const statusText = row.archived ? (
          <span style={{ color: 'red' }}>Archivée</span>
        ) : (
          'Active'
        );
        return <div onClick={() => handleRowSelect(row)}>{statusText}</div>;
      },
    },
    {
      dataField: 'source_paiement',
      text: 'Paiements',
      formatter: (cell, row) => (
        <div className="styleColonne" onClick={() => handleRowSelect(row)}>
          {cell}
        </div>
      ),
    },
    {
      dataField: 'prix',
      text: 'Prix',
      formatter: (cell, row) => {
        const Price = row?.produits_associes?.reduce(
          (sum, prod) => sum + parseFloat(prod.prix || 0),
          0
        );
        return (
          <div onClick={() => handleRowSelect(row)}>{Price?.toFixed(2)}</div>
        );
      },
    },
    {
      dataField: 'piece_joint',
      text: <FiPaperclip />,
      formatter: (cell, row) => (
        <div className="styleColonne" onClick={() => handleRowSelect(row)}>
          {cell ? (
            <a href={cell} target="_blank" rel="noopener noreferrer">
              Pièce jointe
            </a>
          ) : (
            <span>pas pièce jointe</span>
          )}
        </div>
      ),
    },
    {
      dataField: 'montant_total',
      text: 'Total',
      formatter: (cell, row) => (
        <div className="styleColonne" onClick={() => handleRowSelect(row)}>
          {cell}
        </div>
      ),
    },
  ];

  const [pageSize, setPageSize] = useState(10);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [viewArchived, setViewArchived] = useState(false);

  const handlePageChange = (newIndex) => {
    setPaginationIndex(newIndex);
  };

  const currentPageData = donnees
    .filter((vente) => (viewArchived ? vente.archived : !vente.archived))
    // Show all if viewArchived is false, else filter by archived status
    .filter((vente) =>
      vente.client_details?.nom
        ?.toLowerCase()
        .includes(serachTerm.toLowerCase())
    )
    .slice(paginationIndex * pageSize, (paginationIndex + 1) * pageSize);

  // Define the action handler for TablePaginator
  const handleActionSelected = (action) => {
    if (action === 'archive') {
      setViewArchived(!viewArchived); // Toggle between viewing archived and active depenses
    } else {
      console.log(`Action selected: ${action}`);
      // Handle other actions as necessary
    }
  };

  const onClickArchive = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];

      // Optional: Check if the depense is archived before attempting to update
      const vente = currentPageData.find((d) => d.id === selectedRowId);

      const newArchivedStatus = !vente.archived;
      await handleUpdateVente(selectedRowId, { archived: newArchivedStatus });
      toast.info(
        `Vente ${newArchivedStatus ? 'archivée' : 'désarchivée'} avec succès.`
      );
      // Consider refetching updated data here to reflect changes
    } else {
      toast.warning('Veuillez sélectionner exactement une vente à archiver.');
    }
  };

  const DepensebuttonActions = {
    addExpense: () => {
      alert('Ajouter une action de dépense');
    },
  };

  const nom = (
    <div style={{ display: 'flex' }}>
      <h4 className="titre-dossier">Entrées</h4>
      <span className="nbrDossier">{currentPageData?.length}</span>
    </div>
  );
  const description = (
    <div>Voici toutes vos entrées {viewArchived && 'archivées'}</div>
  );

  const handleEyesClick = () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const detailVenteURL = `${selectedRowId}/`;
      navigate(detailVenteURL);
    }
  };

  const handleFormVente = () => {
    navigate(ADD_VENTE);
  };

  return (
    <div
      className="global"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <div
        className="header pb-3"
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          flexWrap: 'wrap',
          // padding: "13px",
        }}
      >
        <HeaderFirst nom={nom} description={description} />
        <button
          type="button"
          className="btn btn-outline-dark"
          style={{
            borderRadius: '31px',
            border: '1px solid #AEAEAE',
            padding: '8px',
          }}
          onClick={handleFormVente}
        >
          <span style={ajoutFou}>+ Ajouter une entrée</span>
        </button>
      </div>
      <HeaderButton
        isButtonsEnabled={selectedRows.length > 0}
        placeholder={'Rechercher une vente'}
        onSearchChange={handleSearchChange}
        onEyesClick={handleEyesClick}
        buttonActions={DepensebuttonActions}
        onClickArchive={onClickArchive}
        // onEditClick={onEditClick}
      />

      <div>
        {donnees ? (
          <CustomBootstrapTable
            keyField="id"
            data={currentPageData.filter((vente) =>
              vente.client_details?.nom
                .toLowerCase()
                .includes(serachTerm.toLowerCase())
            )}
            columns={columns}
            onSelectRow={handleRowSelect}
            // ... other props
          />
        ) : (
          <p>Chargement des données...</p>
        )}
      </div>
      {!currentPageData ||
        (currentPageData.length == 0 && (
          <div className="d-flex justify-content-center fs-5">
            <p>Aucune vente à afficher.</p>
          </div>
        ))}
      {/* pagination */}
      {currentPageData && (
        <TablePaginators
          paginationIndex={paginationIndex}
          setPaginationIndex={handlePageChange}
          dataLength={currentPageData?.length}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onActionSelected={handleActionSelected}
          isViewingArchived={viewArchived}
        />
      )}
    </div>
  );
};

const ajoutFou = {
  fontFamily: 'Montserrat',
  fontWeight: '600',
  fontSize: '13px',
};
export default VentePage;
