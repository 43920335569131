import React,{ useEffect, useState } from 'react'
import "../ConnexionForm/forme.css";
import { useNavigate, useSearchParams } from 'react-router-dom'
import allIcon from '../../image/allIcon.png'
import Loader from 'components/Loader/Loader';
import { useActivationUserMutation } from 'actions/auth/mutations';
// import { ACTIVATION_API_ROUTE } from 'routes/api/endpoints';
// import { LOGIN_PATH } from 'routes/navigation/navigationPaths'

export const Activation = () => {
  // const navigation =useNavigate();
  const [params] = useSearchParams();
  const [isRedirecting] = useState(false);

  const navigate = useNavigate();
  console.log(params.get('uid'));
  console.log(params.get('code_activation'));

  const [activateUser] = useActivationUserMutation();

  const handleActivation = async () => {
    const data = {
      uid: params.get('uid'),
      token: params.get('code_activation'),
    };

    try {
      const response = await activateUser(data).unwrap();
      console.log(response);
      navigate('/');
    } catch (error) {
      console.error('Erreur lors de la requête:', error);
    }
  };

  useEffect(() => {
    handleActivation();
  }, [activateUser]);

  useEffect(() => {
    if (isRedirecting) {
      const timer = setTimeout(() => {
        <Loader/>
        navigate("/"); // Remplacez par votre chemin réel
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isRedirecting, navigate]);


  return (
    <>
      <div className="row  auth-wrapper">
        <div className="col-12 col-md-6 col-lg-6 auth-main-col text-center ">
          <div className='mm'>
            <div style={{ borderRadius: '200px', height: '200px', width: '200px', background: '#F8FAF5', margin: '0 auto' }}>
              <i className="i checkmark">✓</i>
            </div>
            <h1 className='h1'>Success</h1>
            <p className='p'>Vérification de mail avec success<br /> redirection en cours...</p>

            <div>
            </div>

          </div>
        </div>
        <div className="col-12 col-md-5 col-lg-6  auth-background-col">
          <div className="auth-background-holder">
            <div className="allIconImage">
              <img src={allIcon} className="img" />
            </div>
            <div className="text-bas ">
              <h6>Easy compta</h6>
              <p>Easy Compta simplifie la gestion financière, vous permettant de suivre vos revenus et dépenses, générer des rapports détaillés et rester toujours en contrôle de votre comptabilité.</p>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  )
}

