// Modal.js
import React, {useEffect, useState} from "react";
import "./ecriture.css";
// import { useDispatch } from "react-redux";
import {toast} from "react-toastify";
//import ButtonP from 'components/buttons/ButtonP';
import { Col, Container, Form, Row} from "react-bootstrap";
import { useCreateEcritureComtableMutation, useDeleteEcritureComtableMutation, useUpdateEcritureComtableMutation } from "actions/sage_clone/ecritures/mutations";
import { useGetEcritureByLivreIdQuery } from "actions/sage_clone/ecritures/queries";
import Select from "react-select";
import Creatable from 'react-select/creatable';


const Ecriture = ({
  codes,
  codesTiers,
  codeJournalResponse,
  livre_journal,
  facture, 
  setFacture
}) => {
  //const { clientID: id } = useParams();

  const {data: ecritures,refetch} = useGetEcritureByLivreIdQuery({
    livre_journal: livre_journal,
  })
  console.log("Les ecritures: ", ecritures);

  const [addLivreJournal] = useCreateEcritureComtableMutation()
  const [updateLivreJournal] = useUpdateEcritureComtableMutation()
  const [deleteLivreJournal] = useDeleteEcritureComtableMutation()

  const options = codes.map((code) => {
    return {
      value: parseInt(code.code), label: code.combine, libelle: code.libelle
    }
  })

  const optionsTiers = codesTiers.map((code) => {
    return {
      value: parseInt(code.compte_comptable), label: code.compte_comptable + "-" + code.nom
    }
  })

  console.log("codes journaux: ",codeJournalResponse,"\nLes codes: ",codes)
  const [mesOptionsTiers,setOptionsTiers] = useState(optionsTiers)


  

  useEffect(() => {
    if(facture.compte_general != 0){
      const options = codesTiers.filter((code) => parseInt(code.compte_generale) == facture.compte_general)
                                .map((code) => {
                                  return {
                                    value: parseInt(code.compte_comptable), label: code.compte_comptable + "-" + code.nom
                                  }
                                })
      setOptionsTiers(options)
    }
  },[facture.compte_general,setOptionsTiers])

  // Handle input changes
  const handleInputChange = (event) => {
    const {name, value, type, checked} = event.target;

    setFacture((prevState) => {
      if (type === "checkbox") {
        return {...prevState, [name]: checked};
      }
      else if (name == "date" ) {
        const strValue = value ? value : new Date();
        return {...prevState, [name]: strValue};
      } else if (name == "libelle") {
        const strValue = value ? value : "";
        return {...prevState, [name]: strValue};
      } else {
        return {...prevState, [name]: parseInt(value, 10)};
      }
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Verify all necessary fields are populated.
    const requiredFields = ["libelle"];
    const areFieldsFilled = requiredFields.every(
      (field) => facture[field] && facture[field].toString().trim() !== ""
    );

    if (!areFieldsFilled ) {
      toast.error("Tous les champs sont obligatoires.");
      return;
    }

    // Check for the presence of a file to be uploaded.

      const formData = new FormData();

      // Append scalar fields directly
      formData.append("compte_general", facture.compte_general);
      formData.append("compte_tiers", facture.compte_tiers);
      formData.append("libelle", facture.libelle);
      formData.append("debit", facture.debit);
      formData.append("credit", facture.credit);
      formData.append("date", facture.date);
      formData.append("livre_journal", facture.livre_journal);
      // Perform the POST request
      try {
        await addLivreJournal({
            livre_journal: facture.livre_journal, 
            libelle: facture.libelle,
            compte_general: facture.compte_general,
            compte_tiers: facture.compte_tiers,
            debit: facture.debit,
            date: facture.date,
            credit: facture.credit,
          formData: formData
        }).unwrap();
        toast.success("Ecriture ajoutée avec succès");
        refetch()
      } catch (error) {
        console.error("Requête échouée:", error);
        toast.error(
          `Erreur: ${error.data?.message}` || "Echec d'ajout de dépense"
        );
      }
    
  };

 
  const colourStyles = {
    option: (styles, { data}) => {
      return {
        ...styles,
        fontFamily: data.label,
        fontSize: "12px"
      };
    },

    singleValue: (styles) => ({
      ...styles,
      fontSize: "12px"
    })
  };

  const handleModify = async () => {
    try {
      await updateLivreJournal({
        id: facture.id,
        ...facture,
      }).unwrap();
      toast.success("Ecriture modifiée avec succès");
      refetch(); // Refetch client data from the backend
    } catch (error) {
      toast.error(`Erreur de mise à jour: ${error.data?.error || error.message}`);
      console.error('La requête a échoué:', error);
    }
  }

  const handleSuppress = async () => {
    try {
      console.log("ID à supprimer: ",facture.id)
      await deleteLivreJournal({id: facture.id})
      toast.success('Ecriture supprimée avec succès');
      refetch();
    } catch (error) {
      toast.error(`Erreur de suppression`);
    }
  }

  return (
    <div >
        <div className="container-background w-100 w-md-75 px-0 ">
            <Container fluid className="container-style p-4 mb-5">
            <Form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="d-flex flex-column align-items-center gap-5">
                <div className="w-100 d-flex flex-column gap-2">
                    <Row className="row-gap-2">
                        <Col sm={2}>
                            <Form.Group controlId="formFournisseur">
                            <Form.Label className="mb-2">Date*</Form.Label>
                            <Form.Control
                                type="date"
                                name="date"
                                value={facture.date}
                                onChange={handleInputChange}
                                className="rounded rounded-pill"
                            />

                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group controlId="formFournisseur">
                            <Form.Label className="mb-2">Compte Général*</Form.Label>
                            <Select 
                              options={options} 
                              onChange={(e) => setFacture((prevState) => ({
                                ...prevState,
                                compte_general: e.value,
                                libelle: e.libelle
                              }))}
                              //styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                              styles={colourStyles}
                            />

                            </Form.Group>
                        </Col>

                        <Col sm={2}>
                            <Form.Group controlId="formIntitule">
                            <Form.Label className="mb-2">Compte Tiers</Form.Label>
                            <Creatable 
                              options={mesOptionsTiers} 
                              onChange={(e) => setFacture((prevState) => ({
                                ...prevState,
                                compte_tiers: e.value,
                              }))}
                              styles={colourStyles}
                            />
                            </Form.Group>
                        </Col>

                        <Col sm={2}>
                            <Form.Group controlId="formIntitule">
                            <Form.Label className="mb-2">Libellé*</Form.Label>
                            <Form.Control
                                type="text"
                                name="libelle"
                                value={facture.libelle}
                                onChange={handleInputChange}
                                className="rounded rounded-pill"
                                style={{fontSize:"11px", padding:"10px 5px"}}
                            />
                            </Form.Group>
                        </Col>

                        <Col sm={2}>
                            <Form.Group controlId="formIntitule">
                            <Form.Label className="mb-2">Débit</Form.Label>
                            <Form.Control
                                type="number"
                                name="debit"
                                value={facture.debit}
                                onChange={handleInputChange}
                                className="rounded rounded-pill"
                            />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group controlId="formIntitule">
                            <Form.Label className="mb-2">Crédit</Form.Label>
                            <Form.Control
                                type="number"
                                name="credit"
                                value={facture.credit}
                                onChange={handleInputChange}
                                className="rounded rounded-pill"
                            />
                            </Form.Group>
                        </Col>
                      </Row>
                    
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "15px",
                        marginTop: "15px"
                      }}>

                            <button
                              type="submit"
                              className="btn btn-primary rounded rounded-pill px-4"
                              style={{
                                  backgroundColor: "#068f7a",
                                  borderColor: "#068f7a",
                              }}
                              disabled={
                                  !facture.libelle || !facture.date 
                              } // Add other fields as necessary
                            >
                              Ajouter
                            </button>
                
                            
                        </div>
                     
                    {/* ... Other input fields ... */}

                </div>
                </div>
                {/* ... Other input fields ... */}
            </Form>
            <div 
              style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: "15px",
                      marginTop: "15px"
                    }}>
              <button
                onClick={() => handleModify()}
                className="btn btn-primary rounded rounded-pill px-4"
                style={{
                    backgroundColor: "#ffbf00",
                    borderColor: "#daa520",
                }}
                disabled={
                    !facture.libelle || !facture.date 
                } // Add other fields as necessary
              >
                Modifier
              </button>

              <button
                onClick={() => handleSuppress()}
                className="btn btn-primary rounded rounded-pill px-4"
                style={{
                    backgroundColor: "#c21807",
                    borderColor: "#7c0a02",
                }}
                disabled={
                    !facture.libelle || !facture.date 
                } // Add other fields as necessary
              >
                Suppression
              </button>
            </div>
            </Container>
        </div>
    </div>
  );
};


export default Ecriture;
