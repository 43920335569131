import React, {useRef, useState} from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { usePDF } from 'react-to-pdf';

import Table from "react-bootstrap/Table";
import {useEffect} from "react";
import "./style/style.css";
import { useGetJournalIdQuery } from 'actions/sage_clone/journaux/queries';

const LivreJournal = ({id_journal,ecritures,setFacture}) => {

  //const [isActive, setIsActive] = useState("false")
  const [currentIndex,setCurrentIndex] = useState(0)


  const {data: livrejournal} = useGetJournalIdQuery({
    id: id_journal,
  })
  // pour excel
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'LivreJournal table',
    sheet: 'LivreJournal'
  })  
  

  // pour pdf
  const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});

  const myRef = useRef(null);


  useEffect(function() {
    tableRef.current = myRef.current;
    targetRef.current = myRef.current;
  }, [myRef.current]);

  const handleChangeEcriture = (ecriture,index) => {
    setFacture({
      compte_general: ecriture["compte_general"],
      compte_tiers: ecriture["compte_tiers"],
      libelle: ecriture["libelle"],
      date: ecriture["date"],
      debit: ecriture["debit"],
      credit: ecriture["credit"],
      id: ecriture["id"]
    })
    setCurrentIndex(index)
  }

  return (
    <div style={{marginLeft: "20px"}}>
      <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
        <h1
          style={{
            fontSize: "25px",
            fontWeight: "bold",
            marginLeft: "0px",
            padding: "10px 0px",
          }}>
          Livre Journal
        </h1>
        <div style={{display:"flex",alignItems:"center",gap:"10px"}} >
          <button onClick={onDownload}> Export excel </button>
          <button onClick={() => toPDF()}>Download PDF</button>
        </div>

      </div>


      <Table bordered hover ref={myRef}>
        <thead>
          <tr>
            <th >Date</th>
            <th>Compte_general</th>
            <th>Compte tiers</th>
            <th>Libellé</th>
            <th>Débit</th>
            <th>Crédit</th>
          </tr>
        </thead>
        <tbody>
          {
            ecritures &&
            ecritures.data?.filter((ecriture) => ecriture["archived"] === false).map((ecriture, index) => {
                return(
                  <tr key={index}  onClick={() => handleChangeEcriture(ecriture,index)} className={`${index === currentIndex? 'table-active': ''}`} >
                    <td>{ecriture["date"]}</td>
                    <td>{ecriture["compte_general"]}</td>
                    <td>{ecriture["compte_tiers"]}</td>
                    <td>{ecriture["libelle"]}</td>
                    <td>{ecriture["debit"]}</td>
                    <td>{ecriture["credit"]}</td>
                  </tr>
                )
              })
            }

          {ecritures&& (
              <tr>
                <td>-</td>
                <td>-</td>
              <td>-</td>
              <td className='data'>TOTAL</td>
              <td className='data'>{livrejournal.data[0]["total_debits"]}</td>
              <td className='data'>{livrejournal.data[0]["total_credits"]}</td>             
          </tr>
          )}
        
      </tbody>
      </Table>
    </div>
  );
};

export default LivreJournal;
