import { ApiMananger } from "actions/rtkquery";
import { GET_PRODUITS_API_ROUTE } from "routes/api/endpoints";

const produitApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({

    getProduits: build.query({
        query: ({ dossier_id }) => `${GET_PRODUITS_API_ROUTE}?dossier_id=${dossier_id}`,
        providesTags: ["INVALIDATE_PRODUITS"],
    }),
    getProduitsId: build.query({
        query: ({ dossier_id, id }) => `${GET_PRODUITS_API_ROUTE}?dossier_id=${dossier_id}&id=${id}`,
        providesTags: ["INVALIDATE_PRODUITS"],
    }),
}),
});

export const {
useGetProduitsQuery,
useGetProduitsIdQuery,
} = produitApi;
