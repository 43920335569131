import React, {useEffect,useRef} from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { usePDF } from 'react-to-pdf';

import Table from "react-bootstrap/Table";
import "./style/style.css";
import {useGetGrandLivre_CabinetQuery} from "actions/cabinet/queries";

const GrandLivre = ({dossier_id}) => {
  const {data: grandlivre, refetch} = useGetGrandLivre_CabinetQuery({
    dossier_id: dossier_id,
  });

    // pour excel
    const tableRef = useRef(null);
    const { onDownload } = useDownloadExcel({
      currentTableRef: tableRef.current,
      filename: 'GrandLivre table',
      sheet: 'GrandLivre'
    })  
  
    // pour pdf
    const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});
  
    const myRef = useRef(null);
  
  
    useEffect(function() {
      tableRef.current = myRef.current;
      targetRef.current = myRef.current;
    }, [myRef.current]);

    useEffect(() => {
      refetch();
    }, [grandlivre]);


  console.log(grandlivre);
  return (
    <div style={{marginLeft: "20px"}}>
      <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
        <h1
          style={{
            fontSize: "25px",
            fontWeight: "bold",
            marginLeft: "0px",
            padding: "10px 0px",
          }}>
          Grand Livre
        </h1>
        <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
          <button onClick={onDownload}> Export excel </button>
          <button onClick={() => toPDF()}>Download PDF</button>
        </div>

      </div>
      <Table striped bordered hover ref={myRef}>
        <thead>
          <tr>
            <th>Numéro Compte</th>
            <th>Libellé</th>
            <th>Crédit</th>
            <th>Débit</th>
          </tr>
        </thead>
        <tbody>
        {
          grandlivre &&
          grandlivre["grand-livre"]?.map((ecriture, index) => {
              return(
                <tr key={index}>
                  <td>{ecriture["numero_comptable"]}</td>
                  <td>{ecriture["libelle"]}</td>
                  <td>{ecriture["debit"]}</td>
                  <td>{ecriture["credit"]}</td>
                </tr>
              )
            })
          }
          
          {grandlivre && (
            <tr>
              <td>-</td>
              <td className='data'>TOTAL</td>
              <td className='data'>{grandlivre["total_debits"]}</td>
              <td className='data'>{grandlivre["total_credits"]}</td>             
          </tr>
          )}
              
      </tbody>
      </Table>
    </div>
  );
};

export default GrandLivre;
