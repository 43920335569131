import { ApiMananger } from "actions/rtkquery";
import { GET_CLIENT_API_ROUTE } from "routes/api/endpoints";

const clientApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({

    getClient: build.query({
        query: ({ dossier_id }) => `${GET_CLIENT_API_ROUTE}?dossier_id=${dossier_id}`,
        providesTags: ["INVALIDATE_CLIENT"],
    }),
    getClientId: build.query({
        query: ({ dossier_id, id }) => `${GET_CLIENT_API_ROUTE}?dossier_id=${dossier_id}&id=${id}`,
        providesTags: ["INVALIDATE_CLIENT"],
    }),
}),
});

export const {
useGetClientQuery,
useGetClientIdQuery,
} = clientApi;
