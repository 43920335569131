import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './client.css';
import ConfirmModal from 'utils/ConfirmationModal';
import { toast } from 'react-toastify';
import TransactionHeader from './transactionPageComp/TransactionHeader';
import TransactionDetails from './transactionPageComp/TransactionDetails';
import TransactionList from './transactionPageComp/TransactionList';
import { useDeleteTransactionMutation } from 'actions/transactions/mutations';
import { useGetTransactionQuery } from 'actions/dossiers/queries';
import useVentesData from 'pagesDossierClient/ventes/useVentesData';
import useDepensesData from 'pagesDossierClient/depenses/useDepensesData';

const TransactionPage = () => {
  const navigate = useNavigate();
  const { clientID: dossier_id } = useParams();
  const { data: getAllTransactions, refetch } = useGetTransactionQuery({
    dossier_id,
  });
  const [deleteTransaction] = useDeleteTransactionMutation();
  // States
  const [combinedData, setCombinedData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [currentTab, setCurrentTab] = useState('all');
  const [selectedRows, setSelectedRows] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [transactionToDelete, setTransactionToDelete] = useState(null);
  const [selectedPaymentSource, setSelectedPaymentSource] = useState(null);
  const ventesData = useVentesData(dossier_id);
  const depensesData = useDepensesData(dossier_id);
  const [viewArchived, setViewArchived] = useState(false);

  const handleActionSelected = (action) => {
    if (action === 'archive') {
      setViewArchived(!viewArchived);
    } else {
      console.log(`Action selected: ${action}`);
    }
  };

  // Handlers
  const handlePaymentSourceClick = (source) => {
    setSelectedPaymentSource(source);
    setPaginationIndex(0); // Reset pagination when filter changes
  };

  const onEyeClick = (rowData) => {
    const parts = rowData.split('-'); // Split the string by '-'
    const id = parts[0]; // The first part is the id
    const key = parts.slice(1).join('-');
    console.log(rowData);
    const transactionData = getAllTransactions?.transactions[key];
    const type = transactionData?.type;
    console.log(transactionData?.type);
    navigate(`${type}/${id}/`);
  };

  const onDeleteClick = (transaction) => {
    setTransactionToDelete(transaction);
    setIsConfirmModalOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    if (transactionToDelete) {
      const parts = transactionToDelete.split('-');
      const id = parts[0];
      const key = parts.slice(1).join('-');
      const transactionData = getAllTransactions?.transactions[key];
      const type = transactionData?.type;

      try {
        await deleteTransaction({ id: id, type: type }).unwrap();
        toast.success('Transaction supprimée avec succès');
        setIsConfirmModalOpen(false); // Close the modal upon success
        refetch();
      } catch (error) {
        toast.error(
          `Échec de la suppression de la transaction : ${error.message}`
        );
        setIsConfirmModalOpen(false); // Close the modal upon failure
        console.error('Requête échouée:', error);
      }
    }
  };

  const handlePageChange = (newIndex) => {
    setPaginationIndex(newIndex);
  };

  const handleRowSelect = (row, isSelect) => {
    const uniqueIdentifier = row.key;

    if (isSelect) {
      const existingRow = selectedRows.find(
        (selectedRow) => selectedRow.id === row.id
      );

      if (existingRow) {
        // If an existing row with the same id is found, use the unique key to differentiate
        setSelectedRows((prev) => [...prev, uniqueIdentifier]);
      } else {
        // If no existing row with the same id is found, it's safe to use the id directly
        setSelectedRows((prev) => [...prev, row.id]);
      }
    } else {
      // For deselection, remove the row by either its id or unique key
      setSelectedRows((prev) =>
        prev.filter(
          (selectedRow) =>
            selectedRow !== row.id && selectedRow !== uniqueIdentifier
        )
      );
    }
  };

  // Form handlers
  const handleFormDepense = () => {
    let currentPath = window.location.pathname;
    currentPath = currentPath.replace(/\/+$/, '');
    const pathSegments = currentPath.split('/');
    pathSegments.pop(); // This should now correctly remove the last word
    console.log(pathSegments);
    const newPath = [...pathSegments, `depenses`, `add-depense/`].join('/');
    navigate(newPath);
  };

  const handleFormVente = () => {
    let currentPath = window.location.pathname;
    currentPath = currentPath.replace(/\/+$/, '');
    const pathSegments = currentPath.split('/');
    pathSegments.pop();
    const newPath = [...pathSegments, `ventes`, `add-vente/`].join('/');
    navigate(newPath);
  };

  // Data fetching and transformation
  useEffect(() => {
    // First, filter transactions by the selected payment source, if any.
    let filteredData = getAllTransactions?.transactions
      ? getAllTransactions.transactions.filter((item) => {
          // Check for the selected payment source
          const matchesPaymentSource = selectedPaymentSource
            ? item.source_paiement === selectedPaymentSource
            : true;

          // Check for the archival status
          const matchesArchivalStatus = viewArchived
            ? item.archived
            : !item.archived;

          // Include the item only if it matches both conditions
          return matchesPaymentSource && matchesArchivalStatus;
        })
      : [];

      

    // Next, apply additional filtering and mapping based on the current tab.
    if (currentTab === 'all') {
      filteredData = filteredData.map((item, index) => ({
        ...item,
        id: `${item.id}-${index}`,
      }));
    } else if (currentTab === 'out') {
      filteredData = filteredData
        .filter((item) => item.type === 'sortie')
        .map((item, index) => ({
          ...item,
          id: `sortie-${item.id}-${index}`,
        }));
    } else if (currentTab === 'in') {
      filteredData = filteredData
        .filter((item) => item.type === 'entree')
        .map((item, index) => ({
          ...item,
          id: `entree-${item.id}-${index}`,
        }));
    }

    // Apply pagination to the now filtered and mapped data.
    const startIndex = paginationIndex * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedData = filteredData.slice(startIndex, endIndex);

    // Update the state with the paginated data.
    setCombinedData(paginatedData);
    refetch()

  }, [
    getAllTransactions,
    currentTab,
    selectedPaymentSource,
    paginationIndex,
    pageSize,
    viewArchived,
  ]);

  // Styles (example)
  const ajoutFou = {
    fontFamily: 'Montserrat',
    fontWeight: '600',
    fontSize: '13px',
  };

  return (
    <div
      className="transaction-page"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onRequestClose={() => setIsConfirmModalOpen(false)}
        onConfirm={handleDeleteConfirmation}
        title="Confirmer la suppression"
        message="Êtes-vous sûr de vouloir supprimer cette transaction ? Cette action est irréversible."
      />
      <TransactionHeader
        transactionCount={combinedData?.length}
        viewArchived={viewArchived}
      />
      <hr />
      <TransactionDetails
        soldeTotal={getAllTransactions?.solde_total || 0}
        nbrTransactions={getAllTransactions?.transactions.length}
        paymentDetails={getAllTransactions?.transactions_par_compte}
        nbrEntree={ventesData?.updatedVentes?.length || 0}
        nbrSortie={depensesData?.updatedDonnees?.length || 0}
        onCardClick={handlePaymentSourceClick}
      />
      <TransactionList
        combinedData={combinedData || []}
        paginationIndex={paginationIndex}
        setPaginationIndex={handlePageChange}
        dataLength={getAllTransactions?.transactions.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        selectedRows={selectedRows}
        handleRowSelect={handleRowSelect}
        onEyeClick={onEyeClick}
        onDeleteClick={onDeleteClick}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        handleFormDepense={handleFormDepense}
        handleFormVente={handleFormVente}
        ajoutFou={ajoutFou}
        viewArchived={viewArchived}
        onActionSelected={handleActionSelected}
      />
    </div>
  );
};

export default TransactionPage;
