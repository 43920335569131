import { ApiMananger } from 'actions/rtkquery';
import { GET_DEPENSES_API_ROUTE } from 'routes/api/endpoints';

const depenseApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getDepenses: build.query({
      query: ({ dossier_id }) =>
        `${GET_DEPENSES_API_ROUTE}?dossier_id=${dossier_id}`,
      providesTags: ['DEPENSES'],
    }),
    getDepenseById: build.query({
      query: ({ dossier_id, id }) =>
        `${GET_DEPENSES_API_ROUTE}?dossier_id=${dossier_id}&id=${id}`,
      providesTags: ['DEPENSES'],
    }),
  }),
});

export const { useGetDepensesQuery, useGetDepenseByIdQuery } = depenseApi;
