import { ApiMananger } from 'actions/rtkquery';
import { GET_FOURNISSEUR_API_ROUTE } from 'routes/api/endpoints';

const fournisseurApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getFournisseur: build.query({
      query: ({ dossier_id }) =>
        `${GET_FOURNISSEUR_API_ROUTE}?dossier_id=${dossier_id}`,
      providesTags: ['INVALIDATE_FOURNISSEURS', 'INVALIDATE_DOSSIER'],
    }),
    getFournisseurId: build.query({
      query: ({ dossier_id, id }) =>
        `${GET_FOURNISSEUR_API_ROUTE}?dossier_id=${dossier_id}&id=${id}`,
      providesTags: ['INVALIDATE_FOURNISSEURS'],
    }),
  }),
});

export const { useGetFournisseurQuery, useGetFournisseurIdQuery } =
  fournisseurApi;
