import React from 'react';
import CardForm from './CardForm';
import { CiMoneyBill } from 'react-icons/ci';
import { HiOutlineArrowPath } from 'react-icons/hi2';
import { TbArrowBarDown } from 'react-icons/tb';
import { TbArrowBarUp } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

export default function CardDetails({
  nbrTransactions,
  nbrEntree,
  nbrSortie,
  solde_total,
}) {
  const navigate = useNavigate();
  const ToEntry = () => {
    let currentPath = window.location.pathname;
    currentPath = currentPath.replace(/\/+$/, '');
    const pathSegments = currentPath.split('/');
    pathSegments.pop();
    const newPath = [...pathSegments, `ventes/`].join('/');
    navigate(newPath);
  };
  const ToSorties = () => {
    let currentPath = window.location.pathname;
    currentPath = currentPath.replace(/\/+$/, '');
    const pathSegments = currentPath.split('/');
    pathSegments.pop();
    const newPath = [...pathSegments, `depenses/`].join('/');
    navigate(newPath);
  };
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap gap-2">
        <CardForm
          somme={solde_total}
          explain={'Solde Total disponible'}
          icon={<CiMoneyBill />}
          color={'bg-success-subtle'}
          colorIcon={'text-success'}
          monnaie={" FCFA"}
        />
        <CardForm
          somme={nbrEntree}
          explain={'Nombre Totale des entrées'}
          icon={<TbArrowBarDown />}
          color={'bg-info-subtle'}
          colorIcon={'text-info'}
          onClick={ToEntry}
          monnaie={""}
        />
        <CardForm
          somme={nbrSortie}
          explain={'Nombre Totale des sorties'}
          icon={<TbArrowBarUp />}
          color={'bg-danger-subtle'}
          colorIcon={'text-danger'}
          onClick={ToSorties}
          monnaie={""}
        />
        <CardForm
          somme={nbrTransactions}
          explain={'Nombre de transactions'}
          icon={<HiOutlineArrowPath />}
          color={'bg-warning-subtle'}
          colorIcon={'text-warning'}
          monnaie={""}
        />
      </div>
    </div>
  );
}
