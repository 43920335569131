import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderFirst from 'utils/HeaderFirst';
import InputDetail from 'components/Inputs/InputDetail';
import { toast } from 'react-toastify';
import ButtonP from 'components/buttons/ButtonP';
import { useUpdateClientMutation } from 'actions/clients/mutations';
import { useGetClientIdQuery } from 'actions/clients/queries';
import { useGetTransactionsQuery } from 'actions/transactions/queries';
import { useGetProduitsQuery } from 'actions/produits/queries';
import TransactionDetails from './TransactionDetails';

function SalarieDetails() {
  const { clientID, id: client } = useParams();
  const navigate = useNavigate();
  console.log(client);

  const {
    data: clientData,
    isFetching,
    error,
  } = useGetClientIdQuery({ dossier_id: clientID, id: client });
  const [updateClient] = useUpdateClientMutation();

  const [clientDetails, setClientDetails] = useState({
    nom: '',
    adresse: '',
    email: '',
    telephone: '',
  });

  useEffect(() => {
    if (clientData) {
      setClientDetails(clientData?.data[0]);
    }
  }, [clientData,setClientDetails]);

  const {
    data: produitsData, // Fetch product details
  } = useGetProduitsQuery({ dossier_id: clientID });

  const {
    data: transactions,
    isFetching: transactionsFetching,
    error: transactionsError,
  } = useGetTransactionsQuery({
    dossier_id: clientID,
    id: client,
  });

  if (transactionsFetching) return <div>Chargement...</div>;
  if (transactionsError) return <div>Une erreur est survenue.</div>;

  const saveChanges = async () => {
    try {
      await updateClient({
        id: client,
        ...clientDetails,
      }).unwrap();
      toast.success('Salarié mis à jour avec succès.');
      navigate(-1);
    } catch (error) {
      toast.error(
        "Une erreur s'est produite lors de la mise à jour du salarié."
      );
    }
  };

  if (isFetching) return <div>Chargement...</div>;
  if (error) return <div>Une erreur s&#39;est produite.</div>;

  return (
    <div
      className="global client-detail-container"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <HeaderFirst
        nom={
          <h4 className="titre-dossier">
            Détails du client : {clientDetails.nom || 'Inconnu'}
          </h4>
        }
        description={
          <div>
            Vous pouvez ici visualiser ou modifier les détails du salarié.
          </div>
        }
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          saveChanges();
        }}
        className="d-flex flex-column gap-2"
        style={{ padding: '15px' }}
      >
        <div className="row">
          <div className="col-md-6">
            <InputDetail
              label="Nom"
              name="nom"
              value={clientDetails.nom}
              onChange={(e) =>
                setClientDetails({
                  ...clientDetails,
                  nom: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
          <div className="col-md-6">
            <InputDetail
              label="Adresse"
              name="adresse"
              value={clientDetails.adresse}
              onChange={(e) =>
                setClientDetails({
                  ...clientDetails,
                  adresse: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <InputDetail
              label="Email"
              name="email"
              value={clientDetails.email}
              onChange={(e) =>
                setClientDetails({
                  ...clientDetails,
                  email: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
          <div className="col-md-6">
            <InputDetail
              label="Téléphone"
              name="telephone"
              value={clientDetails.telephone}
              onChange={(e) =>
                setClientDetails({
                  ...clientDetails,
                  telephone: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
        </div>
        <div className="my-3">
          <ButtonP type="submit" text="Sauvegarder" />
        </div>
      </form>
      <TransactionDetails
        transactions={transactions}
        clientId={client}
        produitsData={produitsData}
      />
    </div>
  );
}

export default SalarieDetails;
