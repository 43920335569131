// ProductLine.js
import React, { useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import { BiTrash } from 'react-icons/bi';

function ProductLine({
  product,
  index,
  handleProductChange,
  removeProduct,
  totalProducts,
  codes
}) {

  useEffect(() => {
    var total = 0
    total = (product.prix_unitaire_ht * product.quantite)
    product.prix_total_ttc = total
  },[product.prix_unitaire_ht, product.quantite, product.taxe])

  //console.log("Les produits: ",productOptions)
  return (
    <div className="d-flex row mb-2">
      <Col className="col-6">
        <div className="d-flex align-items-end gap-3">
          <Col className='col-6'>
            <Form.Group controlId={`product-${index}`}>
              <Form.Label className="mb-2">Produit</Form.Label>
              <Form.Control
                  type="text"
                  name="description"
                  value={product.description || ''}
                  onChange={(e) => handleProductChange(index, e)}
                  className="rounded rounded-pill"
              />
            </Form.Group>         
          </Col>
          <Col className='col-6'>
            <Form.Group controlId={`code-${index}`}>
              <Form.Label className='mb-2'>Code </Form.Label>
              <Form.Control
                as="select"
                name="code"
                value={product.code}
                onChange={(e) => handleProductChange(index, e)}
                className='rounded rounded-pill'
              >
                <option value="">- Choisissez -</option>
                {codes.map((code_comptable,i) => {
                  return (
                    <option value={code_comptable.code} key={i}>{code_comptable.combine}</option>
                  )
                })}

              </Form.Control>
            </Form.Group>          
          </Col>

        </div>
      </Col>
      <Col className="col-6">
        <div className="d-flex align-items-end gap-3">
          <Form.Group controlId={`quantity-${index}`} className="w-50">
            <Form.Label className="mb-2">Quantité</Form.Label>
            <Form.Control
            type="number"
            name="quantite"
            value={product.quantite || 1}
            onChange={(e) => handleProductChange(index, e)}
            className="rounded rounded-pill"
            />
          </Form.Group>
          <Form.Group controlId={`prix-unitaire-${index}`} className="w-50">
            <Form.Label className="mb-2">Prix Unitaire</Form.Label>
            <Form.Control
                type="number"
                name="prix_unitaire_ht"
                value={product.prix_unitaire_ht || 0}
                onChange={(e) => handleProductChange(index, e)}
                className="rounded rounded-pill"
            />
          </Form.Group>
          <Form.Group controlId={`taxe-${index}`} className="w-50">
            <Form.Label className="mb-2">Taxe</Form.Label>
            {/*<Form.Control
              type="number"
              name="taxe"
              value={product.taxe || 0}
              onChange={(e) => handleProductChange(index, e)}
              className="rounded rounded-pill"
            />*/}
            <Form.Control
              as="select"
              name="taxe"
              value={product.taxe || 0}
              onChange={(e) => handleProductChange(index, e)}
              className="rounded rounded-pill">
              <option value={0}>0%</option>
              <option value={10}>10%</option>
              <option value={18}>18%</option>
            </Form.Control>
          </Form.Group>

          {/*<Form.Group controlId={`prix_total_ttc-${index}`} className="w-50 h">
            <Form.Label className="mb-2">Prix TTC</Form.Label>
            <Form.Control
                type="number"
                name="prix_total_ttc"
                value={product.prix_total_ttc || 0}
                onChange={(e) => handleProductChange(index, e)}
                className="rounded rounded-pill"
            />
          </Form.Group>*/}

          {totalProducts > 1 && (
            <BiTrash
              className="fs-2 text-danger"
              style={{ cursor: 'pointer' }}
              onClick={() => removeProduct(index)}
            />
          )}
        </div>
      </Col>
    </div>
  );
}

export default ProductLine;
