import InscriptionProvider from 'components/InscriptionForm/InscriptionProvider'
import InscriptionForm from 'components/InscriptionForm/inscriptionForm'
import React from 'react'

const InscriptionPage = () => {
  return (
    <InscriptionProvider defaultValues={null}>
      <InscriptionForm />
    </InscriptionProvider>
  )
}

export default InscriptionPage