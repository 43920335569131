import { useEffect, useState } from 'react';
import { 
  useUpdateCodeJournalMutation,
  useDeleteCodeJournalMutation 
} from 'actions/sage_clone/code_journaux/mutations';
import { toast } from 'react-toastify';
import { useGetCodeJournalQuery } from 'actions/sage_clone/code_journaux/queries';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/features/userSliceWithTokenValidation'; // Adjust according to your project structure
//import useGetCurrentUser from 'hooks/useGetCurrentUser';


const useCodeJournalData = () => {
  const userStatus = useSelector(getUser);
  //const currentUser = useGetCurrentUser()
  //console.log("Status: ",userStatus.id,"\n User: ",currentUser)

  const {
    data: codeJournalResponse,
    isLoading: isLoadingcodeJournals,
    refetch,
  } = useGetCodeJournalQuery({comptable: userStatus?.id});

  const [updateCodeJournal] = useUpdateCodeJournalMutation();
  const [deleteCodeJournal] = useDeleteCodeJournalMutation();

  const [updatedcodeJournals, setUpdatedcodeJournals] = useState([]);

  useEffect(() => {
    if (
      !isLoadingcodeJournals &&
      codeJournalResponse?.data &&
      Array.isArray(codeJournalResponse.data)
    ) {
      refetch()
      const enhancedcodeJournals = codeJournalResponse.data.map(
        (codeJournal) => ({
          ...codeJournal,
          displayName: `${codeJournal?.nom} (${codeJournal?.id})`,
        })
      );
      setUpdatedcodeJournals(enhancedcodeJournals);
    }
  }, [isLoadingcodeJournals, codeJournalResponse]);

  const handleUpdateCodeJournal = async (codeJournalId, updateData) => {
    try {
      await updateCodeJournal({
        id: codeJournalId,
        ...updateData,
      }).unwrap();
      refetch(); // Refetch codeJournal data from the backend
    } catch (error) {
      toast.error(`Update error: ${error.data?.error || error.message}`);
      console.error('Request failed:', error);
    }
  };

  const handleDeleteCodeJournal = async (codeJournalId) => {
    try {
      await deleteCodeJournal({ id: codeJournalId }).unwrap();
      toast.success('code Journal supprimé avec succès');
      //console.log(codeJournalId)
      refetch();
    } catch (error) {
      toast.error(`Deletion error: ${error.data?.error || error.message}`);
    }
  };

  return {
    updatedcodeJournals,
    handleUpdateCodeJournal,
    handleDeleteCodeJournal,
    refetch,
  };
};

export default useCodeJournalData;
