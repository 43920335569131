import React, {useState} from "react";
import ButtonP from "components/buttons/ButtonP";
import ButtonWhite from "components/buttons/ButtonWhite";
import {BiBriefcase} from "react-icons/bi";

const AddPermissionModal = ({isOpen, onClose, onAddPermission}) => {
  const [newPermissionKey, setNewPermissionKey] = useState("");
  const [newPermissionDescription, setNewPermissionDescription] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPermissionKey && newPermissionDescription) {
      onAddPermission(newPermissionKey, newPermissionDescription);
      setNewPermissionKey("");
      setNewPermissionDescription("");
      onClose();
    }
  };

  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div
            className="modal-content bg-white"
            onClick={(e) => e.stopPropagation()}
            style={{borderRadius: "8px"}}>
            <div className="header d-flex flex-column gap-4">
              <div className="header-icon">
                <BiBriefcase style={{color: "#009688"}} />
              </div>
              <h2 className="modal-header">Ajouter une nouvelle permission</h2>
            </div>
            <form
              className="assign-role-form"
              autoComplete="off"
              onSubmit={handleSubmit}>
              <div className="form-group">
                <label
                  htmlFor="permission"
                  className="form-label d-inline"
                  style={{
                    color: "#667085",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}>
                  Clé de la nouvelle permission
                </label>
                <input
                  type="text"
                  id="permission"
                  className="form-control"
                  value={newPermissionKey}
                  onChange={(e) => setNewPermissionKey(e.target.value)}
                  style={{
                    fontSize: "16px",
                    color: "#068F7A",
                    fontWeight: 700,
                  }}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="description"
                  className="form-label d-inline"
                  style={{
                    color: "#667085",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}>
                  Description de la permission
                </label>
                <input
                  type="text"
                  id="description"
                  className="form-control"
                  value={newPermissionDescription}
                  onChange={(e) => setNewPermissionDescription(e.target.value)}
                  style={{
                    fontSize: "16px",
                    color: "#068F7A",
                    fontWeight: 700,
                  }}
                />
              </div>
              <div className="w-100 mt-3">
                <ButtonP text={"Ajouter"} type={"submit"} className="w-100" />
              </div>
              <div className="modal-footer w-100">
                <ButtonWhite
                  text={"Annuler"}
                  onClick={onClose}
                  className="w-100"
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AddPermissionModal;
