import React, {useRef,useState} from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { usePDF } from 'react-to-pdf';
import "./style.css"
import {useEffect} from "react";
import { useLocation } from 'react-router-dom'
import HeaderFirst from 'utils/HeaderFirst'
import { useGetJournalEcrituresByCodeJournalIdQuery, useGetJournalEcrituresByMonthIdQuery } from 'actions/sage_clone/journaux/queries';
import Select from "react-select";

export default function DisplayJournal() {
  const {state} = useLocation()
  const [monthValue, setMonthValue] = useState(null);

  const {data: livrejournal} = useGetJournalEcrituresByCodeJournalIdQuery({
    id: state.journalId,
  })
  const {data: livrejournalBymonth} = useGetJournalEcrituresByMonthIdQuery({
    dossier_id: state.dossier_id,
    livre_specific: state.journalId,
    month: monthValue?.value || 1
  })
  console.log("Les livres: ",livrejournalBymonth)

  let months = [
    { label: "Janvier", value: 1 },
    { label: "Février", value: 2 },
    { label: "Mars", value: 3 },
    { label: "Avril", value: 4 },
    { label: "Mai", value: 5 },
    { label: "Juin", value: 6 },
    { label: "Juillet", value: 7 },
    { label: "Août", value: 8 },
    { label: "Septembre", value: 9 },
    { label: "Octobre", value: 10 },
    { label: "Novembre", value: 11 },
    { label: "Décembre", value: 12 },
    { label: "Tous les mois", value: 13 },
  ];
  
  useEffect(() => {

    console.log("Nouveau mois: ",monthValue)
  }, [monthValue]);
  

   // pour excel
   const tableRef = useRef(null);
   const { onDownload } = useDownloadExcel({
     currentTableRef: tableRef.current,
     filename: 'LivreJournal table',
     sheet: 'LivreJournal'
   })  
 
   // pour pdf
   const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});
 
   const myRef = useRef(null);
 
 
   useEffect(function() {
     tableRef.current = myRef.current;
     targetRef.current = myRef.current;
   }, [myRef.current]);

   
  return (
    <div
      className="global client-detail-container"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <HeaderFirst
        nom={
          <h4 className="titre-dossier">
            Journal Achat 
          </h4>
        }
        description={
          <div>
            Vous pouvez ici visualiser l&apos;ensemble des écritures comptables de votre client.
          </div>
        }
      />

      <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginBottom:"10px"}}>
        <Select options={months} onChange={(e) => setMonthValue(e.value)}/>
        <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
          <button onClick={onDownload}> Export excel </button>
          <button onClick={() => toPDF()}>Download PDF</button>
        </div>

      </div>


        
      <table id="customers" ref={myRef}>

        <tr>
          <th>Compte général</th>
          <th>Compte Tiers</th>
          <th>Libelle</th>
          <th>Debit</th>
          <th>Credit</th>
        </tr>

        {
          livrejournal.map((livres,index) => {
            return(
                <>
                  <tr className='livre' key={index} style={{backgroundColor:"rgba(0,0,0,0.5)", color:"white"}}>
                    <td>{livres.numero_facture} {livres.reference} {livres.date}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {
                    livres.ecritures.map((ecriture,i) => {
                      return (
                        <tr key={i}>
                          <td>{ecriture.compte_general}</td>
                          <td>{ecriture.compte_tiers}</td>
                          <td>{ecriture.libelle}</td>
                          <td>{ecriture.debit}</td>
                          <td>{ecriture.credit}</td>
                        </tr>
                      )
                    })
                  }
                  <tr key={index+1} >
                    <td>-</td>
                    <td>-</td>
                    <td style={{fontWeight:"bold"}}>Total</td>
                    <td style={{fontWeight:"bold"}}>{livres.total_debits}</td>
                    <td style={{fontWeight:"bold"}}>{livres.total_credits}</td>
                  </tr>
                </>
            )
          })
        }
        
        <tr>
          <td>Alfreds Futterkiste</td>
          <td>Maria Anders</td>
          <td>Germany</td>
        </tr>
        <tr>
          <td>Berglunds snabbköp</td>
          <td>Christina Berglund</td>
          <td>Sweden</td>
        </tr>
        <tr>
          <td>Centro comercial Moctezuma</td>
          <td>Francisco Chang</td>
          <td>Mexico</td>
        </tr>
        <tr className='livre'>
          <td>Ernst Handel</td>
          <td>Roland Mendel</td>
          <td>Austria</td>
        </tr>
        <tr>
          <td>Island Trading</td>
          <td>Helen Bennett</td>
          <td>UK</td>
        </tr>
        <tr>
          <td>Königlich Essen</td>
          <td>Philip Cramer</td>
          <td>Germany</td>
        </tr>
        <tr>
          <td>Laughing Bacchus Winecellars</td>
          <td>Yoshi Tannamuri</td>
          <td>Canada</td>
        </tr>
        <tr className='livre'>
          
          <td>Giovanni Rovelli</td>
          <td colSpan="2">Italy</td>
        </tr>
        <tr>
          <td>North/South</td>
          <td>Simon Crowther</td>
          <td>UK</td>
        </tr>
        <tr>
          <td>Paris spécialités</td>
          <td>Marie Bertrand</td>
          <td>France</td>
        </tr>
      </table>


    </div>
  )
}
