import React from 'react';
import InputDetail from 'components/Inputs/InputDetail';
import ButtonP from 'components/buttons/ButtonP';

function FournisseurDetailsForm({ formData, setFormData, saveChanges }) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        saveChanges();
      }}
      className="d-flex flex-column gap-2"
      style={{ padding: '15px' }}
    >
      <div className="row">
        <div className="col-md-6">
          <InputDetail
            label="Nom"
            name="nom"
            value={formData.nom}
            onChange={(e) =>
              setFormData({
                ...formData,
                nom: e.target.value,
              })
            }
            className="custom-input"
          />
        </div>
        <div className="col-md-6">
          <InputDetail
            label="Adresse"
            name="adresse"
            value={formData.adresse}
            onChange={(e) =>
              setFormData({
                ...formData,
                adresse: e.target.value,
              })
            }
            className="custom-input"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <InputDetail
            label="Email"
            name="email"
            value={formData.email}
            onChange={(e) =>
              setFormData({
                ...formData,
                email: e.target.value,
              })
            }
            className="custom-input"
          />
        </div>
        <div className="col-md-6">
          <InputDetail
            label="Téléphone"
            name="telephone"
            value={formData.telephone}
            onChange={(e) =>
              setFormData({
                ...formData,
                telephone: e.target.value,
              })
            }
            className="custom-input"
          />
        </div>
      </div>
      <div className="my-3">
        <ButtonP type="submit" text="Sauvegarder" />
      </div>
    </form>
  );
}

export default FournisseurDetailsForm;
