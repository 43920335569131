import {  useNavigate } from "react-router-dom";
import React from "react";
import "./form.css";
import logo from "../../image/logo.png"
import { useFormContext } from "react-hook-form";
import {  useUpdatePasswordMutation } from "actions/auth/mutations";
import passwordImg from '../../image/passwordImg.png'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ErrorPasswordForm = () => {
    const navigate = useNavigate();

    const [forget] = useUpdatePasswordMutation();
    const {register, handleSubmit, formState:{errors}} = useFormContext()
    console.log(forget)
    const onSubmit = (data) => {
    forget(data).unwrap().then(res => {
      console.log(res)
      toast.success("Connexion réussie!");
      navigate("/");
  
    }).catch(error =>{
      console.log(error)
      toast.error("Erreur de connexion. Veuillez réessayer."); 
    })
     }
  return (
    <>
    <div className="container-fluid">
    
     <div className="row  auth-wrapper">
    
     <div className="col-12 col-md-6 col-lg-6 auth-main-col text-center ">
         <div className="d-flex flex-column align-items-end h-100 side">
        
           <div className="auth-body mx-auto">
           <div className="logo-app">
           <img src={logo} />
         </div>
             <div className="">
             <h6 className="title-connecter">Réinitialiser votre mot de passe</h6>
             <p className="paragraphe">N`&apos;utilisez pas de mot de passe facile à deviner</p>
             </div>
            
             <div className="auth-form-container text-start ">
               <form
                 className="auth-form"
                 onSubmit={handleSubmit(onSubmit)}
                 autoComplete="off"
               >
                 <div className="mb-2">
                   <label htmlFor="phone" className="form-label">
                     Nouveau mot de passe 
                   </label>
                   <input
                    type="password"
                    className="form-control custom-rounded"
                    id="password"
                    placeholder="Entrer votre de passe"
                    {...register("password", { required: 'Password is required' })}
                  />
                  {errors.password?.message && (
                    <div className="text-danger">{errors.password.message}</div>
                  )}
                 </div>

                 <div className="mb-2">
                   
                   <label htmlFor="password" className="form-label ">
                  Confirmez le mot de passe 
                   </label>

                   <input
                     type="password"
                     className="form-control custom-rounded"
                     id="password"
                     placeholder="Entrer votre de passe"
                     {...register("password", { required: 'Password is required' })}
                   />
                   {errors.password?.message && (
                     <div className="text-danger">{errors.password.message}</div>
                   )}
                
                 </div>

                 <div className="text-center">
                   <button
                     type="submit"
                     className="button-btn  w-100 "
                   >
                     Réinitialiser
                   </button>
                 </div>
               </form>
             </div>
           </div>
         </div>
       </div>
       <div className="col-12 col-md-5 col-lg-6  auth-background-col">
       <div className="auth-background-holder">
         <div className="allIconImage">
            <img src={passwordImg} className="img"/>
         </div>
         <div className="text-advice ">
           <h6>Easy compta</h6>
          <p style={{fontSize: '18px'}}>Pour ne pas oublier votre mot de passe plus tard, enregistrez-le dans le gestionnaire de mots de passe!</p>
         </div>
         </div>
       </div>
     </div>
   </div>
   <ToastContainer />
   </>
  )
}

export default ErrorPasswordForm