import { useEffect, useState } from 'react';
import { useGetFournisseurQuery } from 'actions/fournisseurs/queries';
import {
  useDeleteDepenseMutation,
  useUpdateDepenseMutation,
} from 'actions/depenses/mutations';
import { toast } from 'react-toastify';
import { useGetDepensesQuery } from 'actions/depenses/queries';

const useDepensesData = (dossier_id) => {
  const { data: depenseResponse, isLoading: isLoadingDepenses, refetch} =
    useGetDepensesQuery({ dossier_id });
  const { data: fournisseurResponse, isLoading: isLoadingFournisseurs } =
    useGetFournisseurQuery({ dossier_id: dossier_id });

  // RTK Query hooks for updating and deleting depenses
  const [updateDepense] = useUpdateDepenseMutation();
  const [deleteDepense] = useDeleteDepenseMutation();

  const [updatedDonnees, setUpdatedDonnees] = useState([]);

  useEffect(() => {
    const depenseData = depenseResponse?.data; // Assuming the actual data is nested under `.data`
    const fournisseurData = fournisseurResponse?.data; // Same assumption for fournisseurData

    if (
      !isLoadingDepenses &&
      !isLoadingFournisseurs &&
      depenseData &&
      Array.isArray(fournisseurData)
    ) {
      const fournisseurMap = fournisseurData.reduce((acc, fournisseur) => {
        acc[fournisseur.id] = fournisseur.nom;
        return acc;
      }, {});

      const enhancedDepenses = depenseData.map((depense) => ({
        ...depense,
        fournisseurs: fournisseurMap[depense.fournisseurs] || 'N/A',
      }));

      setUpdatedDonnees(enhancedDepenses);
      refetch()
    }
  }, [
    isLoadingDepenses,
    isLoadingFournisseurs,
    depenseResponse,
    fournisseurResponse,
  ]);

  // Function to handle the update of a depense
  const handleUpdateDepense = async (depenseId, updateData) => {
    try {
      await updateDepense({ id: depenseId, ...updateData }).unwrap();
      refetch()
      toast.success('Sortie mise à jour avec succès');
    } catch (error) {
      toast.error(
        `Erreur lors de la mise à jour: ${error.data?.error || error.message}`
      );
      console.error('Request failed:', error);
    }
  };

  // Function to handle the deletion of a depense
  const handleDeleteDepense = async (depenseId) => {
    try {
      await deleteDepense(depenseId).unwrap();
      toast.success('Suppression réussie');
      // Consider refetching or updating the local data to reflect the change
    } catch (error) {
      toast.error(
        `Erreur lors de la suppression: ${error.data?.error || error.message}`
      );
    }
  };

  return { updatedDonnees, handleUpdateDepense, handleDeleteDepense };
};

export default useDepensesData;
