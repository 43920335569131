import { useAddDepensesMutation } from 'actions/depenses/mutations';
import { useGetProduitsQuery } from 'actions/produits/queries';
import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import HeaderFirst from 'utils/HeaderFirst';
import { useAddProduitMutation } from 'actions/produits/mutations';
import { useGetFournisseurQuery } from 'actions/fournisseurs/queries';
import { useForm } from 'react-hook-form';
import './style.css';
import ProductModal from 'components/AddProducts/ProductModal';
import FileUpload from './FileUpload';
import ProductForm from 'components/ProductForm/ProductForm';

export default function AddDepensesForm() {
  const { clientID: dossier_id } = useParams();
  const { data: getProduitData } = useGetProduitsQuery({ dossier_id });
  const navigate = useNavigate();
  const { data: fournisseurResponse, isLoading: isLoadingFournisseurs } =
    useGetFournisseurQuery({ dossier_id: dossier_id });
  const [addDepense] = useAddDepensesMutation();
  const [showProductModal, setShowProductModal] = useState(false);
  const productFormMethods = useForm(); // Assuming you want to manage product form separately
  const [creationProduit, { isSuccess }] = useAddProduitMutation();
  const [depense, setDepense] = useState({
    fournisseur: null,
    intitule: '',
    dossier_id: dossier_id,
    facturable_au_client: false,
    taxes: 0,
    prix: 0,
    remise: 0,
    quantite: 1,
    description: '',
    crm: '',
    source_paiement: '',
    produits_associes: [{ produit: null, prix: null, quantite: 1 }],
  });

  const taxesOptions = {
    0: '0%',
    10: '10%',
    18: '18%',
  };

  const handleAddProduct = () => {
    setShowProductModal(true);
  };

  const handleProductChange = (index, event) => {
    const { name, value } = event.target;
    let updatedProduitsAssocies = [...depense.produits_associes];

    // Ensure the product exists in the array or create a new entry if needed
    if (!updatedProduitsAssocies[index]) {
      updatedProduitsAssocies[index] = { produit: '', prix: '', quantite: 1 };
    }

    // Update the specific product's property based on the input field name
    updatedProduitsAssocies[index][name] =
      name === 'produit' ? Number(value) : value;

    if (value === 'ajouterProduit') {
      handleAddProduct();
    }

    setDepense({ ...depense, produits_associes: updatedProduitsAssocies });
  };

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    setDepense((prevState) => {
      if (type === 'checkbox') {
        return { ...prevState, [name]: checked };
      } else if (['fournisseur', 'prix', 'quantite', 'taxes'].includes(name)) {
        const intValue = value ? parseInt(value, 10) : '';
        return { ...prevState, [name]: intValue };
      } else {
        return { ...prevState, [name]: value };
      }
    });
  };

  // Handle select change for produits

  // suppimer une ligne by davyCode
  const nom = (
    <div style={{ display: 'flex' }}>
      <h4 className="titre-dossier">Ajouter une dépense</h4>
    </div>
  );

  // fichier joint
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (depense.taxes > 100) {
      toast.error('Les taxes ne peuvent pas dépasser 100%');
      return;
    }

    // Verify all necessary fields are populated.
    const requiredFields = ['fournisseur', 'intitule', 'description'];
    const areFieldsFilled = requiredFields.every(
      (field) => depense[field] && depense[field].toString().trim() !== ''
    );

    if (!areFieldsFilled || !depense.produits_associes.length) {
      toast.error('Tous les champs sont obligatoires.');
      return;
    }

    // Construct the main data payload as a JSON object.
    let dataPayload = {
      fournisseurs: depense.fournisseur,
      intitule: depense.intitule,
      dossier_id: depense.dossier_id,
      facturable_au_client: depense.facturable_au_client,
      taxes: depense.taxes,
      description: depense.description,
      crm: depense.crm,
      source_paiement: depense.source_paiement,
      produits_associes: depense.produits_associes,
      prix: depense.prix,
      remise: depense.remise
    };

    // Check for the presence of a file to be uploaded.
    if (selectedFile) {
      const formData = new FormData();

      // Append scalar fields directly
      formData.append('dossier_id', depense.dossier_id);
      formData.append('fournisseurs', depense.fournisseur);
      formData.append('intitule', depense.intitule);
      formData.append('facturable_au_client', depense.facturable_au_client);
      formData.append('taxes', depense.taxes);
      formData.append('description', depense.description);
      formData.append('crm', depense.crm);
      formData.append('source_paiement', depense.source_paiement);
      formData.append('compte_comptable', depense.compte_comptable);
      formData.append('piece_joint', selectedFile);
      formData.append('prix', depense.prix);
      formData.append('remise', depense.remise);

      depense.produits_associes.forEach((produit, index) => {
        if (produit.produit && produit.prix !== undefined) {
          formData.append(
            `produits_associes[${index}]produit`,
            produit.produit
          );
          formData.append(
            `produits_associes[${index}]prix`,
            produit.prix.toString()
          );
          if (produit.quantite) {
            formData.append(
              `produits_associes[${index}]quantite`,
              produit.quantite.toString()
            );
          }
        } else {
          console.error(
            `Missing required product details at index ${index}`,
            produit
          );
        }
      });

      // Perform the POST request
      try {
        await addDepense(formData).unwrap();
        toast.success('Dépense ajoutée avec succès');
        navigate(-1);
      } catch (error) {
        console.error('Requête échouée:', error);
        toast.error(`Erreur: ${error.data?.message}` || "Echec d'ajout de dépense");
      }
    } else {
      // If there is no file, send JSON data directly.
      try {
        await addDepense(dataPayload, {
          headers: { 'Content-Type': 'application/json' },
        }).unwrap();
        toast.success('Dépense ajoutée avec succès');
        navigate(-1);
      } catch (error) {
        console.error('Requête échouée:', error);
        toast.error(
          `Error: ${error.data?.message}` || `Échec de l'ajout d'une dépense`
        );
      }
    }
  };

  const addProduct = () => {
    setDepense((prevState) => ({
      ...prevState,
      produits_associes: [
        ...prevState.produits_associes,
        { produit: '', prix: '', quantite: 1 },
      ],
    }));
  };

  const removeProduct = (index) => {
    setDepense((prevState) => ({
      ...prevState,
      produits_associes: prevState.produits_associes.filter(
        (_, i) => i !== index
      ),
    }));
  };

  useEffect(() => {
    if (isSuccess) {
      setShowProductModal(false);
    }
  }, [isSuccess]);

    // This useEffect is for calculate automatically the total price
    useEffect(() => {
      var somme=0
      const total = depense.produits_associes.map(produit => {
        somme += parseInt(produit.prix)*parseInt(produit.quantite)
        return somme
      })
     
    
      const montant_TTC = total[total.length - 1]*(1+((depense.taxes-depense.remise)/100))
      console.log("Somme Total: ",montant_TTC)
      setDepense({...depense, prix: montant_TTC})
  
    },[depense.produits_associes,depense.taxes,depense.remise])
    ///////////////////////////////////////////////////////////////////



  return (
    <div
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <HeaderFirst nom={nom} />
      <div className="container-background w-100 w-md-75 px-0 px-md-5">
        <Container fluid className="container-style p-4 mb-5">
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="d-flex flex-column align-items-center gap-5">
              <div className="w-100 d-flex flex-column gap-2">
                <Row className="row-gap-2">
                  <Col sm={6}>
                    <Form.Group controlId="formFournisseur">
                      <Form.Label className="mb-2">Fournisseur</Form.Label>
                      <Form.Control
                        as="select"
                        name="fournisseur"
                        value={depense.fournisseur}
                        onChange={handleInputChange}
                        disabled={isLoadingFournisseurs}
                        className="rounded rounded-pill"
                      >
                        <option value="">- Choisissez -</option>
                        {fournisseurResponse?.data.filter(item => item.archived == false && item.active == true).map((fournisseur) => (
                          <option key={fournisseur.id} value={fournisseur.id}>
                            {fournisseur.nom}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  
                  <Col sm={6}>
                    <Form.Group controlId="formIntitule">
                      <Form.Label className="mb-2">Intitulé</Form.Label>
                      <Form.Control
                        type="text"
                        name="intitule"
                        value={depense.intitule}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {/* ... Other input fields ... */}
                <Row className="row-gap-2">
                
                  <Col sm={6}>
                    <Form.Group controlId="formSourcePaiement">
                      <Form.Label className="mb-2">
                        Source de Paiement
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="source_paiement"
                        value={depense.source_paiement}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                      >
                        <option value="">- Choisissez -</option>
                        <option value="wave">Wave</option>
                        <option value="orange_money">Orange Money</option>
                        <option value="banque">Banque</option>
                        <option value="caisse">Caisse</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group controlId="formCrm">
                      <Form.Label className="mb-2">CRM</Form.Label>
                      <Form.Control
                        as="select"
                        name="crm"
                        value={depense.crm}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                      >
                        <option value="">- Choisissez -</option>
                        <option value="oui">Oui</option>
                        <option value="non">Non</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="row-gap-2">
                  <Col sm={6}>
                    <Form.Group controlId="formPrix">
                      <Form.Label className="mb-2">Prix</Form.Label>
                      <Form.Control
                        type="number"
                        name="prix"
                        value={depense.prix}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    {/* Placeholder for 'Categorie' */}
                    <Form.Group controlId="formTaxes">
                      <Form.Label className="mb-2">Description</Form.Label>
                      <Form.Control
                        type="text"
                        name="description"
                        value={depense.description}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="row-gap-2">
                  <Col sm={6}>
                    <Form.Group controlId="formTaxes">
                      <Form.Label className="mb-2">Taxes (%)</Form.Label>
                      <Form.Control
                        as="select"
                        name="taxes"
                        value={depense.taxes}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                      >
                        <option value="">- Sélectionnez -</option>
                        {Object.entries(taxesOptions).map(([value, label]) => (
                          <option key={value} value={parseInt(value)}>
                            {label}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group controlId="formRemise">
                      <Form.Label className="mb-2">Remise (%)</Form.Label>
                      <Form.Control
                        type="number"
                        name="remise"
                        value={depense.remise}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {depense.produits_associes.map((product, index) => (
                      <ProductForm
                        key={index}
                        product={product}
                        index={index}
                        handleProductChange={handleProductChange}
                        removeProduct={removeProduct}
                        productOptions={getProduitData?.data}
                        totalProducts={depense.produits_associes?.length}
                      />
                    ))}
                    <Button
                      onClick={addProduct}
                      className="mt-3 rounded rounded-pill"
                      style={{
                        backgroundColor: '#068f7a',
                        borderColor: '#068f7a',
                      }}
                    >
                      Ajouter un produit
                    </Button>
                  </Col>
                </Row>
              </div>
              <Row>
                <Form.Group controlId="fileUploadControl" className="w-100">
                  <FileUpload onFileSelect={handleFileSelect} />
                </Form.Group>
              </Row>
              <Row className="mt-2">
                <Col sm={12} className="text-center">
                  <div className="d-flex gap-3">
                    <button
                      onClick={() => navigate(-1)}
                      className="btn btn-danger rounded rounded-pill px-4"
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary rounded rounded-pill px-4"
                      style={{
                        backgroundColor: '#068f7a',
                        borderColor: '#068f7a',
                      }}
                      disabled={
                        !depense.fournisseur ||
                        !depense.intitule ||
                        !depense.prix ||
                        !depense.description
                      } // Add other fields as necessary
                    >
                      Enregistrer
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
            {/* ... Other input fields ... */}
          </Form>
        </Container>
        <ProductModal
          showModal={showProductModal}
          handleClose={() => setShowProductModal(false)}
          formMethods={productFormMethods}
          creationProduit={creationProduit}
          dossier_id={dossier_id}
          produits={getProduitData?.data}
        />
      </div>
    </div>
  );
}
