import { ApiMananger } from 'actions/rtkquery';
import {
  ADD_CLIENT_API_ROUTE,
  UPDATE_CLIENT_API_ROUTE,
  DELETE_CLIENT_API_ROUTE,
} from 'routes/api/endpoints';

const clientApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    // Mutation for creating a new client instance
    createClient: build.mutation({
      query(body) {
        return {
          url: ADD_CLIENT_API_ROUTE,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['CLIENT'],
    }),

    // Mutation for updating an existing client instance
    updateClient: build.mutation({
      query: ({ id, ...updateData }) => ({
        url: `${UPDATE_CLIENT_API_ROUTE}${id}/`,
        method: 'PUT',
        body: updateData,
      }),
      invalidatesTags: ['CLIENT'], // Invalidate the cache tags to refetch client-related data
    }),

    // Mutation for deleting an existing client instance
    deleteClient: build.mutation({
      query: ({id}) => ({
        url: `${DELETE_CLIENT_API_ROUTE}${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CLIENT'], // Invalidate to refetch client-related data
    }),

    // More client-related mutations and queries can be added here...
  }),
  overrideExisting: false,
});

export const {
  useCreateClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
} = clientApi;
