import {ApiMananger} from "actions/rtkquery";
import {GET_ROLE_API_ROUTE} from "routes/api/endpoints";

const PermissionsApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getPermissions: build.query({
      query: (id) => `${GET_ROLE_API_ROUTE}${id}/permissions/`,
      providesTags: ["PERMISSIONS"],
    }),
  }),
});

export const {useGetPermissionsQuery} = PermissionsApi;
