import SpliTemplateScreen from 'layouts'
import NavbarComponent from 'layouts/navbar/NavbarComponent'
import SidebarComponent from 'layouts/sidebar/SidebarComponent'
import React from 'react'
import { Outlet } from 'react-router-dom'

const DashboardAppShell = () => {

  return (
    <SpliTemplateScreen>
      <NavbarComponent />
        <SidebarComponent
          activeLinkColor={"#FFFFFF"}
          activeLinkBgColor={"#009688"}
          colorOnHover={"white"}
          linkColor="#4B5563"
          color="#4B5563"
        />
      <Outlet 
      
      />
    </SpliTemplateScreen>
  )
}

export default DashboardAppShell