import React from 'react';
import InputDetail from 'components/Inputs/InputDetail';
import ButtonP from 'components/buttons/ButtonP';

function CodeJournalDetailsForm({ formData, setFormData, saveChanges }) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        saveChanges();
      }}
      className="d-flex flex-column gap-2"
      style={{ padding: '15px' }}
    >
      <div className="row">
        <div className="col-md-6">
          <InputDetail
            label="code_journal"
            name="code_journal"
            value={formData.code_journal}
            onChange={(e) =>
              setFormData({
                ...formData,
                code_journal: e.target.value,
              })
            }
            className="custom-input"
          />
        </div>
        <div className="col-md-6">
          <InputDetail
            label="type_journal"
            name="type_journal"
            value={formData.type_journal}
            onChange={(e) =>
              setFormData({
                ...formData,
                type_journal: e.target.value,
              })
            }
            className="custom-input"
          />
        </div>
      </div>
      <div className="my-3">
        <ButtonP type="submit" text="Sauvegarder" />
      </div>
    </form>
  );
}

export default CodeJournalDetailsForm;
