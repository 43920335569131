import React, { useState } from 'react';
import './TabSwitcher.css'; // Ensure you have this CSS file for styling

const TabSwitcher = ({ onTabChange }) => {
  const [activeTab, setActiveTab] = useState('all');

  const tabs = [
    { id: 'all', text: 'Toutes les transactions' },
    { id: 'in', text: 'Entrées' },
    { id: 'out', text: 'Sorties' },
  ];

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    if (onTabChange) {
      onTabChange(tabId);
    }
  };

  return (
    <div className="tab-switcher">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`tab-item ${activeTab === tab.id ? 'active' : ''}`}
          onClick={() => handleTabClick(tab.id)}
        >
          {tab.text}
        </button>
      ))}
    </div>
  );
};

export default TabSwitcher;
