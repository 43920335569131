/* eslint-disable no-unused-vars */
/* eslint-disable valid-jsdoc */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  INVALIDATE_USERS,
  INVALIDATE_DOSSIER,
  INVALIDATE_CLIENT,
  INVALIDATE_FOURNISSEURS,
} from './invalidationTags';
import {
  logout,
  setAccessToken,
} from 'redux/features/userSliceWithTokenValidation';
import { DEFAULT_API, REFRESH_TOKEN } from 'routes/api/endpoints';

const baseQuery = fetchBaseQuery({
  baseUrl: DEFAULT_API,
  prepareHeaders: (headers, { getState }) => {
    const state = getState();
    const accessToken =
      state.auth && state.auth.currentUser
        ? state.auth.currentUser.access
        : null;

    if (accessToken) {
      return headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});

/**
 *=====================================================
 *
 * Verification de la validite du token
 *
 * ====================================================
 */
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error?.status === 401) {
    const refreshToken = api.getState().auth?.refreshToken; // Adjust this path if necessary

    if (refreshToken) {
      const refreshResult = await baseQuery(
        {
          url: REFRESH_TOKEN,
          method: 'POST',
          body: JSON.stringify({ refresh: refreshToken }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        api,
        extraOptions
      );

      if (refreshResult.data) {
        // Update the access token in the Redux store
        api.dispatch(setAccessToken(refreshResult.data.accessToken));

        // Retry the original request with the new access token
        result = await baseQuery(args, api, extraOptions);
      } else {
        // If token refresh fails, log out
        api.dispatch(logout());
      }
    } else {
      // No refresh token available, log out
      api.dispatch(logout());
    }
  }

  return result;
};

/**
 *=====================================================
 *
 * OPTION1: Base api avec validation du token
 *
 * ====================================================
 */
export const rxdApi = createApi({
  baseQuery: baseQueryWithReauth,
  refetchOnReconnect: true,
  keepUnusedDataFor: 50,
  reducerPath: 'path',
  endpoints: () => ({}),
});
/**
 *=====================================================
 *
 * OPTION1: Base api avec sans validation du token
 *
 * ====================================================
 */
// export const rxdApi = createApi({
//     baseQuery: fetchBaseQuery({ baseUrl:DEFAULT_API }),
//     refetchOnReconnect: true,
//     keepUnusedDataFor: 86400,
//     reducerPath: "path",
//     endpoints: () => ({}),
// });

export const ApiMananger = rxdApi.enhanceEndpoints({
  addTagTypes: [
    INVALIDATE_USERS,
    INVALIDATE_DOSSIER,
    INVALIDATE_CLIENT,
    INVALIDATE_FOURNISSEURS,
  ],
});
