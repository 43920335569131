import { ApiMananger } from 'actions/rtkquery';
import {
  ADD_ECRITURESCOMPTABLES_API_ROUTE,
  UPDATE_ECRITURESCOMPTABLES_API_ROUTE,
  DELETE_ECRITURESCOMPTABLES_API_ROUTE
} from 'routes/api/endpoints';

const ecritureComptableApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    // Mutation for creating a new fournisseur instance
    createEcritureComtable: build.mutation({
      query(body) {
        return {
          url: ADD_ECRITURESCOMPTABLES_API_ROUTE,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['ECRITURE_COMPTABLE'],
    }),

    // Mutation for updating an existing fournisseur instance
    updateEcritureComtable: build.mutation({
      query: ({ id, ...updateData }) => ({
        url: `${UPDATE_ECRITURESCOMPTABLES_API_ROUTE}${id}/`,
        method: 'PUT',
        body: updateData,
      }),
      invalidatesTags: ['ECRITURE_COMPTABLE'], // Invalidate the cache tags to refetch fournisseur-related data
    }),

    // Mutation for deleting an existing fournisseur instance
    deleteEcritureComtable: build.mutation({
      query: ({id}) => ({
        url: `${DELETE_ECRITURESCOMPTABLES_API_ROUTE}${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ECRITURE_COMPTABLE'], // Invalidate to refetch fournisseur-related data
    }),

    // More fournisseur-related mutations and queries can be added here...
  }),
  overrideExisting: false,
});

export const {
  useCreateEcritureComtableMutation,
  useUpdateEcritureComtableMutation,
  useDeleteEcritureComtableMutation,
} = ecritureComptableApi;
