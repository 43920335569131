import React from 'react';
import './skeleton.css'; // Créez un fichier CSS pour les styles

const SkeletonComponent = () => {
  return (
    <div className="skeleton-container">
      <div className="skeleton-line" />
      <div className="skeleton-line" />
      <div className="skeleton-line" />
      <div className="skeleton-line" />
      <div className="skeleton-line" />
      <div className="skeleton-line" />
      <div className="skeleton-line" />
      <div className="skeleton-line" />
    </div>
  );
};

export default SkeletonComponent;
