import React, { useState } from 'react';
import './table.css';
import TablePaginator from './TablePaginator';
import BootstrapTable from 'react-bootstrap-table-next';
// import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter'; // Importez les fonctions de filtrage nécessaires

const CustomBootstrapTable = ({
  columns = [],
  data = [],
  autoIncrementer,
  showPaginator = false,
  selectedRows, // Added prop for selected rows
  onSelectRow, // Added prop for handling row selection
}) => {
  const [paginationIndex, setPaginationIndex] = useState(0);

  const handleOnSelect = (row, isSelect) => {
    onSelectRow(row, isSelect); // Delegate handling to the parent component
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    bgColor: '#009688',
    selected: selectedRows, // Use the selectedRows from props
    onSelect: handleOnSelect,
  };
  // Configurez vos colonnes avec les options de filtrage appropriées
  columns = columns.map((col) => {
    // let filterType = textFilter(); // Par défaut, utilisez le filtre de texte

    // Déterminez le type de filtre en fonction de la colonne
    if (col.filterType === 'select') {
      //   filterType = selectFilter({
      //     options: col.filterOptions, // Ajoutez vos options de sélection ici
      //   });
    } else if (col.filterType === 'date') {
      //   filterType = dateFilter();
    }

    return {
      ...col,
      //   filter: filterType,
    };
  });

  return (
    <div className="row mt-3">
      <div className="col-md-12 col-12 table-responsive-sm">
        <BootstrapTable
          keyField={autoIncrementer || 'id'}
          data={data}
          columns={columns}
          //   filter={filterFactory()} // Activez le filtre global
          // {...cellEditFactory({ mode: 'click', blurToSave: true })} // Activez l'édition des cellules si nécessaire
          selectRow={selectRow}
          wrapperClasses="table-wrapper custom-table"
        />
      </div>
      <div className="text-end pe-4 py-3">
        {showPaginator && (
          <TablePaginator
            paginationIndex={paginationIndex}
            setPaginationIndex={setPaginationIndex}
            dataLength={data.length}
          />
        )}
      </div>
    </div>
  );
};

export default CustomBootstrapTable;
