import React from 'react';

// import { Link } from "react-router-dom";
import './accueil.css';
import HeaderFirst from 'utils/HeaderFirst';
import bleu from './../../image/jaune.png';
import Rectangle from '../../image/bleu.png';
import Rectangl from '../../image/rouge.png';
import Rect from '../../image/rose.png';
import vertical from '../../image/layout.png';
import user from '../../image/user.png';
import credit from '../../image/credit.png';
import change from '../../image/change.png';
import { useGetFournisseurQuery } from 'actions/fournisseurs/queries';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CLIENT,
  FACTURE,
  FOURNISSEURS,
  TRANSACTION,
} from 'routes/navigation/navigationPaths';
import Cover from 'utils/cover';
import { useGetClientQuery } from 'actions/clients/queries';
import GridList from 'react-flexible-list';
import SkeletonComponent from 'utils/skeleton/skeleton';
import { useGetTransactionQuery } from 'actions/dossiers/queries';
import { useGetOcrQuery } from 'actions/orc/queries';
// import Modal from "components/Modal";
// import arrow from "../../image/arrow-left.png";
function Accueil() {
  const { clientID: dossier_id } = useParams();
  const { data: getFournisseur, isLoading } = useGetFournisseurQuery({
    dossier_id,
  });
  const { data: getClient } = useGetClientQuery({ dossier_id });
  const { data: getAllTransactions } = useGetTransactionQuery({ dossier_id });
  const { data: getDataFacture } = useGetOcrQuery();


  const donnees = getFournisseur?.data.filter(item => item.archived == false);
  const client = getClient?.data.filter(item => item.archived == false);

  const navigate = useNavigate();

  const nom = <div style={{ paddingTop: '40px' }}>Tableau de Bord</div>;
  const description = (
    <div>Voici un aperçu global de vos différentes données</div>
  );

  return (
    <div
      className="global"
      style={{
        backgroundColor: 'white',
        minHeight: '800px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <Cover />
      <br />
      <div
        className="header"
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          // padding: "13px",
        }}
      >
        <HeaderFirst nom={nom} description={description} />
      </div>

      <div className="mt-3">
        {isLoading ? (
          <GridList
            cardWidth={200}
            resourceData={[1, 2, 3, 4, 5]}
            resourceItem={() => <SkeletonComponent />}
          />
        ) : (
          <GridList
            cardWidth={230}
            resourceData={[
              {
                id: 1,
                title: 'Fournisseurs',
                image: bleu,
                image2: vertical,
                count: donnees?.length,
                onClick: FOURNISSEURS,
              },
              {
                id: 2,
                title: 'Clients',
                image: Rectangle,
                image2: user,
                count: client?.length,
                onClick: CLIENT,
              },
              {
                id: 3,
                title: 'Factures',
                image: Rectangl,
                image2: credit,
                count: getDataFacture?.filter(item => item.archived == false).count,
                onClick: FACTURE,
              },
              {
                id: 4,
                title: 'Transactions',
                image: Rect,
                image2: change,
                count: getAllTransactions?.transactions.filter(item => item.archived == false).length,
                onClick: TRANSACTION,
              },
            ]}
            resourceItem={(item) => (
              <div
                className="cartes"
                style={cardStyle}
                onClick={() => navigate(item.onClick)}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '15px',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <div>
                      <img
                        src={item.image}
                        alt={item.title}
                        style={{ marginRight: '10px' }}
                      />
                    </div>
                    <div>
                      <p style={{ fontWeight: '700', fontSize: '27px' }}>
                        {item.count}
                      </p>
                      <h2 style={{ fontSize: '12px', fontWeight: '700' }}>
                        {item.title}
                      </h2>
                    </div>
                  </div>
                  <div style={{}}>
                    <img src={item.image2} />
                  </div>
                </div>
              </div>
            )}
          />
        )}
      </div>
    </div>
  );
}
const cardStyle = {
  backgroundColor: '#FFF',
  padding: '10px',
  borderRadius: '20px',
  // border: "1px solid gray",
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)',
  minWidth: '200px',
  height: '120px',
  marginBottom: '15px',
  // Adjust the margin to reduce spacing
};

export default Accueil;
