import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderFirst from 'utils/HeaderFirst';
import { toast } from 'react-toastify';
import { useUpdateFournisseurMutation } from 'actions/fournisseurs/mutations';
import { useGetFournisseurIdQuery } from 'actions/fournisseurs/queries';
import { useGetTransactionsQuery } from 'actions/transactions/queries';
import { useGetProduitsQuery } from 'actions/produits/queries';
import FournisseurDetailsForm from './FournisseurDetailsForm';
import TransactionDetails from './TransactionDetails';

function DetailFournisseurs() {
  const { clientID, id: fournisseurId } = useParams();
  const navigate = useNavigate();

  const {
    data: fournisseurDetail,
    isFetching: fournisseurFetching,
    error: fournisseurError,
  } = useGetFournisseurIdQuery({
    dossier_id: clientID,
    id: fournisseurId,
  });

  const [updateFournisseur] = useUpdateFournisseurMutation();

  const [formData, setFormData] = useState({
    nom: '',
    adresse: '',
    email: '',
    telephone: '',
  });

  useEffect(() => {
    // Ensure we directly access the fournisseur data from the API response correctly
    if (fournisseurDetail) {
      setFormData(fournisseurDetail.data[0]);
    }
  }, [fournisseurDetail]);

  const {
    data: transactions,
    isFetching: transactionsFetching,
    error: transactionsError,
  } = useGetTransactionsQuery({
    dossier_id: clientID,
    id: fournisseurId,
  });

  const saveChanges = async () => {
    try {
      await updateFournisseur({
        dossier_id: clientID,
        id: fournisseurId,
        ...formData,
      }).unwrap();
      toast.success(
        'Les détails du fournisseur ont été mis à jour avec succès.'
      );
      navigate(-1);
    } catch (error) {
      toast.error(
        'Une erreur est survenue lors de la mise à jour du fournisseur.'
      );
    }
  };

  const {
    data: produitsData, // Fetch product details
  } = useGetProduitsQuery({ dossier_id: clientID });

  if (fournisseurFetching || transactionsFetching)
    return <div>Chargement...</div>;
  if (fournisseurError || transactionsError)
    return <div>Une erreur est survenue.</div>;

  return (
    <div
      className="global fournisseur-detail-container"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <HeaderFirst
        nom={
          <h4 className="titre-dossier">
            Détail du fournisseur: {formData.nom || 'Inconnu'}
          </h4>
        }
        description={
          <div>
            Vous pouvez visualiser ou modifier les détails de ce fournisseur
            ici.
          </div>
        }
      />
      <FournisseurDetailsForm
        formData={formData}
        setFormData={setFormData}
        saveChanges={saveChanges}
      />
      <TransactionDetails
        transactions={transactions}
        fournisseurId={fournisseurId}
        produitsData={produitsData}
      />
    </div>
  );
}

export default DetailFournisseurs;
