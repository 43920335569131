import React, {useState} from "react";
import "./TabSwitcher.css"; // Ensure you have this CSS file for styling

const TabSwitcher = ({onTabChange}) => {
  const [activeTab, setActiveTab] = useState("role");

  const tabs = [
    {id: "role", text: "Rôles"},
    {id: "permissions", text: "Permissions"},
  ];

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    if (onTabChange) {
      onTabChange(tabId);
    }
  };

  return (
    <div className="tab-switcher">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`tab-item ${activeTab === tab.id ? "active" : ""}`}
          onClick={() => handleTabClick(tab.id)}>
          {tab.text}
        </button>
      ))}
    </div>
  );
};

export default TabSwitcher;
