// import { TextField, Button, Container, Paper, Typography } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
// import Right from "../../image/Right.png"
// import Input from 'components/Input'
import React, { useState } from 'react';
import './forme.css';
import logo from '../../image/logo.png';
import { useFormContext } from 'react-hook-form';
import { useLoginUserMutation } from 'actions/auth/mutations';
import allIcon from '../../image/allIcon.png';
// import { ACCEUIL_PATH } from "routes/navigation/navigationPaths";
import useGetAuthenticate from 'hooks/useIsAuthenticatedUser';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserStatus,
  setCredentials,
} from 'redux/features/userSliceWithTokenValidation';
import { ACCEUIL_PATH } from 'routes/navigation/navigationPaths';
import { ToastContainer, toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { FaEye } from 'react-icons/fa';
import { FaEyeSlash } from 'react-icons/fa';
// import { store } from "redux/store";

const ConnexionForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login] = useLoginUserMutation();
  const isAuthenticate = useGetAuthenticate();
  const userStatus = useSelector(getUserStatus);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // Utilise useFormContext pour accéder aux méthodes du formulaire depuis le contexte.
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  const onSubmit = (data) => {
    setLoading(true);
    login(data)
      .unwrap()
      .then((res) => {
        console.log('response', res);
        console.log('User Status:', userStatus);
        toast.success('Connexion réussie !');
        dispatch(setCredentials(res));
        if (isAuthenticate) {
          navigate(ACCEUIL_PATH);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error('La connexion a échoué. Vérifiez vos identifiants ou activez votre compte');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row  auth-wrapper">
          <div className="col-12 col-md-6 col-lg-6 auth-main-col text-center ">
            <div className="d-flex flex-column align-items-end h-100 side">
              <div className="auth-body mx-auto" style={{ width: '450px' }}>
                <div className="logo-app">
                  <img src={logo} />
                </div>
                <div className="">
                  <h6 className="title-connecter">
                    Connectez-vous à votre compte
                  </h6>
                  <p className="paragraphe">
                    Allez, inscrivez-vous et plongez dans le monde passionnant
                    de la technologie financière. Conçue pour répondre à vos
                    besoins comptables les plus complexes
                  </p>
                </div>

                <div className="auth-form-container text-start ">
                  <form
                    className="auth-form"
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete="off"
                  >
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="form-label"
                        style={{ fontSize: '15px' }}
                      >
                        Adresse email
                      </label>
                      <input
                        type="email"
                        className="form-control custom-rounded"
                        id="email"
                        placeholder="email"
                        {...register('email', {
                          required: 'email obligatoire',
                        })}
                      />
                      {errors.email?.message && (
                        <div className="text-danger">
                          {errors.email.message}
                        </div>
                      )}
                    </div>

                    <div className="mb-2">
                      <label
                        htmlFor="password"
                        className="form-label "
                        style={{ fontSize: '15px' }}
                      >
                        Mot de passe
                      </label>

                      <div className="password-input-container d-flex align-items-center justify-content-end">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          className="form-control custom-rounded"
                          id="password"
                          placeholder="Entrer votre mot de passe"
                          {...register('password', {
                            required: 'Le mot de passe est obligatoire ',
                          })}
                        />
                        <span
                          className="password-toggle-icon me-3"
                          onClick={() => setShowPassword(!showPassword)}
                          style={{
                            position: 'fixed',
                            cursor: 'pointer',
                            display: 'flex',
                          }}
                        >
                          {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </span>
                      </div>
                      {errors.password?.message && (
                        <div className="text-danger">
                          {errors.password.message}
                        </div>
                      )}
                      <div className="extra mt-3 row justify-content-between">
                        <div className="col-5">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="remember"
                              // checked={remember}
                              // onChange={(e) => setRemember(e.currentTarget.checked)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="remember"
                            >
                              Souviens-toi de moi
                            </label>
                          </div>
                        </div>
                        <div className="col-7">
                          <div className="forgot-password text-end">
                            <Link to="/auth/forgetPassword">
                              Mot de passe oublié?
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-center">
                      {loading ? (
                        <CircularProgress color="primary" size={24} /> // Render loader when loading is true
                      ) : (
                        <button type="submit" className="button-btn  w-100 ">
                          Se connecter
                        </button>
                      )}
                    </div>
                  </form>
                  <div className="auth-option  linker">
                    Vous n&apos;avez pas de compte?
                    <Link className="text-link" to={'signup'}>
                      &nbsp; créer votre compte ?
                    </Link>
                  </div>
                  {/* <hr /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-6  auth-background-col">
            <div className="auth-background-holder">
              <div className="allIconImage">
                <img src={allIcon} className="img" />
              </div>
              <div className="text-bas ">
                <h6>Easy compta</h6>
                <p>
                  Easy Compta simplifie la gestion financière, vous permettant
                  de suivre vos revenus et dépenses, générer des rapports
                  détaillés et rester toujours en contrôle de votre
                  comptabilité.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnexionForm;
