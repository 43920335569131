import React  from "react";
import "./style.css";
import LivreJournal from "./components/LivreJournal";
//import {useParams} from "react-router-dom";

const Etat = ({ecritures,id_journal,setFacture}) => {
  //const { id:livre} = useParams();
  return (
    <div
      className="global"
      style={{
        backgroundColor: "white",
        minHeight: "50px",
        borderRadius: "20px",
        marginBottom:"20px"
      }}>

        <LivreJournal id_journal={id_journal} ecritures={ecritures} setFacture={setFacture}/>

    </div>
  );
};


export default Etat;
