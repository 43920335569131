import { useEffect, useState } from 'react';
import {
  useUpdateClientMutation,
  useDeleteClientMutation,
} from 'actions/clients/mutations';
import { toast } from 'react-toastify';
import { useGetClientQuery } from 'actions/clients/queries';

const useClientData = (dossierId) => {
  const {
    data: clientResponse,
    isLoading: isLoadingClients,
    refetch,
  } = useGetClientQuery({ dossier_id: dossierId });

  const [updateClient] = useUpdateClientMutation();
  const [deleteClient] = useDeleteClientMutation();

  const [updatedClients, setUpdatedClients] = useState([]);

  useEffect(() => {
    if (
      !isLoadingClients &&
      clientResponse?.data &&
      Array.isArray(clientResponse.data)
    ) {
      refetch()
      const enhancedClients = clientResponse.data.map((client) => ({
        ...client,
        displayName: `${client.nom} (${client.id})`,
      }));
      setUpdatedClients(enhancedClients);
    }
  }, [isLoadingClients, clientResponse]);

  const handleUpdateClient = async (clientId, updateData) => {
    try {
      await updateClient({
        id: clientId,
        ...updateData,
      }).unwrap();
      refetch(); // Refetch client data from the backend
    } catch (error) {
      toast.error(`Update error: ${error.data?.error || error.message}`);
      console.error('Request failed:', error);
    }
  };

  const handleDeleteClient = async (clientId) => {
    try {
      await deleteClient({ id: clientId }).unwrap();
      toast.success('Client deleted successfully');
      refetch();
    } catch (error) {
      console.log(clientId)
      toast.error(`Deletion error: ${error.data?.error || error.message}`);
    }
  };

  return { updatedClients, handleUpdateClient, handleDeleteClient, refetch };
};

export default useClientData;
