// Part 1: Imports and Setup (Completed)

import React, { useRef } from 'react';
import ReactModal from 'react-modal';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import logo from '../../image/logo.png'; // Path to your logo image, make sure it's correct
import { useGetProduitsQuery } from 'actions/produits/queries';
import { useParams } from 'react-router-dom';
import { MdOutlineAttachEmail } from 'react-icons/md';
import { FaDownload, FaPrint } from 'react-icons/fa';
import './client.css';

const Facture = ({ isOpen, onClose, rowData, data }) => {
  const { clientID: dossier_id } = useParams();
  const selectedRow = data?.find((row) => row.id === rowData);
  const modalContentRef = useRef(null);
  const { data: produitsData, isLoading } = useGetProduitsQuery({
    dossier_id: dossier_id,
  });

  if (isLoading) {
    <div>Chargement...</div>;
  }

  const handlePrint = () => {
    if (!modalContentRef.current) return;

    html2canvas(modalContentRef.current, {
      scale: window.devicePixelRatio,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      // Open a blank printing window
      const printWin = window.open('', '_blank', 'width=800,height=900');

      // Check if the window opened successfully
      if (!printWin) {
        alert(
          'Echec à ouvrir le dialog. Svp vérifier les paramètres du PopUp.'
        );
        return;
      }

      // Define CSS styles for the print window, hiding buttons and adjusting the image
      const printStyles = `
      <style>
        @media print {
          button, .print-hide { display: none !important; }
          img { max-width: 100%; height: auto; }
        }
      </style>`;

      // Construct the HTML content with the image and styles
      const htmlContent = `
      <html>
        <head>
          <title>Facture</title>
          ${printStyles}
        </head>
        <body onload="window.print(); window.close();">
          <img src="${imgData}">
        </body>
      </html>`;

      // Write and load the content in the print window
      printWin.document.open();
      printWin.document.write(htmlContent);
      printWin.document.close();
    });
  };

  const productDetails = selectedRow?.produits_associes.map((prod, index) => {
    // Safely attempt to access produitsData as an array
    const productDetail = (produitsData.data || []).find(
      (p) => p.id === prod.produit
    );
    return {
      key: index,
      Produit: productDetail?.nom || 'Produit inconnu',
      Quantité: prod.quantite,
      Prix: `${prod.prix} Fcfa`,
      Total: `${parseFloat(prod.prix) * parseInt(prod.quantite, 10)} Fcfa`,
    };
  });

  const downloadAsPDF = () => {
    if (!modalContentRef.current) return;
    html2canvas(modalContentRef.current, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [canvas.width, canvas.height],
      });
      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
      pdf.save('invoice.pdf');
    });
  };

  // Enhanced return statement for the Facture component, in French, with an email button
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 1050,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '60%',
          height: '700px',
          overflow: 'auto',
          borderRadius: '10px',
          border: '1px solid #ccc',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <div ref={modalContentRef} style={{ padding: '20px' }}>
        <div className="modal-header d-flex justify-content-between align-items-center">
          <h5 className="modal-title">Facture</h5>
          <button type="button" className="close btn fs-3" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="invoice-header text-center mb-4">
          <img
            src={logo}
            alt="Logo de l'entreprise"
            style={{ maxWidth: '100px', marginBottom: '10px' }}
          />
          <h2>EASYCOMPTA</h2>
          <p>Services de Comptabilité Professionnels</p>
        </div>
        <div className="invoice-body">
          <div className="row mb-4">
            <div className="col-6">
              <h6 className="mb-1">De :</h6>
              <div>
                {selectedRow?.dossier_id_details?.nom_entreprise ||
                  'Chargement...'}
              </div>
              <div>
                Tél :{' '}
                {selectedRow?.dossier_id_details?.telephone || 'Chargement...'}
              </div>
              <div>
                Adresse :{' '}
                {selectedRow?.dossier_id_details?.adresse || 'Chargement...'}
              </div>
            </div>
            <div className="col-6 text-right">
              <h6 className="mb-1">À :</h6>
              <div>
                {selectedRow?.fournisseurs_details?.nom ||
                  selectedRow?.client_details?.nom ||
                  'Chargement...'}
              </div>
              <div>
                Tél :{' '}
                {selectedRow?.fournisseurs_details?.telephone ||
                  selectedRow?.client_details?.telephone ||
                  'Chargement...'}
              </div>
              <div>
                Adresse :{' '}
                {selectedRow?.fournisseurs_details?.adresse ||
                  selectedRow?.client_details?.adresse ||
                  'Chargement...'}
              </div>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Désignation</th>
                <th>Quantité</th>
                <th>Prix Unit.</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {productDetails?.map(
                ({ key, Produit, Quantité, Prix, Total }) => (
                  <tr key={key}>
                    <td>{Produit}</td>
                    <td>{Quantité}</td>
                    <td>{Prix}</td>
                    <td>{Total}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        <div className="invoice-footer text-right mt-4">
          <p>
            Montant Total : <strong>{selectedRow?.montant_total} Fcfa</strong>
          </p>
          {/* Section de Signature */}
          <div className="signature-section" style={{ marginTop: '40px' }}>
            <p>Signature :</p>
            <div
              className="signature-space"
              style={{
                height: '50px',
                marginBottom: '20px',
                width: '50px',
              }}
            >
              {/* Ici, vous pouvez ajouter une image de la signature ou laisser un espace vide pour une signature manuelle */}
            </div>
            <p style={{ textAlign: 'right' }}>Signature Autorisée</p>
          </div>
          <div className="buttons d-flex gap-3 print-hide">
            <button
              className="btn btn-info"
              onClick={() => {
                /* Logic to send email */
              }}
            >
              <MdOutlineAttachEmail />
            </button>
            <button className="btn btn-danger" onClick={downloadAsPDF}>
              <FaDownload />
            </button>
            <button className="btn btn-secondary" onClick={handlePrint}>
              <FaPrint />
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default Facture;
