import {ApiMananger} from "actions/rtkquery";
import {
  ADD_PLAN_COMPTABLE_API_ROUTE,
  UPDATE_PLAN_COMPTABLE_API_ROUTE,
  DELETE_PLAN_COMPTABLE_API_ROUTE,
} from "routes/api/endpoints";

const planComptableApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    // Mutation for creating a new collaborateur instance
    createPlanComptable: build.mutation({
      query(body) {
        return {
          url: `${ADD_PLAN_COMPTABLE_API_ROUTE}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["PlanComptable"],
    }),

    // Mutation for updating an existing collaborateur instance
    updatePlanComptable: build.mutation({
      query: ({id, ...updateData}) => ({
        url: `${UPDATE_PLAN_COMPTABLE_API_ROUTE}${id}/`,
        method: "PUT",
        body: updateData,
      }),
      invalidatesTags: ["PlanComptable"], // Invalidate the cache tags to refetch collaborateur-related data
    }),

    // Mutation for deleting an existing collaborateur instance
    deletePlanComptable: build.mutation({
      query: ({id}) => ({
        url: `${DELETE_PLAN_COMPTABLE_API_ROUTE}${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["PlanComptable"], // Invalidate to refetch collaborateur-related data
    }),

    // More collaborateur-related mutations and queries can be added here...
  }),
  overrideExisting: false,
});

export const {
  useCreatePlanComptableMutation,
  useUpdatePlanComptableMutation,
  useDeletePlanComptableMutation,
} = planComptableApi;
