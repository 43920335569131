import { ApiMananger } from "actions/rtkquery";
import { GET_ALL_TRANSACTIONS_API_ROUTE, GET_DOSSIER_CLIENT_API_ROUTE } from "routes/api/endpoints";

const dossierApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
        //DOSSIER
       //DOSSIER
       getDossier: build.query({
        query: ({ comptableId, token }) => `${GET_DOSSIER_CLIENT_API_ROUTE}?comptable=${comptableId}&token=${token}`,
        providesTags: ["INVALIDATE_DOSSIER"],
    }),
    getDossierId: build.query({
        query: ({ comptableId }) => `${GET_DOSSIER_CLIENT_API_ROUTE}?comptable=${comptableId}`,
        providesTags: ["INVALIDATE_DOSSIER"],
    }),
    getDossierById: build.query({
        query: ({ id }) => `${GET_DOSSIER_CLIENT_API_ROUTE}${id}/`,
        providesTags: ["INVALIDATE_DOSSIER"],
    }),
    getDossierArchived: build.query({
        query: ({ archived }) => `${GET_DOSSIER_CLIENT_API_ROUTE}?archived=${archived}`,
        providesTags: ["INVALIDATE_DOSSIER"],
    }),
    getDossierActivated: build.query({
        query: ({ comptableId, activated }) => `${GET_DOSSIER_CLIENT_API_ROUTE}?comptable=${comptableId}&activated=${activated}`,
        providesTags: ["INVALIDATE_DOSSIER"],
    }),
    getTransaction: build.query({
        query: ({ dossier_id }) => `${GET_ALL_TRANSACTIONS_API_ROUTE}${dossier_id}/`,
        providesTags: ["INVALIDATE_DOSSIER"],
    }),
    
}),
});

export const {
//dossier
useGetDossierQuery,
useGetDossierIdQuery,
useGetDossierActivatedQuery,
useGetDossierArchivedQuery,
useGetDossierByIdQuery,
//ALL TRASANCTION
useGetTransactionQuery,
} = dossierApi;
