import React, {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import HeaderFirst from "utils/HeaderFirst";
import HeaderButton from "utils/headerButton";
import CustomBootstrapTable from "utils/reactTable";
import ModalClient from "./Modal";
import arrow from "../../image/arrow-left.png";
import {useNavigate, useParams} from "react-router-dom";
import "./client.css";
import TablePaginators from "components/Pagination/TablePaginator";
import {toast} from "react-toastify";
import useFactureData from "./useFactureData";
import {useGetCodesQuery} from "actions/comptabilite/queries";
// import axios from 'axios';
// import { useGetClientQuery } from 'actions/dossiers/queries';

const SaisieManuelle = () => {
  const {clientID: dossier_id} = useParams();
  const [serachTerm, setSearchedTerm] = useState("");
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const {updatedFacture, handleUpdateFacture, handleDeleteFacture, refetch} =
    useFactureData(dossier_id);
  const [viewArchived, setViewArchived] = useState(false);

  //Les codes
  const {data: codesResponse = [], isLoading: isLoadingResponse} =
    useGetCodesQuery();
   

  if (!isLoadingResponse) console.log("Mes codes ordered: ", codesResponse);

  console.log("Mes factureesss: ", updatedFacture);
  const filteredData = updatedFacture.filter((facture) =>
    facture?.name?.toLowerCase().includes(serachTerm.toLowerCase())
  );

  const navigate = useNavigate();

  const currentPageData = filteredData.slice(
    paginationIndex * pageSize,
    (paginationIndex + 1) * pageSize
  );

  const handleActionSelected = (action) => {
    if (action === "archive") {
      setViewArchived(!viewArchived); // Toggle between viewing archived and active depenses
    } else {
      console.log(`Action selected: ${action}`);
      // Handle other actions as necessary
    }
  };

  const handlePageChange = (newIndex) => {
    setPaginationIndex(newIndex);
  };

  const handleSearchChange = (newSearchTerm) => {
    setSearchedTerm(newSearchTerm);
  };

  const handleRowSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows((prev) => [...prev, row.id]);
    } else {
      setSelectedRows((prev) => prev.filter((id) => id !== row.id));
    }
  };

  const rowEvents = {
    onClick: (e, row) => {
      console.log("Table row clicked:", row); // Check if this logs
      handleRowClick(row);
    },
  };

  const columns = [
    {
      dataField: "name",
      text: "Type",
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell}</div>
      ),
    },
    {
      dataField: "type",
      text: "Type",
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell}</div>
      ),
    },
    {
      dataField: "somme",
      text: "Somme",
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell}</div>
      ),
    },
  ];

  const handleDossierCreated = () => {
    setIsModalOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
    refetch();
  };

  useEffect(() => {
    //
  }, []);

  const nom = (
    <div style={{display: "flex"}}>
      <h4 className="titre-dossier">Documents financiers</h4>
      <span className="nbrDossier">{filteredData?.length}</span>
    </div>
  );

  const description = <div>Voici tous vos documents</div>;

  const handleRowClick = (row) => {
    const clickedRowId = row?.id;
    console.log(clickedRowId);
  };

  const onEyesClick = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const detailClientURL = `details/${selectedRowId}/`;
      navigate(detailClientURL);
      console.log(selectedRowId);
    }
  };

  const onClickDelete = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const facture = updatedFacture.find((d) => d.id === selectedRowId);
      if (!facture) {
        toast.error("Facture non trouvé.");
        return;
      }

      try {
        const newArchivedStatus = !facture.active;
        await handleDeleteFacture(selectedRowId);
        /*await handleUpdateClient(selectedRowId, {
          active: newArchivedStatus,
          archived: true
        });*/
        setSelectedRows([]);
        toast.info(
          `Facture ${newArchivedStatus ? "archivé" : "désarchivé"} avec succès.`
        );
      } catch (error) {
        toast.error(
          "Une erreur est survenue lors de la mise à jour du facture."
        );
        console.error("Erreur lors de la mise à jour du facture :", error);
      }
    } else {
      toast.warning(
        "Veuillez sélectionner exactement une facture à archiver ou désarchiver."
      );
    }
  };

  const onClickArchive = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const facture = updatedFacture.find((d) => d.id === selectedRowId);
      if (!facture) {
        toast.error("Facture non trouvé.");
        return;
      }

      try {
        const newArchivedStatus = !facture.active;
        await handleUpdateFacture(selectedRowId, {
          active: newArchivedStatus,
          archived: true,
        });
        setSelectedRows([]);
        toast.info(
          `Facture ${newArchivedStatus ? "archivé" : "désarchivé"} avec succès.`
        );
      } catch (error) {
        toast.error(
          "Une erreur est survenue lors de la mise à jour du facture."
        );
        console.error("Erreur lors de la mise à jour de la facture :", error);
      }
    } else {
      toast.warning(
        "Veuillez sélectionner exactement une facture à archiver ou désarchiver."
      );
    }
  };

  const onActivedClick = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const facture = updatedFacture.find((d) => d.id === selectedRowId);
      if (!facture) {
        toast.error("Facture non trouvé.");
        return;
      }

      try {
        const newActiveStatus = !facture.active;
        await handleUpdateFacture(selectedRowId, {
          active: newActiveStatus,
        });
        setSelectedRows([]);
        toast.info(
          `Facture ${newActiveStatus ? "activé" : "désactivé"} avec succès.`
        );
      } catch (error) {
        toast.error(
          "Une erreur est survenue lors de la mise à jour de la facture."
        );
        console.error("Erreur lors de la mise à jour de la facture :", error);
      }
    } else {
      toast.warning(
        "Veuillez sélectionner exactement une facture à activer ou désactiver."
      );
    }
  };

  const methods = useForm();
  return (
    <div
      className="global"
      style={{
        backgroundColor: "white",
        minHeight: "500px",
        borderRadius: "20px",
        padding: "15px",
      }}>
      <div
        className="header pb-3"
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
          // padding: "13px",
        }}>
        <HeaderFirst nom={nom} description={description} />
        <button
          type="button"
          className="btn btn-outline-dark"
          style={{
            borderRadius: "31px",
            border: "1px solid #AEAEAE",
            padding: "8px",
          }}
          onClick={openModal}>
          <span style={ajoutFou}>+ Transaction financière</span>
        </button>

        {isModalOpen && (
          <FormProvider {...methods}>
            <ModalClient
              isOpen={isModalOpen}
              onClose={closeModal}
              onDossierCreated={handleDossierCreated}
              factures={updatedFacture}
              codes={codesResponse || []}
              refetch={refetch}
              dossier_id={dossier_id}>
              <h2
                style={{color: " #009688", fontSize: "20px", fontWeight: 700}}>
                <span>
                  {" "}
                  <img src={arrow} />
                </span>{" "}
                &nbsp;CREER UN NOUVEAU DOSSIER CLIENT
              </h2>
            </ModalClient>
          </FormProvider>
        )}
      </div>
      <HeaderButton
        placeholder={"Rechercher une facture"}
        onSearchChange={handleSearchChange}
        isButtonsEnabled={selectedRows.length > 0}
        onClickArchive={onClickArchive}
        onActivedClick={onActivedClick}
        onEyesClick={onEyesClick}
        onClickDelete={onClickDelete}
      />

      <div>
        {updatedFacture ? (
          <CustomBootstrapTable
            keyField="id"
            data={currentPageData?.filter((facture) =>
              facture.name.toLowerCase().includes(serachTerm.toLowerCase())
            )}
            columns={columns}
            selectedRows={selectedRows}
            onSelectRow={handleRowSelect}
            wrapperClasses="table-wrapper"
            rowEvents={rowEvents}
          />
        ) : (
          <p>Chargement des données...</p>
        )}
      </div>
      {currentPageData && currentPageData.length > 0 && (
        <TablePaginators
          paginationIndex={paginationIndex}
          setPaginationIndex={handlePageChange}
          dataLength={filteredData.length}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onActionSelected={handleActionSelected}
          isViewingArchived={viewArchived}
        />
      )}
    </div>
  );
};

const ajoutFou = {
  fontFamily: "Montserrat",
  fontWeight: "600",
  fontSize: "13px",
};
export default SaisieManuelle;
