import React from "react";
import "../ConnexionForm/forme.css";
import allIcon from '../../image/allIcon.png'

const PageAfterRegister = () => {

  return (
    <>
     <div className="container-fluid">
     
      <div className="row  auth-wrapper">
     
      <div className="col-12 col-md-6 col-lg-6 auth-main-col text-center ">
          <div className="d-flex flex-column h-100 side">
          <div className="vh-100 d-flex justify-content-center align-items-center ">
            <div>
                <div className="mb-4 text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" style={{color:"#009688"}} className="bi bi-check-circle-fill" width="75" height="75"
                        fill="currentColor" viewBox="0 0 16 16">
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                </div>
                <div className="text-centers">
                    <h1>Merci ! </h1>
                    <p>Nous avons envoyé le lien dans votre boîte de réception.<br/> Veuillez confirmer votre mail pour vous connecter </p>
                    {/* <button className="btn btn-primary">Retour</button> */}
                </div>
            </div>
          </div>
          </div>
        </div>
        <div className="col-12 col-md-5 col-lg-6  auth-background-col">
        <div className="auth-background-holder">
          <div className="allIconImage">
             <img src={allIcon} className="img"/>
          </div>
          <div className="text-bas ">
            <h6>Easy compta</h6>
           <p>Easy Compta simplifie la gestion financière, vous permettant de suivre vos revenus et dépenses, générer des rapports détaillés et rester toujours en contrôle de votre comptabilité.</p>
          </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default PageAfterRegister;
