import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

const inscriptionSchema = yup
  .object()
  .shape({
    first_name: yup.string(),
    last_name: yup.string(),
    phone: yup.number().typeError('Ajouter un numero de téléphone valide '),
    adresse: yup.string(),
    email: yup.string().required('email obligatoire'),
    password: yup.string().required('le mot de passe est obligatoire')
  })
  .required();
  
const InscriptionProvider = ({children}) => {
  const methods = useForm({
    values:{
        first_name: '',
        last_name: '',     
        phone:'',
        adresse: '',
        email: '',
        password: ''
    },
    defaultValues:{
        first_name: '',
        last_name: '', 
        phone:'',
        adresse: '',
        email: '',
        password: ''
    },
    resolver:yupResolver(inscriptionSchema)
})
  return (
    <FormProvider {...methods}>{children}</FormProvider>
  )
}

export default InscriptionProvider