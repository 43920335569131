import React, { useEffect, useState } from "react";
import HeaderFirst from "utils/HeaderFirst";
import "./client.css";
import TabSwitcher from "./TabSwitcher";
import RoleCard from "./RoleCard";
import { useGetRolesQuery } from "actions/roles/queries";
import useCollaborateurData from "../Collaborateur/useCollaborateurData";
import { FiList, FiSearch } from "react-icons/fi";
import { HiOutlineSquares2X2 } from "react-icons/hi2";
import { BsFilter } from "react-icons/bs";
import Permissions from "./Permissions";
import { GoPlus } from "react-icons/go";
import ButtonP from "components/buttons/ButtonP";
import ButtonWhite from "components/buttons/ButtonWhite";
import AssignModal from "./AssignModal";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ADD_ROLE } from "routes/navigation/navigationPaths";

const RoleAndPermissionsPage = () => {
  const [currentTab, setCurrentTab] = useState("role");
  const [roles, setRoles] = useState([]);
  const [collaborateurs, setCollaborateurs] = useState([]);
  const {
    data: roleResponse,
    isLoading: isLoadingRole,
    error,
    refetch,
  } = useGetRolesQuery();
  const { updatedCollaborateurs } = useCollaborateurData();
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const methods = useForm();

  useEffect(() => {
    if (!isLoadingRole) {
      if (error) {
        console.error("Error fetching roles:", error);
      } else {
        refetch();
        console.log("API Response: ", roleResponse);
        setRoles(roleResponse);
      }
    }
    if (updatedCollaborateurs) {
      setCollaborateurs(updatedCollaborateurs);
    }
  }, [isLoadingRole, roleResponse, error, updatedCollaborateurs]);

  const filteredRoles = roles.filter(
    (role) =>
      role.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      role.description &&
      role.description.trim() !== ""
  );

  const navigate = useNavigate();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const nom = (
    <div style={{ display: "flex" }}>
      <h4 className="titre-dossier">Rôles et permissions</h4>
    </div>
  );
  const description = (
    <div>Voici tous les rôles et permissions de vos utilisateurs</div>
  );
  // getting collaborateurs
  const getCollaborateursByRole = (roleTitle) => {
    return collaborateurs.filter(
      (collaborateur) =>
        collaborateur.fonction && collaborateur.fonction.title === roleTitle
    );
  };

  const handleNavigateToNewRolePage = () => {
    navigate(ADD_ROLE);
  };

  console.log(collaborateurs);

  return (
    <div
      className="global"
      style={{
        backgroundColor: "white",
        minHeight: "500px",
        borderRadius: "20px",
        padding: "20px",
      }}>
      {isModalOpen && (
        <FormProvider {...methods}>
          <AssignModal isOpen={isModalOpen} onClose={closeModal} />
        </FormProvider>
      )}
      <div
        className="header pb-3"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
        }}>
        <HeaderFirst
          nom={nom}
          description={description}
          enterprise={"Cabinet"}
        />
        <ButtonWhite
          text={
            <div className="d-flex align-items-center gap-2">
              <GoPlus style={ajoutFou} />
              <span style={ajoutFou}>Assigner</span>
            </div>
          }
          type="button"
          onClick={openModal}
        />
        <ButtonP
          className="d-none"
          text={
            <div className="d-flex align-items-center gap-2">
              <GoPlus style={ajoutFou} />
              <span style={ajoutFou}>Nouveau rôle</span>
            </div>
          }
          type="button"
          onClick={handleNavigateToNewRolePage}
        />
      </div>
      <TabSwitcher onTabChange={setCurrentTab} />
      {currentTab === "role" && (
        <div className="row mx-3 p-0">
          <div className="card p-3 my-3 d-flex flex-row justify-content-between">
            <div className="d-flex align-items-center gap-2">
              <FiSearch className="fs-4" />
              <input
                type="text"
                className="border-0 search-Permissions"
                placeholder="Rechercher des rôles par titre, dossier ou tout autre mot-clé associé"
                style={{ width: "550px", fontFamily: "Montserrat" }}
                onChange={handleSearchChange}
              />
            </div>
            <div className="d-flex align-items-center gap-2">
              <button type="button" className="button-permission-search">
                <div className="d-flex align-items-center gap-2">
                  <BsFilter />
                  <span style={{ fontSize: "15px" }}>Filter</span>
                </div>
              </button>
              <button type="button" className="button-permission-search">
                <div className="d-flex align-items-center">
                  <FiList />
                </div>
              </button>
              <button
                type="button"
                className="button-permission-search bg-secondary-subtle">
                <div className="d-flex align-items-center text-secondary">
                  <HiOutlineSquares2X2 />
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="mt-3">
        {currentTab === "role" && (
          <div>
            <div className="row row-md-cols-2 row-cols-1 m-0 p-0">
              {filteredRoles.map((role, index) => (
                <div className="col-md-6" key={index}>
                  {role && (
                    <RoleCard
                      title={role?.title}
                      description={role?.description}
                      tags={role?.tags}
                      buttonColor={getButtonColor(role?.title)}
                      collaborateurs={getCollaborateursByRole(role?.title)}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {currentTab === "permissions" && <Permissions />}
      </div>
    </div>
  );
};

const ajoutFou = {
  fontFamily: "Montserrat",
  fontWeight: "600",
  fontSize: "13px",
};

const getButtonColor = (title) => {
  switch (title) {
    case "Associé":
      return "#00E1EF";
    case "Junior":
      return "#0078D4";
    case "Senior":
      return "#FDBE18";
    default:
      return "#ff0000";
  }
};

export default RoleAndPermissionsPage;
