import React from 'react';
import './client.css';

export default function CardForm({
  somme,
  explain,
  color,
  icon,
  colorIcon,
  onClick,
  monnaie,
}) {
  return (
    <div
      className="card-transac"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <div className="card">
        <div
          className="card-body justify-content-end text-end d-flex align-items-center gap-3"
          style={{
            fontFamily: 'Montserrat',
            borderRadius: '5.41px',
          }}
        >
          <div className="explain d-flex flex-column">
            {somme >0?(
              <span className={`${colorIcon} fw-bold`} >
              {
               new Intl.NumberFormat('fr-FR').format(somme)+monnaie
              }
            </span>
            ):(
              <span className="fw-bold" style={{color:"#991B1B"}}>
              {
               new Intl.NumberFormat('fr-FR').format(somme)
              }
            </span>
            )}
            
            <span style={{ fontSize: '13px' }}>{explain}</span>
          </div>
          <div className="iconCard d-flex">
            <div
              className={`bkgr ${color} d-flex align-items-center justify-content-center`}
            >
              <p className={`fs-4 p-3 mb-1 ${colorIcon}`}>{icon}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
