import { ApiMananger } from 'actions/rtkquery';
import {
  ADD_SALARIES_API_ROUTE,
  UPDATE_SALARIES_API_ROUTE,
  DELETE_SALARIES_API_ROUTE,
} from 'routes/api/endpoints';

const salarieApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    // Mutation for creating a new client instance
    createSalaries: build.mutation({
      query(body) {
        return {
          url: ADD_SALARIES_API_ROUTE,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['SALARIES'],
    }),

    // Mutation for updating an existing client instance
    updateSalaries: build.mutation({
      query: ({ id, ...updateData }) => ({
        url: `${UPDATE_SALARIES_API_ROUTE}${id}/`,
        method: 'PUT',
        body: updateData,
      }),
      invalidatesTags: ['SALARIES'], // Invalidate the cache tags to refetch client-related data
    }),

    // Mutation for deleting an existing client instance
    deleteSalaries: build.mutation({
      query: ({id}) => ({
        url: `${DELETE_SALARIES_API_ROUTE}${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SALARIES'], // Invalidate to refetch client-related data
    }),

    // More client-related mutations and queries can be added here...
  }),
  overrideExisting: false,
});

export const {
  useCreateSalariesMutation,
  useUpdateSalariesMutation,
  useDeleteSalariesMutation
} = salarieApi;
