import { ApiMananger } from 'actions/rtkquery';
import {
  ADD_COLLABORATEUR_API_ROUTE,
  UPDATE_COLLABORATEUR_API_ROUTE,
  DELETE_COLLABORATEUR_API_ROUTE,
} from 'routes/api/endpoints';

const collaborateurApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    // Mutation for creating a new collaborateur instance
    createCollaborateur: build.mutation({
      query(body) {
        return {
          url: ADD_COLLABORATEUR_API_ROUTE,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['COLLABORATEUR'],
    }),

    // Mutation for updating an existing collaborateur instance
    updateCollaborateur: build.mutation({
      query: ({ id, ...updateData }) => ({
        url: `${UPDATE_COLLABORATEUR_API_ROUTE}${id}/`,
        method: 'PUT',
        body: updateData,
      }),
      invalidatesTags: ['COLLABORATEUR'], // Invalidate the cache tags to refetch collaborateur-related data
    }),

    // Mutation for deleting an existing collaborateur instance
    deleteCollaborateur: build.mutation({
      query: ({id}) => ({
        url: `${DELETE_COLLABORATEUR_API_ROUTE}${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['COLLABORATEUR'], // Invalidate to refetch collaborateur-related data
    }),

    // More collaborateur-related mutations and queries can be added here...
  }),
  overrideExisting: false,
});

export const {
  useCreateCollaborateurMutation,
  useUpdateCollaborateurMutation,
  useDeleteCollaborateurMutation,
} = collaborateurApi;
