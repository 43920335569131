import { ApiMananger } from 'actions/rtkquery';
import {
  GET_ALL_TRANSACTIONS_API_ROUTE,
  GET_ONE_TRANSACTIONS_API_ROUTE,
} from 'routes/api/endpoints';

const transactionApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getTransactions: build.query({
      query: ({ dossier_id }) =>
        `${GET_ALL_TRANSACTIONS_API_ROUTE}${dossier_id}/`,
      providesTags: ['Transactions'],
    }),

    getTransactionId: build.query({
      query: ({ dossier_id, type, id }) =>
        `${GET_ONE_TRANSACTIONS_API_ROUTE}${dossier_id}/${type}/${id}/`,
      providesTags: ['Transactions'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetTransactionsQuery, useGetTransactionIdQuery } =
  transactionApi;
