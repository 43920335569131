/* eslint-disable react/react-in-jsx-scope */
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { FORGETPASSWORD, LOGIN_PATH, PUBLIC_PATH, REGISTER_PATH,AFTERREGISTER,  } from "../navigationPaths";
import WithoutAuth from "layouts/WithoutAuth";
// import { Navigate } from "react-router-dom";
import ConnexionPage from "pages/Connexion/ConnexionPage";
import InscriptionPage from "pages/Inscription/InscriptionPage";
import ErrorPasswordPage from "pages/ErrorPasswordPage/ErrorPasswordPage";
import { Activation } from "components/ActivationForm/Activation";
import PageAfterRegister from "components/ActivationForm/pageAfterRegister";


export const publicRoutes = [
    {
        path: PUBLIC_PATH,
        errorElement: <ErrorBoundary />,
        element: <WithoutAuth />,
        children: [
            // {
            //     path: "*",
            //     element: <Navigate to={PUBLIC_PATH} />
            // },
            {
                path: "",
                element: <ConnexionPage />,
            },
            {
                path: REGISTER_PATH,
                element: <InscriptionPage />,
            },
            {
                path: LOGIN_PATH,
                element: <ConnexionPage />,
            },
            {
                path: FORGETPASSWORD,
                element: <ErrorPasswordPage />
            },
            {
                path: "activation",
                element: <Activation />
            },
            {
                path: AFTERREGISTER,
                element: <PageAfterRegister />
            }
           
        ]
    },
];