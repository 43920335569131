import { ApiMananger } from 'actions/rtkquery';
import {
  ADD_DEPENSES_API_ROUTE,
  UPDATE_DEPENSES_API_ROUTE,
  DELETE_DEPENSES_API_ROUTE,
} from 'routes/api/endpoints';

const depenseApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    // Mutation for adding a new Depense instance
    addDepenses: build.mutation({
      query: (depenseData) => ({
        url: ADD_DEPENSES_API_ROUTE,
        method: 'POST',
        body: depenseData,
      }),
      invalidatesTags: ['DEPENSES'],
    }),

    // Mutation for updating an existing Depense instance
    updateDepense: build.mutation({
      query: ({ id, ...updateData }) => ({
        url: `${UPDATE_DEPENSES_API_ROUTE}${id}/`, // Ensure this route points to your specific 'depenses/{id}/' endpoint
        method: 'PUT', // Use 'PATCH' if you're partially updating the resource
        body: updateData,
      }),
      invalidatesTags: ['DEPENSES'], // Invalidate the cache tags to refetch depense-related data
    }),

    // Mutation for deleting an existing Depense instance
    deleteDepense: build.mutation({
      query: (id) => ({
        url: `${DELETE_DEPENSES_API_ROUTE}${id}/`, // Construct the URL with the depense ID
        method: 'DELETE',
      }),
      invalidatesTags: ['DEPENSES'], // Invalidate the cache tags to refetch depense-related data
    }),

    // You can add more depense-related mutations and queries here if necessary...
  }),
  overrideExisting: false,
});

export const {
  useAddDepensesMutation,
  useUpdateDepenseMutation,
  useDeleteDepenseMutation,
} = depenseApi;
