import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useGetFactureByDossierClientIdQuery } from 'actions/facture_comp/queries';
import { useDeleteFactureMutation, useUpdateFactureMutation } from 'actions/facture_comp/mutations';

const useFactureData = (dossier_id) => {
  const {
    data: factureResponse,
    isLoading: isLoadingFacture,
    refetch,
  } = useGetFactureByDossierClientIdQuery(dossier_id)

  const [updateFacture] = useUpdateFactureMutation();
  const [deleteFacture] = useDeleteFactureMutation();

  const [updatedFacture, setUpdatedFacture] = useState([]);

  useEffect(() => {
    if (
      !isLoadingFacture &&
      factureResponse?.data &&
      Array.isArray(factureResponse.data)
    ) {
      const enhancedFacture = factureResponse.data.map((facture) => ({
        ...facture,
        displayName: `${facture.name} (${facture.id})`,
      }));
      setUpdatedFacture(enhancedFacture);
    }
    refetch()
  }, [isLoadingFacture, factureResponse]);

  const handleUpdateFacture = async (factureId, updateData) => {
    try {
      await updateFacture({
        id: factureId,
        ...updateData,
      }).unwrap();
      refetch(); // Refetch client data from the backend
    } catch (error) {
      toast.error(`Update error: ${error.data?.error || error.message}`);
      console.error('Request failed:', error);
    }
  };

  const handleDeleteFacture = async (factureId) => {
    try {
      await deleteFacture({ id: factureId }).unwrap();
      toast.success('Facture deleted successfully');
      refetch();
    } catch (error) {
      console.log(factureId)
      toast.error(`Deletion error: ${error.data?.error || error.message}`);
    }
  };

  return { updatedFacture, handleUpdateFacture, handleDeleteFacture, refetch };
};

export default useFactureData;
