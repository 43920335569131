import ConnexionForm from 'components/ConnexionForm/ConnexionForm'
import ConnexionProvider from 'components/ConnexionForm/ConnexionProvider'
import React from 'react'

const ConnexionPage = () => {
  return (
    <ConnexionProvider defaultValues={null}>
        <ConnexionForm />
    </ConnexionProvider>
  )
}

export default ConnexionPage