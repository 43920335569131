import React, { useState } from "react";
import "./client.css";
import { toast } from "react-toastify";
import { useGetRolesQuery } from "actions/roles/queries";
import useCollaborateurData from "../Collaborateur/useCollaborateurData";
import ButtonP from "components/buttons/ButtonP";
import { BiBriefcase } from "react-icons/bi";
import { FiHelpCircle, FiSearch } from "react-icons/fi";
import ButtonWhite from "components/buttons/ButtonWhite";

const AssignModal = ({ isOpen, onClose }) => {
  const { data: roles = [] } = useGetRolesQuery();
  const { updatedCollaborateurs, handleUpdateCollaborateur, refetch } =
    useCollaborateurData();
  const [selectedRoleId, setSelectedRoleId] = useState();
  const [selectedCollaborateur, setSelectedCollaborateur] = useState(null);

  const getInitials = (firstName, lastName) => {
    const firstNameInitial = firstName ? firstName[0] : "";
    const lastNameInitial = lastName ? lastName[0] : "";
    return firstNameInitial + lastNameInitial;
  };

  console.log(roles);

  const profileStyle = {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    backgroundColor: "#f0f0f0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#666666",
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (selectedCollaborateur && selectedRoleId) {
      const selectedRole = selectedRoleId;
      console.log(selectedRole);
      if (!selectedRole) {
        toast.error("Role not found.");
        return;
      }

      const updatedCollaborateur = {
        fonction: {
          id: selectedRole,
        },
      };

      try {
        await handleUpdateCollaborateur(
          selectedCollaborateur.id,
          updatedCollaborateur
        );
        toast.success("Rôle assigné avec succès !");
        refetch(); // Refresh the collaborateurs list
        onClose(); // Close the modal
      } catch (error) {
        console.log(error);
        toast.error("Échec de l'attribution du rôle.");
      }
    } else {
      toast.error("Sélectionnez un rôle et un collaborateur.");
    }
  };
  const handleRoleChange = (e) => {
    setSelectedRoleId(e.target.value);
    console.log(e.target.value);
  };
  console.log(selectedRoleId);

  const handleCollaborateurSelect = (collaborateur) => {
    setSelectedCollaborateur(collaborateur);
  };

  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div
            className="modal-content bg-white"
            onClick={(e) => e.stopPropagation()}
            style={{ borderRadius: "8px" }}>
            <div className="header d-flex flex-column gap-4">
              <div className="header-icon">
                <BiBriefcase style={{ color: "#009688" }} />
              </div>
              <h2 className="modal-header">Attribuer ce rôle</h2>
            </div>
            <form
              className="assign-role-form"
              autoComplete="off"
              onSubmit={onSubmit}>
              <div className="form-group">
                <label
                  htmlFor="role"
                  className="form-label d-inline"
                  style={{ color: "#667085", fontSize: "14px", fontWeight: 400 }}>
                  Sélectionnez un ou plusieurs utilisateurs à affecter à ce rôle
                </label>
                <select
                  id="role"
                  className="border-0 outline-none d-inline"
                  onChange={handleRoleChange}
                  value={selectedRoleId}
                  style={{ fontSize: "16px", color: "#068F7A", fontWeight: 700 }}>
                  {roles
                    .filter(
                      (role) =>
                        role.description && role.description.trim() !== ""
                    )
                    .map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.title}
                      </option>
                    ))}
                </select>
              </div>
              <div className="card d-flex align-items-center flex-row px-2">
                <FiSearch className="fs-4" style={{ color: "#667085" }} />
                <input
                  className="form-controll search-Permissions border-0"
                  type="text"
                  id="collaborateurs"
                  placeholder="Rechercher un individu ou une équipe"
                // Add search functionality here if needed
                />
                <FiHelpCircle className="fs-4" style={{ color: "#98A2B3" }} />
              </div>

              <div className="collaborateurs-list">
                {updatedCollaborateurs.map((collaborateur) => (
                  <div
                    key={collaborateur.id}
                    className={`collaborateur-item mb-2 ${selectedCollaborateur?.id === collaborateur.id
                      ? "selected"
                      : ""
                      }`}
                    onClick={() => handleCollaborateurSelect(collaborateur)}>
                    <div className="collaborateur-info card-body">
                      <div style={{ ...profileStyle }}>
                        {collaborateur.photo ? (
                          <img
                            src={collaborateur.photo}
                            alt={`${collaborateur.nom}`}
                          />
                        ) : (
                          <div>
                            {getInitials(
                              collaborateur.first_name,
                              collaborateur.last_name
                            )}
                          </div>
                        )}
                      </div>
                      <div style={{ fontSize: "15px" }}>
                        <p>
                          {collaborateur.first_name} {collaborateur.last_name}
                        </p>
                        <p>{collaborateur?.fonction.title}</p>
                      </div>
                    </div>
                    {selectedCollaborateur?.id === collaborateur.id && (
                      <span className="checkmark">✔</span>
                    )}
                  </div>
                ))}
              </div>
              <div className="w-100 mt-3">
                <ButtonP text={"Assigner"} type={"submit"} className="w-100" />
              </div>
              <div className="modal-footer w-100">
                <div className="col">
                  <ButtonWhite
                    text={"Annuler"}
                    onClick={onClose}
                    className="w-100"
                  />
                </div>
                <div className="col">
                  <ButtonP
                    text={"Créer rôle "}
                    type={"button"}
                    className="w-100"
                    onClick={() => {
                      // Add functionality to create a new role
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AssignModal;
