import { ApiMananger } from "actions/rtkquery";
import { GET_COLLABORATEUR_API_ROUTE,GET_EXPERTCOMPTABLE_DOSSIERS_API_ROUTE} from "routes/api/endpoints";

const collaborateurApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({

    getCollaborateur: build.query({
        query: () => `${GET_COLLABORATEUR_API_ROUTE}`,
        providesTags: ["INVALIDATE_COLLABORATEUR"],
    }),
    getCollaborateurId: build.query({
        query: ({ id }) => `${GET_COLLABORATEUR_API_ROUTE}?id=${id}`,
        providesTags: ["INVALIDATE_COLLABORATEUR"],
    }),
    getExpertDossiers: build.query({
        query: (id) => `${GET_EXPERTCOMPTABLE_DOSSIERS_API_ROUTE}/${id}/`,
        providesTags: ["ExpertDossiers"], // Use a specific tag for expert dossiers
    }),
    
}),
});

export const {
useGetCollaborateurQuery,
useGetCollaborateurIdQuery,
useGetExpertDossiersQuery
} = collaborateurApi;
