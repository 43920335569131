import React, {cloneElement} from "react";
import SidebarItem from "../../components/SidebarItem";
import ResourceDataMap from "../../components/ResourceDataMap";
import {linksCabinet_dossiers} from "routes/navigation/sidebarLinks";
import PropTypes from "prop-types";
import logo from "../../image/logo.png";
import useGetCurrentUser from "hooks/useGetCurrentUser";
import Ellipse from "../../image/Ellipse.png";
import "../sidebar/style.css";
import {useParams} from "react-router-dom";

const SidebarCabinetDossierComponent = ({
  activeLinkColor,
  activeLinkBgColor,
  colorOnHover,
  linkColor,
  color,
  openedMenu,
}) => {
  const {clientID: dossier_id} = useParams();
  const ClonedSidebarItem = ({linkItem}) =>
    cloneElement(
      <SidebarItem
        linkItem={linkItem}
        openedMenu={openedMenu}
        inDossier={true}
        dossier={dossier_id}
      />,
      {
        activeLinkColor,
        activeLinkBgColor,
        colorOnHover,
        linkColor,
        color,
      }
    );

  const user = useGetCurrentUser();
  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100%",
        padding: "25px 0px",
        borderRight: "1px solid rgba(0,0,0,0.1)",
        overflowY: "scroll",
      }}
      className="hideScrollbar">
      <div
        className=""
        style={{marginBottom: "25px", width: "100%", padding: "20px"}}>
        <div style={{marginBottom: "50px"}}>
          <img
            src={logo}
            alt="Logo"
            style={{width: "60%"}} // Ajout de marginRight pour l'espacement
          />
        </div>
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
            gap: "5%",
            padding: "5% 7%",
            width: "95%",
            borderRadius: "35px",
            backgroundColor: "rgba(241, 241, 241, 0.624)",
          }}>
          <div
            style={{
              width: "35px",
              height: "35px",
              borderRadius: "50%",
              backgroundColor: "#000",
            }}>
            <img
              src={Ellipse}
              alt="user"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          </div>
          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{fontSize: "13px", letterSpacing: "2px"}}>
              {user?.first_name} {user?.last_name}
            </div>
            <div
              style={{
                fontSize: "13px",
                letterSpacing: "2px",
                fontWeight: "bold",
              }}>
              Manager
            </div>
          </div>
        </div>
        <div style={{}}>
          <input
            type="text"
            placeholder="Recherche"
            style={{
              padding: "10px",
              width: "100%",
              borderRadius: "10px",
              border: "none",
              backgroundColor: "rgba(241, 241, 241, 0.624)",
            }}
          />
        </div>
      </div>
      <p
        style={{
          marginLeft: "25px",
          fontSize: "20px",
          fontFamily: "Roboto",
          fontWeight: "400",
          padding: "0 10px",
        }}>
        APERÇU
      </p>

      <ResourceDataMap
        resourceData={linksCabinet_dossiers}
        resourceItem={ClonedSidebarItem}
        resourceName="linkItem"
      />
    </div>
  );
};

// Add PropTypes validation for the props
SidebarCabinetDossierComponent.propTypes = {
  bgColor: PropTypes.string,
  activeLinkColor: PropTypes.string.isRequired,
  activeLinkBgColor: PropTypes.string.isRequired,
  colorOnHover: PropTypes.string.isRequired,
  linkColor: PropTypes.string.isRequired,
  color: PropTypes.string,
  openedMenu: PropTypes.bool.isRequired,
};

export default SidebarCabinetDossierComponent;
