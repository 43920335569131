import React from 'react';
import CustomBootstrapTable from 'utils/reactTable';
import { FiPaperclip } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/features/userSliceWithTokenValidation';

const DepensesTable = ({ data, handleRowClick, selectedRows, onSelectRow }) => {
  const userStatus = useSelector(getUser);
  const rowEvents = {
    onClick: (e, row) => {
      console.log('Table row clicked:', row); // Check if this logs
      handleRowClick(row);
    },
  };
  const sortedData = React.useMemo(() => {
    return data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
  }, [data]);
  const columns = [
    {
      dataField: 'id',
      text: 'N°',
      formatter: (cell, row, rowIndex) => (
        <div onClick={() => handleRowClick(row)}>{rowIndex + 1}</div>
      ),
    },
    {
      dataField: 'fournisseurs_details',
      text: 'Fournisseur',
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell?.nom}</div>
      ),
    },
    {
      // dataField: 'dossier_id',
      text: 'Fait par',
      formatter: (cell, row) => (
        <div className="styleColonne" onClick={() => handleRowClick(row)}>
          {userStatus?.first_name}
        </div>
      ),
    },
    {
      dataField: 'updated_at',
      text: 'Date',
      formatter: (cell, row) => {
        // Parse the date string into a Date object
        const date = new Date(cell);

        // Format the date and time parts
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        // Combine the parts into the desired format
        const formattedDate = `${day}/${month}/${year} à ${hours}:${minutes}`;

        // Render the formatted date string
        return <div onClick={() => handleRowClick(row)}>{formattedDate}</div>;
      },
    },
    {
      dataField: 'archived',
      text: 'Statut',
      formatter: (cell, row) => {
        // Check if the row is archived and apply red color to the "Archivé" text
        const statusText = row.archived ? (
          <span style={{ color: 'red' }}>Archivé</span>
        ) : (
          'Activé'
        );
        return <div onClick={() => handleRowClick(row)}>{statusText}</div>;
      },
    },
    {
      dataField: 'prix',
      text: 'Prix',
      formatter: (cell, row) => {
        const Price = row?.produits_associes?.reduce(
          (sum, prod) => sum + parseFloat(prod.prix || 0),
          0
        );
        return (
          <div onClick={() => handleRowClick(row)}>{Price?.toFixed(2)}</div>
        );
      },
    },
    {
      dataField: 'quantite',
      text: 'Qte',
      formatter: (cell, row) => {
        const Quantity = row?.produits_associes?.reduce(
          (sum, prod) => sum + (parseInt(prod.quantite, 10) || 0),
          0
        );
        return <div onClick={() => handleRowClick(row)}>{Quantity}</div>;
      },
    },

    {
      dataField: 'taxes',
      text: 'Taxes',
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell}</div>
      ),
    },
    {
      dataField: 'piece_joint',
      text: <FiPaperclip />,
      formatter: (cell, row) => (
        <div className="styleColonne" onClick={() => handleRowClick(row)}>
          {cell ? (
            <a href={cell} target="_blank" rel="noopener noreferrer">
              Pièce jointe
            </a>
          ) : (
            <span>Pas pièce jointe</span>
          )}
        </div>
      ),
    },
    {
      dataField: 'montant_total',
      text: 'Total',
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell} Fcfa</div>
      ),
    },
  ];

  return (
    <CustomBootstrapTable
      keyField="id"
      data={sortedData}
      columns={columns}
      rowEvents={rowEvents}
      selectedRows={selectedRows} // Forward the prop
      onSelectRow={onSelectRow} // Forward the prop
    />
  );
};

export default DepensesTable;
