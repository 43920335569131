import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useGetRolesQuery} from "actions/roles/queries";
import {useGetPermissionsQuery} from "actions/Permissions/queries";
import {useUpdatePermissionStatusMutation} from "actions/Permissions/mutations";
import ButtonP from "components/buttons/ButtonP";
import ButtonWhite from "components/buttons/ButtonWhite";
import HeaderFirst from "utils/HeaderFirst";
import {GoPlus} from "react-icons/go";
import AddPermissionModal from "./AddPermissionModal";

function AddRole() {
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [newPermission, setNewPermission] = useState({
    name: "",
    description: "",
  }); // Initialize newPermission with the required structure
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {data: roles} = useGetRolesQuery();
  const {data: rolePermissions, refetch} =
    useGetPermissionsQuery(selectedRoleId);
  const [updateRolePermissions] = useUpdatePermissionStatusMutation();

  useEffect(() => {
    if (roles && roles.length > 0 && !selectedRoleId) {
      const managerRole = roles.find((role) => role.title === "Associé");
      if (managerRole) {
        setSelectedRoleId(managerRole.id);
        refetch(managerRole.id);
      }
    }
  }, [roles, selectedRoleId, refetch]);

  console.log(roles);

  useEffect(() => {
    if (rolePermissions) {
      setPermissions(rolePermissions.permissions);
    }
  }, [rolePermissions]);

  const handleRoleChange = (e) => {
    const roleId = e.target.value;
    setSelectedRoleId(roleId);
    if (roleId) {
      refetch(roleId);
    }
  };
  const savePermissions = async () => {
    if (selectedRoleId) {
      try {
        // Ensure newPermission has valid name and description
        if (!newPermission.name || !newPermission.description) {
          throw new Error("Name and description are required.");
        }

        const payload = {
          id: selectedRoleId,
          updateData: newPermission,
        };

        await updateRolePermissions(payload).unwrap();
        toast.success("Permissions updated successfully!");
      } catch (error) {
        console.error("Error updating permissions:", error);
        toast.error("Failed to update permissions.");
      }
    } else {
      console.error("selectedRoleId is not set correctly:", selectedRoleId);
    }
  };

  const addPermission = (newPermissionKey, newPermissionDescription) => {
    setPermissions((prev) => [
      ...prev,
      {
        name: newPermissionKey,
        description: newPermissionDescription,
      },
    ]);
    setNewPermission({
      name: newPermissionKey,
      description: newPermissionDescription,
    });
    console.log(newPermissionKey, newPermissionDescription);
  };

  const nom = (
    <div style={{display: "flex"}}>
      <h4 className="titre-dossier">Nouveau Rôle</h4>
    </div>
  );
  const description = (
    <div>Créer un nouveau rôle en définissant les permissions </div>
  );

  return (
    <div
      className="global"
      style={{
        backgroundColor: "white",
        minHeight: "500px",
        borderRadius: "20px",
        padding: "20px",
        fontFamily: "Montserrat",
      }}>
      <div
        className="header pb-3"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
        }}>
        <HeaderFirst
          nom={nom}
          description={description}
          enterprise={"Rôles et permissions"}
        />
        <ButtonWhite
          text={
            <div className="d-flex align-items-center gap-2">
              <span style={ajoutFou}>Annuler</span>
            </div>
          }
          type="button"
        />
        <ButtonP
          text={
            <div className="d-flex align-items-center gap-2">
              <GoPlus style={ajoutFou} />
              <span style={ajoutFou}>Enregistrer</span>
            </div>
          }
          type="button"
        />
      </div>
      <div className="card row m-0 p-0 mx-3">
        <div className="card-header d-flex align-items-center justify-content-between px-5 py-4">
          <div className="d-flex align-items-start flex-column">
            <h3 style={{fontSize: "20px", fontWeight: 700}}>
              Définir les permissions
            </h3>
            <p
              className="m-0 p-0"
              style={{fontSize: "16px", fontWeight: 400, color: "#676E7E"}}>
              Modifier ce que les utilisateurs occupant ce rôle peuvent faire
            </p>
          </div>
          <div className="d-flex align-items-start flex-column gap-2">
            <label>Rôle</label>
            <select onChange={handleRoleChange} className="form-select">
              <option value="">Sélectionner un rôle</option>
              {roles
                .filter(
                  (role) => role.description && role.description.trim() !== ""
                )
                .map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.title}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="card-body p-4">
          {selectedRoleId && (
            <div className="card p-4">
              <h4 className="my-3">Gestion générale des accès</h4>
              <div className="d-flex m-0 p-0 row-md-cols--2 row">
                {permissions?.map((permission) => (
                  <div
                    key={permission.id}
                    className=" ms-0 ps-0 w-50 mb-3"
                    style={{
                      borderRadius: "10px",
                    }}>
                    <div
                      className="card p-4 m-1 d-flex flex-column align-items-start"
                      style={{height: "110px"}}>
                      <label>{permission.permission__name}</label>
                      <label style={{color: "#909DAD"}}>
                        {permission.permission__description}
                      </label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`permission-${permission.id}`}
                          checked={permission.isActive}
                          // onChange={() => handlePermissionToggle(permission.id)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`permission-${permission.id}`}>
                          Actif
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex flex-row gap-2">
                <ButtonP
                  text={"Créer une nouvelle permission"}
                  onClick={() => setIsModalOpen(true)}
                />
                <ButtonWhite text={"Sauvegarder"} onClick={savePermissions} />
              </div>
            </div>
          )}
        </div>
      </div>
      <AddPermissionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAddPermission={addPermission}
      />
    </div>
  );
}

const ajoutFou = {
  fontFamily: "Montserrat",
  fontWeight: "600",
  fontSize: "13px",
};

export default AddRole;
