import React from 'react';
import left from '../image/arrow-left.png';
//import { useSelector } from 'react-redux';
//import { getUser } from 'redux/features/userSliceWithTokenValidation';
//import { useGetDossierIdQuery } from 'actions/dossiers/queries';
import { useNavigate} from 'react-router-dom';

function HeaderFirst({ nom, description}) {
  const navigate = useNavigate();
  //const userStatus = useSelector(getUser);
  //const comptableId = userStatus?.id;
  //const { clientID: id } = useParams();
  //const { data } = useGetDossierIdQuery({ comptableId, id });
  //const nomEntreprise = data?.data?.[0]?.nom_entreprise || enterprise ||'Loading...';

  return (
    <div className="header-title" style={{ padding: '15px', flex: 1 }}>
      <div
        style={{ cursor: 'pointer', width: 'fit-content' }}
        onClick={() => navigate(-1)}
      >
        <img
          src={left}
          className="imageLeft"
          style={{ width: '10px', height: '14px' }}
        />
        <span style={cardStyle} className="mx-2">
          Back
        </span>
      </div>
      <div style={solde}>{nom}</div>
      <span style={text}>{description}</span>
    </div>
  );
}

const cardStyle = {
  color: '#009688',
  fontWeight: '700',
  fontSize: '15px',
  fontFamily: 'Montserrat',
};

const solde = {
  fontFamily: 'Montserrat',
  fontSize: '35px',
  fontWeight: '600',
  marginTop: '10px',
};

const text = {
  fontFamily: 'Montserrat',
  fontWeight: '400',
  fontSize: '13px',
};

export default HeaderFirst;
