import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderFirst from 'utils/HeaderFirst';
import { toast } from 'react-toastify';
import { useGetCodeJournalIdQuery } from 'actions/sage_clone/code_journaux/queries';
import { useUpdateCodeJournalMutation } from 'actions/sage_clone/code_journaux/mutations';
import CodeJournalDetailsForm from './CodeJournalDetailsForm';

function DetailCodeJournal() {
  const { clientID, id: codeId } = useParams();
  const navigate = useNavigate();
  console.log(clientID)

  const {
    data: codeJournalDetail,
  } = useGetCodeJournalIdQuery({
    id: codeId,
  })

  const [updateCodeJournal] = useUpdateCodeJournalMutation()

  const [formData, setFormData] = useState({
    code_journal: '',
    type_journal: '',
  });

  useEffect(() => {
    // Ensure we directly access the fournisseur data from the API response correctly
    if (codeJournalDetail) {
      setFormData(codeJournalDetail.data[0]);
    }
  }, [codeJournalDetail]);


  const saveChanges = async () => {
    try {
      await updateCodeJournal({
        id: codeId,
        ...formData,
      }).unwrap();
      toast.success(
        'Les détails du code journal ont été mis à jour avec succès.'
      );
      navigate(-1);
    } catch (error) {
      toast.error(
        'Une erreur est survenue lors de la mise à jour du code journal.'
      );
    }
  };


  return (
    <div
      className="global fournisseur-detail-container"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <HeaderFirst
        nom={
          <h4 className="titre-dossier">
            Détail Code Journal: {formData.type_journal || 'Inconnu'}
          </h4>
        }
        description={
          <div>
            Vous pouvez visualiser ou modifier les détails du code journal
            ici.
          </div>
        }
      />
      <CodeJournalDetailsForm
        formData={formData}
        setFormData={setFormData}
        saveChanges={saveChanges}
      />

    </div>
  );
}

export default DetailCodeJournal;
