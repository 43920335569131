import {ApiMananger} from "actions/rtkquery";
import {
  GET_LIVRE_JOURNAL_CABINET,
  GET_Grand_Livre_CABINET,
  GET_BALANCE_GENERAL_CABINET,
} from "routes/api/endpoints";

const generationEtatsApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getLivreJournal_Cabinet: build.query({
      query: ({dossier_id}) =>
        `${GET_LIVRE_JOURNAL_CABINET}?dossier_id=${dossier_id}`,
      providesTags: ["INVALIDATE_CLIENT"],
    }),
    getGrandLivre_Cabinet: build.query({
      query: ({dossier_id}) =>
        `${GET_Grand_Livre_CABINET}?dossier_id=${dossier_id}`,
      providesTags: ["INVALIDATE_CLIENT"],
    }),
    getBalance_Cabinet: build.query({
      query: ({dossier_id}) =>
        `${GET_BALANCE_GENERAL_CABINET}?dossier_id=${dossier_id}`,
      providesTags: ["INVALIDATE_CLIENT"],
    }),
  }),
});

export const {
  useGetBalance_CabinetQuery,
  useGetGrandLivre_CabinetQuery,
  useGetLivreJournal_CabinetQuery,
} = generationEtatsApi;
