import React from 'react'
import { Outlet } from 'react-router-dom'
import SpliTemplateScreenCabinet from 'layouts/SplitTemplates/SpliTemplateScreenCabinet'
import NavbarComponentCabinet from 'layouts/navbar/NavbarComponentCabinet'
import SidebarComponentCabinet from 'layouts/sidebar/SidebarComponentCabinet'


const DashboardAppShellCabinet = () => {
  return (
    <SpliTemplateScreenCabinet>
      <NavbarComponentCabinet />
        <SidebarComponentCabinet
          activeLinkColor={"#FFFFFF"}
          activeLinkBgColor={"#009688"}
          colorOnHover={"white"}
          linkColor="#4B5563"
          color="#4B5563"
        />
      <Outlet 
      
      />
    </SpliTemplateScreenCabinet>
  )
}

export default DashboardAppShellCabinet