// Product.js
import React from 'react';
import InputDetail from 'components/Inputs/InputDetail';

const Product = ({
  id,
  index,
  nom,
  quantite,
  prix,
  onQuantiteChange,
  onPrixChange,
}) => {
  return (
    <div
      className="row flex-row justify-content-between"
      style={{ marginBottom: '15px' }}
    >
      <div className="col-6">
        <InputDetail
          label="Produit"
          name={`nom-${id}`}
          value={nom}
          disabled
          className={'custom-input'}
        />
      </div>
      <div className="col-3">
        <InputDetail
          label="Quantité"
          name={`quantite-${id}`}
          type="number"
          value={quantite}
          onChange={(e) =>
            onQuantiteChange(index, parseFloat(e.target.value) || 0)
          }
          className={'custom-input'}
        />
      </div>
      <div className="col-3">
        <InputDetail
          label="Prix"
          name={`prix-${id}`}
          type="number"
          value={prix}
          onChange={(e) => onPrixChange(index, parseFloat(e.target.value) || 0)}
          className={'custom-input'}
        />
      </div>
    </div>
  );
};

export default Product;
