import ErrorPasswordForm from 'components/ErrorPassword/ErrorPasswordForm'
import ErrorPasswordProvider from 'components/ErrorPassword/ErrorPasswordProvider'
import React from 'react'

const ErrorPasswordPage = () => {
  return (
   <ErrorPasswordProvider defaultValues={null}>
      <ErrorPasswordForm />
   </ErrorPasswordProvider>
    
  )
}

export default ErrorPasswordPage