
import { ApiMananger } from "actions/rtkquery";
import { ADD_CLIENT_API_ROUTE, ADD_DOSSIER_CLIENT_API_ROUTE, ADD_FOURNISSEUR_API_ROUTE, DELETE_DOSSIER_CLIENT_API_ROUTE, UPDATE_DOSSIER_CLIENT_API_ROUTE } from "routes/api/endpoints";

const dossierApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({

        // DOSSIER CLIENT
        createDossier: build.mutation({
            query(body) {
                return {
                    url: ADD_DOSSIER_CLIENT_API_ROUTE,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["INVALIDATE_DOSSIER","INVALIDATE_FOURNISSEURS"],
        }),
        updateDossier: build.mutation({
           
            query({id, body, token}) {
                // console.log(id)
                return {
                    url: `${UPDATE_DOSSIER_CLIENT_API_ROUTE}${id}/`,
                    method: "PUT",
                    body,
                       headers: {
                        Authorization: `Bearer ${token}`,
                      },
                };
            },
            invalidatesTags: ["INVALIDATE_DOSSIER"],
        }),
        updateDossierClient: build.mutation({
           
            query({id, body, token}) {
                // console.log(id)
                return {
                    url: `${UPDATE_DOSSIER_CLIENT_API_ROUTE}${id}/`,
                    method: "PUT",
                    body,
                       headers: {
                        Authorization: `Bearer ${token}`,
                      },
                };
            },
            invalidatesTags: ["INVALIDATE_DOSSIER"],
        }),
        deleteDossier: build.mutation({
            query: ({ id, token }) => {
                return {
                    url: `${DELETE_DOSSIER_CLIENT_API_ROUTE}${id}/`,
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };
            },
            invalidatesTags: ["INVALIDATE_DOSSIER"],
        }),
        

        // CLIENT
        createClient: build.mutation({
            query(body) {
                return {
                    url: ADD_CLIENT_API_ROUTE,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["INVALIDATE_CLIENT"],
        }),

        // FOURNISSEUR
        createFournisseur: build.mutation({
            query(body) {
                return {
                    url: ADD_FOURNISSEUR_API_ROUTE,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["INVALIDATE_FOURNISSEUR"],
        }),

        //Get dossier client

    }),
});

export const {
    //dossier
    useCreateDossierMutation,
    useUpdateDossierMutation,
    useUpdateDossierClientMutation,
    useDeleteDossierMutation,
    //CLIENT
    useCreateClientMutation,
    //FOURNISSEUR
    useCreateFournisseurMutation,
} = dossierApi;