import {ApiMananger} from "actions/rtkquery";
import {UPDATE_USER_DATA} from "routes/api/endpoints";

const RolesApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    // Mutation for updating an existing collaborateur instance
    updateUser: build.mutation({
      query: ({id, updateData}) => ({
        url: `${UPDATE_USER_DATA}${id}/`,
        method: "PUT",
        body: updateData,
        // Ensure no Content-Type header is set
      }),
      invalidatesTags: ["ROLES"],
    }),
  }),
  overrideExisting: false,
});

export const {useUpdateUserMutation} = RolesApi;
