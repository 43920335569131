import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderFirst from 'utils/HeaderFirst';
import InputDetail from 'components/Inputs/InputDetail';
import { toast } from 'react-toastify';
import ButtonP from 'components/buttons/ButtonP';
/*import { useUpdateClientMutation } from 'actions/clients/mutations';
import { useGetClientIdQuery } from 'actions/clients/queries';
import { useGetTransactionsQuery } from 'actions/transactions/queries';
import { useGetProduitsQuery } from 'actions/produits/queries';
import TransactionDetails from './TransactionDetails';*/
import { useUpdateCollaborateurMutation } from 'actions/collaborateur/mutation';
import { useGetCollaborateurIdQuery } from 'actions/collaborateur/queries';

function CollaborateurDetails() {
  const { id: collaborateur } = useParams();
  const navigate = useNavigate();
  console.log(collaborateur);

  const {
    data: collaborateurData,
    isFetching,
    error,
  } = useGetCollaborateurIdQuery({ id: collaborateur });
  const [updateCollaborateur] = useUpdateCollaborateurMutation();

  const [collaborateurDetails, setCollaborateurDetails] = useState({
    first_name: '',
    adresse: '',
    email: '',
    phone: '',
  });

  useEffect(() => {
    if (collaborateurData) {
      setCollaborateurDetails(collaborateurData?.data[0]);
    }
  }, [collaborateurData,setCollaborateurDetails]);

  /*const {
    data: produitsData, // Fetch product details
  } = useGetProduitsQuery({ dossier_id: clientID });

  const {
    data: transactions,
    isFetching: transactionsFetching,
    error: transactionsError,
  } = useGetTransactionsQuery({
    dossier_id: clientID,
    id: client,
  });

  if (transactionsFetching) return <div>Chargement...</div>;
  if (transactionsError) return <div>Une erreur est survenue.</div>;
  */

  const saveChanges = async () => {
    try {
      await updateCollaborateur({
        id: collaborateur,
        ...collaborateurDetails,
      }).unwrap();
      toast.success('Collaborateur mis à jour avec succès.');
      navigate(-1);
    } catch (error) {
      toast.error(
        "Une erreur s'est produite lors de la mise à jour du collaborateur."
      );
    }
  };

  if (isFetching) return <div>Chargement...</div>;
  if (error) return <div>Une erreur s&#39;est produite.</div>;

  return (
    <div
      className="global client-detail-container"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <HeaderFirst
        nom={
          <h4 className="titre-dossier">
            Détails du collaborateur : {collaborateurDetails.first_name || 'Inconnu'}
          </h4>
        }
        description={
          <div>
            Vous pouvez ici visualiser ou modifier les détails du collaborateur.
          </div>
        }
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          saveChanges();
        }}
        className="d-flex flex-column gap-2"
        style={{ padding: '15px' }}
      >
        <div className="row">
          <div className="col-md-6">
            <InputDetail
              label="Nom"
              name="nom"
              value={collaborateurDetails.first_name}
              onChange={(e) =>
                setCollaborateurDetails({
                  ...collaborateurDetails,
                  first_name: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
          <div className="col-md-6">
            <InputDetail
              label="Adresse"
              name="adresse"
              value={collaborateurDetails.adresse}
              onChange={(e) =>
                setCollaborateurDetails({
                  ...collaborateurDetails,
                  adresse: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <InputDetail
              label="Email"
              name="email"
              value={collaborateurDetails.email}
              onChange={(e) =>
                setCollaborateurDetails({
                  ...collaborateurDetails,
                  email: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
          <div className="col-md-6">
            <InputDetail
              label="Téléphone"
              name="telephone"
              value={collaborateurDetails.phone}
              onChange={(e) =>
                setCollaborateurDetails({
                  ...collaborateurDetails,
                  phone: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
        </div>
        <div className="my-3">
          <ButtonP type="submit" text="Sauvegarder" />
        </div>
      </form>

    </div>
  );
}

export default CollaborateurDetails;
