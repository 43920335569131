import { useGetClientQuery } from 'actions/clients/queries';
import { useGetProduitsQuery } from 'actions/produits/queries';
import { useCreateVenteMutation } from 'actions/ventes/mutations';
import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import HeaderFirst from 'utils/HeaderFirst';
import FileUpload from 'components/AddDepenses/FileUpload';
import ProductForm from 'components/ProductForm/ProductForm';
import { useAddProduitMutation } from 'actions/produits/mutations';
import ProductModal from 'components/AddProducts/ProductModal';
import { useForm } from 'react-hook-form';

export default function AddVentesForm() {
  const { clientID: dossier_id } = useParams();
  const navigate = useNavigate();
  const [addVente] = useCreateVenteMutation();
  const { data: produitsData } = useGetProduitsQuery({ dossier_id });
  const { data: clientsData } = useGetClientQuery({
    dossier_id: dossier_id,
  });
  const [showProductModal, setShowProductModal] = useState(false);
  const productFormMethods = useForm(); // For handling product form
  const [createProduit, { isSuccess: isProductSuccess }] =
    useAddProduitMutation();

  const handleAddProduct = () => setShowProductModal(true);
  const handleCloseModal = () => setShowProductModal(false);

  const [vente, setVente] = useState({
    client: '',
    intitule: '',
    dossier_id: dossier_id,
    facturable_au_client: false,
    source_paiement: '',
    crm: '',
    taxes: 0,
    prix: 0,
    remise: 0,
    quantite: 1,
    description: '',
    produits_associes: [{ produit: null, prix: null, quantite: 1 }],
  });

  // Options for dropdowns
  const sourcePaiementOptions = {
    wave: 'Wave',
    orange_money: 'Orange Money',
    banque: 'Banque',
    caisse: 'Caisse',
  };

  const crmOptions = {
    oui: 'Oui',
    non: 'Non',
  };

  const taxesOptions = {
    0: '0%',
    10: '10%',
    18: '18%',
  };


  /*const taxes = {
    0: '0%',
    10: '10%',
    18: '18%',
  };*/

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Vérifiez si l'input est celui des taxes et si la valeur est supérieure à 100
    if (name === 'taxes' && parseFloat(value) > 100) {
      // Si la valeur dépasse 100, ajustez-la à 100
      setVente((prevState) => ({
        ...prevState,
        [name]: '100',
      }));
    } else {
      // Sinon, mettez simplement à jour l'état avec la nouvelle valeur
      setVente((prevState) => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const addProduct = () => {
    setVente((prevState) => ({
      ...prevState,
      produits_associes: [
        ...prevState.produits_associes,
        { produit: '', prix: '', quantite: 1 },
      ],
    }));
  };

  useEffect(() => {
    if (isProductSuccess) {
      setShowProductModal(false);
    }
  }, [isProductSuccess]);

  const removeProduct = (index) => {
    setVente((prevState) => ({
      ...prevState,
      produits_associes: prevState.produits_associes.filter(
        (_, i) => i !== index
      ),
    }));
  };

  // Handle file change
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  const handleProductChange = (index, event) => {
    const { name, value } = event.target;
    let updatedProduitsAssocies = [...vente.produits_associes];

    // Ensure the product exists in the array or create a new entry if needed
    if (!updatedProduitsAssocies[index]) {
      updatedProduitsAssocies[index] = { produit: '', prix: '', quantite: 1 };
    }

    // Update the specific product's property based on the input field name
    updatedProduitsAssocies[index][name] =
      name === 'produit' ? Number(value) : value;

    if (value === 'ajouterProduit') {
      handleAddProduct();
    }

    setVente({ ...vente, produits_associes: updatedProduitsAssocies });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    const requiredFields = ['client', 'intitule', 'description'];
    const areFieldsFilled = requiredFields.every(
      (field) => vente[field] && vente[field].toString().trim() !== ''
    );

    if (!areFieldsFilled || !vente.produits_associes.length) {
      toast.error('Tous les champs sont obligatoires.');
      return;
    }

    let dataPayload = {
      client: vente.client,
      intitule: vente.intitule,
      dossier_id: vente.dossier_id,
      facturable_au_client: vente.facturable_au_client,
      taxes: vente.taxes,
      description: vente.description,
      crm: vente.crm,
      source_paiement: vente.source_paiement,
      produits_associes: vente.produits_associes,
      prix: vente.prix,
      remise: vente.remise
      
    };

    if (selectedFile) {
      const formData = new FormData();
      console.log(formData);

      // Append scalar fields directly
      formData.append('dossier_id', vente.dossier_id);
      formData.append('client', vente.client);
      formData.append('intitule', vente.intitule);
      formData.append('facturable_au_client', vente.facturable_au_client);
      formData.append('taxes', vente.taxes);
      formData.append('description', vente.description);
      formData.append('crm', vente.crm);
      formData.append('source_paiement', vente.source_paiement);
      formData.append('prix', vente.prix);
      formData.append('remise', vente.remise);
      

      vente.produits_associes.forEach((produit, index) => {
        if (produit.produit && produit.prix !== undefined) {
          formData.append(
            `produits_associes[${index}]produit`,
            produit.produit
          );
          formData.append(
            `produits_associes[${index}]prix`,
            produit.prix.toString()
          );
          if (produit.quantite) {
            formData.append(
              `produits_associes[${index}]quantite`,
              produit.quantite.toString()
            );
          }
        } else {
          console.error(
            `Missing required product details at index ${index}`,
            produit
          );
        }
      });
      formData.append('piece_joint', selectedFile);

      try {
        await addVente(formData).unwrap();
        toast.success('Vente ajoutée avec succès');
        navigate(-1);
      } catch (error) {
        console.error('Request failed:', error);
        toast.error(`Error: ${error.data?.message}` || 'Failed to add vente');
      }
    } else {
      console.log(dataPayload);
      try {
        await addVente(dataPayload, {
          headers: { 'Content-Type': 'application/json' },
        }).unwrap();
        toast.success('Vente ajoutée avec succès');
        navigate(-1);
      } catch (error) {
        console.error('Request failed:', error);
        toast.error(
          `Erreur: ${error.data?.message || "Échec de l'ajout de la vente"}`
        );
      }
    }
  };

  // This useEffect is for calculate automatically the total price
  useEffect(() => {
    var somme=0
    const total = vente.produits_associes.map(produit => {
      somme += parseInt(produit.prix)*parseInt(produit.quantite)
      return somme
    })
   
  
    const montant_TTC = total[total.length - 1]*(1+((vente.taxes-vente.remise)/100))
    //montant_TTC *= 1- (parseInt(vente.remise)/100)
    console.log("Somme Total: ",montant_TTC)
    setVente({...vente, prix: montant_TTC})

  },[vente.produits_associes,vente.taxes,vente.remise])
  ///////////////////////////////////////////////////////////////////


  return (
    <div
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <HeaderFirst nom={<h4 className="titre-dossier">Ajouter une vente</h4>} />
      <div className="w-100 w-md-75 px-0 px-md-5 container-background">
        <Container fluid className="container-style p-4 mb-5">
          <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column align-items-center gap-2">
              {/* Client Selection */}
              <div className="w-100">
                <Row>
                  {/* Client Selection */}
                  <Col sm={6}>
                    <Form.Group controlId="formClient">
                      <Form.Label>Client</Form.Label>
                      <Form.Control
                        as="select"
                        name="client"
                        value={vente.client}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                      >
                        <option value="">- Sélectionnez un client -</option>
                        {clientsData?.data.filter(item => item.archived == false && item.active == true).map((client) => (
                          <option key={client.id} value={client.id}>
                            {client.nom}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  {/* Product Selection */}
                  <Col sm={6}>
                    <Form.Group controlId="formIntitule">
                      <Form.Label>Intitulé</Form.Label>
                      <Form.Control
                        type="text"
                        name="intitule"
                        value={vente.intitule}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              {/* Source Paiement, CRM, and Compte Comptable Selection */}
              <div className="w-100">
                <Row>
                  <Col sm={6}>
                    <Form.Group controlId="formSourcePaiement">
                      <Form.Label>Source de Paiement</Form.Label>
                      <Form.Control
                        as="select"
                        name="source_paiement"
                        value={vente.source_paiement}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                      >
                        <option value="">- Sélectionnez -</option>
                        {Object.entries(sourcePaiementOptions).map(
                          ([value, label]) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          )
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group controlId="formCrm">
                      <Form.Label>CRM</Form.Label>
                      <Form.Control
                        as="select"
                        name="crm"
                        value={vente.crm}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                      >
                        <option value="">- Sélectionnez -</option>
                        {Object.entries(crmOptions).map(([value, label]) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              {/* Additional Fields */}
              <div className="w-100">
                <Row>
                  <Col sm={6}>
                    <Form.Group controlId="formPrix">
                      <Form.Label>Prix</Form.Label>
                      <Form.Control
                        type="number"
                        name="prix"
                        value={vente.prix}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group controlId="formDescription">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        name="description"
                        value={vente.description}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div className="w-100">
                <Row>
                  <Col sm={6}>
                    <Form.Group controlId="formTaxes">
                      <Form.Label>Taxes (%)</Form.Label>
                      <Form.Control
                        as="select"
                        name="taxes"
                        value={vente.taxes}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                      >
                        <option value="">- Sélectionnez -</option>
                        {Object.entries(taxesOptions).map(([value, label]) => (
                          <option key={value} value={parseInt(value)}>
                            {label}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group controlId="formRemise">
                      <Form.Label>Remise (%)</Form.Label>
                      <Form.Control
                        type="number"
                        name="remise"
                        value={vente.remise}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div className="w-100">
                <Row>
                  <Col>
                    {vente.produits_associes.map((product, index) => (
                      <ProductForm
                        key={index}
                        product={product}
                        index={index}
                        handleProductChange={handleProductChange}
                        removeProduct={removeProduct}
                        productOptions={produitsData?.data}
                        totalProducts={vente.produits_associes?.length}
                      />
                    ))}
                    <Button
                      onClick={addProduct}
                      className="mt-3 rounded rounded-pill"
                      style={{
                        backgroundColor: '#068f7a',
                        borderColor: '#068f7a',
                      }}
                    >
                      Ajouter un produit
                    </Button>
                  </Col>
                </Row>
              </div>

              <div className="w-100">
                {/* File Upload */}
                <Row>
                  <Form.Group controlId="fileUploadControl" className="w-100">
                    <FileUpload onFileSelect={handleFileSelect} />
                  </Form.Group>
                </Row>
              </div>
              {/* Facturable au client Checkbox */}
              <div className="w-100">
                <Row>
                  <Col sm={12}>
                    <Form.Group controlId="formFacturableAuClient">
                      <Form.Check
                        type="checkbox"
                        label="Facturable au client"
                        name="facturable_au_client"
                        checked={vente.facturable_au_client}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              {/* Submit Button */}
              <Row className="mt-3">
                <Col sm={12} style={{ textAlign: 'right' }}>
                  <div className="d-flex gap-3">
                    <button
                      onClick={() => navigate(-1)}
                      className="btn btn-danger rounded rounded-pill px-4"
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary rounded rounded-pill px-4"
                      style={{
                        backgroundColor: '#068f7a',
                        borderColor: '#068f7a',
                      }}
                      disabled={
                        !vente.client ||
                        !vente.intitule ||
                        !vente.prix ||
                        !vente.description
                      } // Add other fields as necessary
                    >
                      Enregistrer
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        </Container>
        <ProductModal
          showModal={showProductModal}
          handleClose={handleCloseModal}
          formMethods={productFormMethods}
          creationProduit={createProduit}
          dossier_id={dossier_id}
          produits={produitsData?.data}
        />
      </div>
    </div>
  );
}
