import { useCreatePlanComptableMutation } from 'actions/plan_comptable/mutations';
import React, {  useState} from 'react'
import { Col, Container, Form, Row} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FileUpload from './File/FileUpload';
import excel_file from '../../image/excel.png'

import "./style.css"

export default function AddPlanComptable({dossier_id, setLoading, refetch, planTitle, setPlanTitle}) {
    
    const navigate = useNavigate()
    const [changePlan, setChangePlan] = useState(false)
    console.log("Le planTitle: ",planTitle)
    const [plan, setPlan] = useState({
        intitule: "",
        plageClients: "",
        plageFournisseurs: "",
        plageSalaries: "",
        dossier: dossier_id,
      });
      const [addPlan] = useCreatePlanComptableMutation()
  // Handle input changes
  const handleInputChange = (event) => {
    const {name, value, type, checked} = event.target;

    setPlan((prevState) => {
      if (type === "checkbox") {
        return {...prevState, [name]: checked};
      } else if (name == "intitule"||name == "plageClients"||name == "plageFournisseurs"||name == "plageSalaries") {
        const strValue = value ? value : "";
        return {...prevState, [name]: strValue};
      } else {
        return {...prevState, [name]: parseInt(value, 10)};
      }
    });
  };

  // fichier joint
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true)
    setPlanTitle(plan.intitule)
    // Verify all necessary fields are populated.
    const requiredFields = ["intitule","plageClients","plageFournisseurs","plageSalaries"];
    const areFieldsFilled = requiredFields.every(
      (field) => plan[field] && plan[field].toString().trim() !== ""
    );

    if (!areFieldsFilled) {
      toast.error("Tous les champs sont obligatoires.");
      return;
    }

    // Check for the presence of a file to be uploaded.
    if (selectedFile) {
      const formData = new FormData();

      // Append scalar fields directly
      formData.append("intitule", plan.intitule);
      formData.append("plageClients", plan.plageClients);
      formData.append("plageFournisseurs", plan.plageFournisseurs);
      formData.append("plageSalaries", plan.plageSalaries);
      formData.append("dossier", plan.dossier);
      formData.append("file", selectedFile);
      
      // Perform the POST request
      try {
        await addPlan(formData).unwrap();
        toast.success("plan ajoutée avec succès");
        console.log("Le title from old plan ajouté: ",plan.intitule)
        refetch()
        setLoading(false)
        setChangePlan(false)
      } catch (error) {
        console.error("Requête échouée:", error);
        toast.error(
          `Erreur: ${error.data?.message}` || "Echec d'ajout de dépense"
        );
        setLoading(false)
      }
    } else {
      // If there is no file, send JSON data directly.
        toast.error(
            ` Échec de l'ajout d'un plan`
        );
        setLoading(false)
    }
  };


  return (
    <div>
      <Container fluid className="container-style p-4 mb-5">
        {!changePlan && 
        <div style={{width:"100%",height:"40vh", display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
          <img src={excel_file} alt="excel file" style={{width:"120px",height:"100%",objectFit:"cover"}} />
          <h5 style={{marginTop:"-5vh"}}>Plan {planTitle}</h5>
          <button onClick={() => setChangePlan(true)} className='modifier'>Modifier le plan</button>
        </div>
        }

        {changePlan && 
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="d-flex flex-column align-items-center gap-5">
            <div className="w-100 d-flex flex-column gap-2">
                <Row className="row-gap-2 center">
                  <Col sm={6}>
                      <Form.Group controlId="formFournisseur">
                      <Form.Label className="mb-2">Titre*</Form.Label>
                      <Form.Control
                          type="text"
                          name="intitule"
                          value={plan.intitule}
                          onChange={handleInputChange}
                          className="rounded rounded-pill"
                      />
                      </Form.Group>
                  </Col>
                  <Col sm={6}>
                      <Form.Group controlId="formFournisseur">
                      <Form.Label className="mb-2">Plage des Comptes Clients*</Form.Label>
                      <Form.Control
                          type="text"
                          name="plageClients"
                          placeholder='Ex:4111-4198'
                          value={plan.plageClients}
                          onChange={handleInputChange}
                          className="rounded rounded-pill"
                      />
                      </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group controlId="formFournisseur">
                    <Form.Label className="mb-2">Plage des Comptes Fournisseurs*</Form.Label>
                    <Form.Control
                        type="text"
                        name="plageFournisseurs"
                        placeholder='Ex:4011-4098'
                        value={plan.plageFournisseurs}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                    />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group controlId="formFournisseur">
                    <Form.Label className="mb-2">Plage des Comptes Salariés*</Form.Label>
                    <Form.Control
                        type="text"
                        name="plageSalaries"
                        placeholder='Ex:4211-4287'
                        value={plan.plageSalaries}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                    />
                    </Form.Group>
                  </Col>
                {/*<Col sm={6} className='hidden'>
                    <Form.Group controlId="formIntitule">
                    <Form.Label className="mb-2">ID Dossier</Form.Label>
                    <Form.Control
                        type="number"
                        name="dossier"
                        value={plan.dossier}
                        onChange={handleInputChange}
                        disabled={true}
                        className="rounded rounded-pill "
                    />
                    </Form.Group>
                </Col>*/}
                </Row>
                <Row>
                <Form.Group
                    controlId="fileUploadControl"
                    className="w-100">
                    <FileUpload onFileSelect={handleFileSelect}  />
                </Form.Group>
                </Row>
                <Row className="mt-2">
                <Col sm={12} className="text-center">
                    <div className="d-flex gap-3">
                    <button
                        onClick={() => navigate(-1)}
                        className="btn btn-danger rounded rounded-pill px-4">
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary rounded rounded-pill px-4"
                        style={{
                        backgroundColor: "#068f7a",
                        borderColor: "#068f7a",
                        }}
                        disabled={
                        !plan.intitule
                        } // Add other fields as necessary
                    >
                        Enregistrer
                    </button>
                    </div>
                </Col>
                </Row>
            </div>
            </div>
            {/* ... Other input fields ... */}
        </Form>
        }
      </Container>
    </div>
  )
}
