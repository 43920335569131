import useGetAuthenticate from "hooks/useIsAuthenticatedUser";
import React from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ACCEUIL_PATH } from "routes/navigation/navigationPaths";

function WithoutAuth() {
    const isAuthenticated = useGetAuthenticate();

    const location = useLocation();

    return !isAuthenticated ? (
        <Outlet />
    ) : (
        <Navigate to={ACCEUIL_PATH} state={{ from: location }} replace />
    );
}

export default WithoutAuth;