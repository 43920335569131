// TransactionDetails.jsx
import React from 'react';
import CardDetails from '../CardDetails';
import PaimentDetail from '../PaimentDetail';

const TransactionDetails = ({
  soldeTotal,
  nbrTransactions,
  nbrEntree,
  nbrSortie,
  paymentDetails,
  onCardClick,
}) => (
  <div className="d-flex flex-column gap-5 mb-4">
    <CardDetails
      solde_total={soldeTotal}
      nbrTransactions={nbrTransactions}
      nbrEntree={nbrEntree}
      nbrSortie={nbrSortie}
    />
    <PaimentDetail
      totalCaisse={paymentDetails?.Caisse?.solde}
      totalBanque={paymentDetails?.Banque?.solde}
      totalWave={paymentDetails?.Wave?.solde}
      totalOrangeMoney={paymentDetails?.OrangeMoney?.solde}
      onCardClick={onCardClick}
    />
  </div>
);

export default TransactionDetails;
