import React, {useState,useEffect} from 'react'

import { useUpdateDossierClientMutation } from 'actions/dossiers/mutations';
import { useGetDossierByIdQuery } from 'actions/dossiers/queries';
import {  useParams, useNavigate } from 'react-router-dom';
import HeaderFirst from 'utils/HeaderFirst';
import { toast } from 'react-toastify';

import DetailForm from './DetailForm';
import AddPlanComptable from './AddPlanComptable';

import BarLoader from "react-spinners/BarLoader"
import { useGetCodesQuery } from 'actions/comptabilite/queries';

function Parametres_dossier() {
  const { clientID} = useParams();
  
  const navigate = useNavigate();

  const [loading,setLoading] = useState(false)

  const {data: codesResponse = [],refetch} = useGetCodesQuery({dossier_client:clientID});
  const [planTitle,setPlanTitle] = useState("Syscohada")


  useEffect(() => {
    refetch()
  },codesResponse)

  const {
    data: dossierDetail
  } = useGetDossierByIdQuery({id:clientID})


  const [updateDossier] = useUpdateDossierClientMutation()

  const [formData, setFormData] = useState({
    nom_entreprise: '',
    adresse: '',
    email: '',
    telephone: '',
  });

  useEffect(() => {
    // Ensure we directly access the fournisseur data from the API response correctly
    if (dossierDetail) {
      setFormData(dossierDetail);
    }
  }, [dossierDetail]);

  const saveChanges = async () => {
    try {
      await updateDossier({
        id: clientID,
        ...formData,
      }).unwrap();
      toast.success(
        'Les détails du code journal ont été mis à jour avec succès.'
      );
      navigate(-1);
    } catch (error) {
      toast.error(
        'Une erreur est survenue lors de la mise à jour du code journal.'
      );
    }
  };


  return (
    <>
    {loading?<div className="global fournisseur-detail-container"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
        opacity: '0.5',
        position: 'relative'
      }}>
        <div 
          style={{
            position: 'absolute',
            top: '50%',
            left: '45%'
          }}
        >
          <BarLoader />
        </div>
        
        <div
          className="global fournisseur-detail-container"
          style={{
            backgroundColor: 'white',
            minHeight: '500px',
            borderRadius: '20px',
            padding: '15px',
          }}
        >
        <HeaderFirst
          nom={
            <h4 className="titre-dossier">
              Détail du Dossier: 
            </h4>
          }
          description={
            <div>
              Vous pouvez visualiser ou modifier les détails de votre dossier
              ici.
            </div>
          }
        />
        <DetailForm
          formData={formData}
          setFormData={setFormData}
          saveChanges={saveChanges}
          
        />
        <hr />
        <h3>PLAN COMPTABLE</h3>
        <AddPlanComptable dossier_id={clientID} setLoading={setLoading} refetch={refetch}/>
      </div>
    </div>:    <div
      className="global fournisseur-detail-container"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <HeaderFirst
        nom={
          <h4 className="titre-dossier">
            Détail du Dossier: 
          </h4>
        }
        description={
          <div>
            Vous pouvez visualiser ou modifier les détails de votre dossier
            ici.
          </div>
        }
      />
      <DetailForm
        formData={formData}
        setFormData={setFormData}
        saveChanges={saveChanges}
      />
      <hr />
      <h3>PLAN COMPTABLE</h3>
      <AddPlanComptable 
        dossier_id={clientID} 
        setLoading={setLoading} 
        refetch={refetch} 
        planTitle={planTitle}
        setPlanTitle={setPlanTitle}
      />
    </div>}
    </>

  );
}

export default Parametres_dossier;
