import React, { cloneElement } from 'react';
import SidebarItem from '../../components/SidebarItem';
import ResourceDataMap from '../../components/ResourceDataMap';
import { links } from 'routes/navigation/sidebarLinks';
import PropTypes from 'prop-types';

const SidebarComponent = ({
  activeLinkColor,
  activeLinkBgColor,
  colorOnHover,
  linkColor,
  color,
  openedMenu,
}) => {
  const ClonedSidebarItem = ({ linkItem }) =>
    cloneElement(<SidebarItem linkItem={linkItem} openedMenu={openedMenu} inDossier={false}/>, {
      activeLinkColor,
      activeLinkBgColor,
      colorOnHover,
      linkColor,
      color,
    });

  return (
    <div
      style={{
        backgroundColor: 'white',
        height: '10%',
        padding: '25px 10px',
        borderRight: '1px solid rgba(0,0,0,0.1)',
      }}
    >
      <ResourceDataMap
        resourceData={links}
        resourceItem={ClonedSidebarItem}
        resourceName="linkItem"
      />
    </div>
  );
};

// Add PropTypes validation for the props
SidebarComponent.propTypes = {
  activeLinkColor: PropTypes.string.isRequired,
  activeLinkBgColor: PropTypes.string.isRequired,
  colorOnHover: PropTypes.string.isRequired,
  linkColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  openedMenu: PropTypes.bool.isRequired,
};

export default SidebarComponent;
