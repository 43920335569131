import { useEffect, useState } from 'react';
import {
  useUpdateFournisseurMutation,
  useDeleteFournisseurMutation,
} from 'actions/fournisseurs/mutations';
import { toast } from 'react-toastify';
import { useGetFournisseurQuery } from 'actions/fournisseurs/queries';

const useFournisseurData = (dossierId) => {
  const {
    data: fournisseurResponse,
    isLoading: isLoadingFournisseurs,
    refetch,
  } = useGetFournisseurQuery({ dossier_id: dossierId });

  const [updateFournisseur] = useUpdateFournisseurMutation();
  const [deleteFournisseur] = useDeleteFournisseurMutation();

  const [updatedFournisseurs, setUpdatedFournisseurs] = useState([]);

  useEffect(() => {
    if (
      !isLoadingFournisseurs &&
      fournisseurResponse?.data &&
      Array.isArray(fournisseurResponse.data)
    ) {
      refetch()
      const enhancedFournisseurs = fournisseurResponse.data.map(
        (fournisseur) => ({
          ...fournisseur,
          displayName: `${fournisseur?.nom} (${fournisseur?.id})`,
        })
      );
      setUpdatedFournisseurs(enhancedFournisseurs);
    }
  }, [isLoadingFournisseurs, fournisseurResponse]);

  const handleUpdateFournisseur = async (fournisseurId, updateData) => {
    try {
      await updateFournisseur({
        id: fournisseurId,
        ...updateData,
      }).unwrap();
      refetch(); // Refetch fournisseur data from the backend
    } catch (error) {
      toast.error(`Update error: ${error.data?.error || error.message}`);
      console.error('Request failed:', error);
    }
  };

  const handleDeleteFournisseur = async (fournisseurId) => {
    try {
      await deleteFournisseur({ id: fournisseurId }).unwrap();
      toast.success('Fournisseur deleted successfully');
      //console.log(fournisseurId)
      refetch();
    } catch (error) {
      toast.error(`Deletion error: ${error.data?.error || error.message}`);
    }
  };

  return {
    updatedFournisseurs,
    handleUpdateFournisseur,
    handleDeleteFournisseur,
    refetch,
  };
};

export default useFournisseurData;
