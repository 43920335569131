import React, {useState } from 'react';
import "../ForderPage/folder.css";
import dossierClient from "../../image/Group.png";
import { useGetDossierActivatedQuery } from 'actions/dossiers/queries';
import { getUser } from 'redux/features/userSliceWithTokenValidation';
import { useSelector } from 'react-redux';
import archive from "../../image/archive-restore 1.png";
import { ToastContainer, toast } from 'react-toastify';
import search from "../../image/search.png";
import { useUpdateDossierMutation } from 'actions/dossiers/mutations';
import GridList from 'react-flexible-list';
import SkeletonComponent from 'utils/skeleton/skeleton';

const DosierDesactivePage = () => {
  const [ updateDossier ] = useUpdateDossierMutation();
  const userStatus = useSelector(getUser);
  const comptableId = userStatus?.id;
  const { data, isLoading } = useGetDossierActivatedQuery({ comptableId, activated:'True' });

  const [searchTerm, setSearchTerm] = useState('');
 // Utilisez la fonction `filter` pour filtrer les dossiers en fonction du terme de recherche
 const filteredDossiers = data?.data?.filter((dossier) =>
 dossier?.nom_entreprise.toLowerCase().includes(searchTerm.toLowerCase())
);
const handleSearchChange = (newSearchTerm) => {
  setSearchTerm(newSearchTerm);
  // Mettez à jour la logique de recherche ici si nécessaire
};
// activer
const  handleActiveClick = async () => {
  if (selectedDossier !== null) {
    try {
      // Utilisez l'async/await pour attendre la résolution de la mutation
      const dossierId = selectedDossier;
       const res =   await  updateDossier({ id: dossierId, body:{activated:false}, token: userStatus?.access });
      toast.success('Dossier activé avec succès')
      console.log('response', res );
    } catch (error) {
      console.error('Échec de activation du dossier', error);
    }
  } else {
    console.error('ID du dossier nest pas valide :', selectedDossier);
  }
};

  const [selectedDossier, setSelectedDossier] = useState(null);
  const handleDossierClick = (dossierId) => {
    setSelectedDossier(dossierId);
    if ( setSelectedDossier(dossierId)) {
      // navigate(`/client/${dossierId}`);
    }
 
  };
  return (
    <div className="global" style={{ backgroundColor: "white", minHeight: "500px", borderRadius: '20px', padding: '15px' }}>
      <ToastContainer />
      <div
        className="header"
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          gap: "10px"
          // padding: "13px",
        }}
      >
        <div className="header-title" style={{ padding: "15px", flex: 1, display:"flex" }}>
          <h4 className='titre-dossier'>Dossiers désactivés</h4>
          <span className='nbrDossier'>{filteredDossiers?.length}</span>
        </div>
  
      </div>
      <div className='mt-4'>
      <div style={{ height: "50px" }}>
        <hr style={{ margin: "0 0" }}/>
        <div
        className="header"
        style={{
          display: "flex",
          alignItems: "center",
          
        }}
      >
        <div
          className="header-title"
          style={{
            padding: "15px",
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent:"space-between"
          }}
        >
          <button
            onClick={handleActiveClick}
            title="Activer"
            style={{
              marginRight: "25px",
              cursor: "pointer",
              border: "none",
              background: "none",
              padding: 0,
              ...((selectedDossier !== null) ? {} : { pointerEvents: "none", opacity: 0.3 }),
            }}
          >
            <img src={archive} alt="Archive" style={imgbutton}/>
          </button>
          <div style={{
            // flex: 1,
            display: "flex",
            alignItems: "center",
          }}>
        <img src={search} alt="Eyes" style={imgbutton}/>
          <input
            type="text"
            className="form-control"
            placeholder="Rechercher un client"
            style={{ border: "none", fontFamily: "Montserrat", fontWeight: '600', fontSize: "13px" }}
            value={searchTerm} // Correction ici
            onChange={(e) => handleSearchChange(e.target.value)}
            />
        </div>
        </div>
    

      </div>
       <hr style={{ margin: "0 0" }}/>
    </div>
    </div>
    <div className="mt-3">
      {
      isLoading ? (
          <GridList
          cardWidth={200}
          resourceData={ [1,2,3,4,5] }
          resourceItem={ () => <SkeletonComponent />
        }
        />
        ) : (
          <GridList
          cardWidth={150}
          resourceData={ filteredDossiers }
          resourceItem={ (dossier) => 
          <div className="d-flex flex-column align-items-center" key={dossier?.id} style={monDossierParent2} onClick={() => handleDossierClick(dossier?.id)}>
          <img src={dossierClient} alt="Dossier Client" 
          style={{
            ...monDossier,
            boxShadow: selectedDossier === dossier?.id ? '0px 8px 8px 8px #CCE8FF' : 'none',
            transform: selectedDossier === dossier?.id ? 'translateY(-4px)' : 'none',
          }}/>
          <p style={nomDossier}>{dossier?.nom_entreprise}</p>
        </div> 
        }
        />
        )}
      </div>
    </div>
  );
};

const monDossierParent2 = {
  marginRight: "25px",
  cursor: "pointer",
  border: "none",
  background: "none",
  padding: 10,
}
const monDossier = {
  width: '90px',
  height: '70px',
}
const nomDossier = {
  fontWeight: '600',
  fontFamily: 'Montserrat',
  fontSize: '11px',
  textAlign: 'center',
  marginTop: '10px',
  color: '#000000'
}
const imgbutton = {
  width:"18px", 
  height:"18px"
}



export default DosierDesactivePage