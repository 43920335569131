import { ApiMananger } from 'actions/rtkquery';
import {
  ADD_VENTE_API_ROUTE,
  DELETE_VENTE_API_ROUTE,
  UPDATE_VENTE_API_ROUTE,
} from 'routes/api/endpoints';

const venteApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    createVente: build.mutation({
      query(body) {
        return {
          url: ADD_VENTE_API_ROUTE,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['INVALIDATE_VENTE'],
    }),
    updateVente: build.mutation({
      query: ({ id, ...updateData }) => ({
        url: `${UPDATE_VENTE_API_ROUTE}${id}/`,
        method: 'PUT', // Use 'PATCH' if you're partially updating the resource
        body: updateData,
      }),
      invalidatesTags: ['INVALIDATE_VENTE'],
    }),
    deleteVente: build.mutation({
      query: (id) => ({
        url: `${DELETE_VENTE_API_ROUTE}${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['INVALIDATE_VENTE'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateVenteMutation,
  useUpdateVenteMutation,
  useDeleteVenteMutation,
} = venteApi;
