import React, {  useState} from 'react'
import { Col, Container, Form, Row} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FileUpload from './File/FileUpload';

import "./style.css"
import { useAddPieceJointeMutation } from 'actions/sage_clone/journaux/mutations';

export default function AddPieceJointe({dossier_id, journal_id}) {
    
    const navigate = useNavigate()

    const [addPieceJointe] = useAddPieceJointeMutation()


  // fichier joint
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Verify all necessary fields are populated.

    // Check for the presence of a file to be uploaded.
    if (selectedFile) {
      const formData = new FormData();

      // Append scalar fields directly
      formData.append("dossier", dossier_id);
      formData.append("journalId", journal_id);
      formData.append("file", selectedFile);
      
      // Perform the POST request
      try {
        await addPieceJointe(formData).unwrap();
        toast.success("Pièce jointe attachée avec succès");
      } catch (error) {
        console.error("Requête échouée:", error);
        toast.error(
          `Erreur: ${error.data?.message}` || "Echec d'ajout de dépense"
        );
      }
    } else {
      // If there is no file, send JSON data directly.
        toast.error(
            ` Échec de l'ajout d'un plan`
        );
    }
  };


  return (
    <div>
      <Container fluid className="container-style p-4 mb-5">
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="d-flex flex-column align-items-center gap-5">
            <div className="w-100 d-flex flex-column gap-2">
                <Row>
                <Form.Group
                    controlId="fileUploadControl"
                    className="w-100">
                    <FileUpload onFileSelect={handleFileSelect}  />
                </Form.Group>
                </Row>
                <Row className="mt-2">
                <Col sm={12} className="text-center">
                    <div className="d-flex gap-3">
                    <button
                        onClick={() => navigate(-1)}
                        className="btn btn-danger rounded rounded-pill px-4">
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary rounded rounded-pill px-4"
                        style={{
                        backgroundColor: "#068f7a",
                        borderColor: "#068f7a",
                        }}
                        disabled={
                        !selectedFile
                        } // Add other fields as necessary
                    >
                        Attacher
                    </button>
                    </div>
                </Col>
                </Row>
            </div>
            </div>
            {/* ... Other input fields ... */}
        </Form>
      </Container>
    </div>
  )
}
