// Modal.js
import React, {useState} from "react";
import "./Modal.css";
// import { useDispatch } from "react-redux";
import {toast} from "react-toastify";
//import ButtonP from 'components/buttons/ButtonP';
import { Col,  Form, Row} from "react-bootstrap";
import { useCreateLivreJournalMutation } from "actions/sage_clone/journaux/mutations";
import { useNavigate, useParams } from "react-router-dom";

const ModalJournal = ({
  isOpen,
  onClose,
  onDossierCreated,
  factures,
  codeJournalResponse,
  refetch,
  dossier_id,
}) => {
  const { clientID: id } = useParams();
  console.log("Factures avant insertion: ", factures);
  const [addLivreJournal] = useCreateLivreJournalMutation()
  const navigate = useNavigate()

  const [facture, setFacture] = useState({
    numero_facture: "",
    reference: "",
    livre_specific: 1,
    journal_cabinet: 1,
    dossier_client: dossier_id,

  });

  // Handle input changes
  const handleInputChange = (event) => {
    const {name, value, type, checked} = event.target;

    setFacture((prevState) => {
      if (type === "checkbox") {
        return {...prevState, [name]: checked};
      }
      else if (name == "date" ) {
        const strValue = value ? value : new Date();
        return {...prevState, [name]: strValue};
      } else if (name == "numero_facture" || name == "reference") {
        const strValue = value ? value : "";
        return {...prevState, [name]: strValue};
      } else if (name=="livre_specific") {
        
        const strValue = value ? value : "";
        return {...prevState, [name]: strValue};
      }else {
        return {...prevState, [name]: parseInt(value, 10)};
      }
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Verify all necessary fields are populated.
    const requiredFields = ["reference"];
    const areFieldsFilled = requiredFields.every(
      (field) => facture[field] && facture[field].toString().trim() !== ""
    );

    if (!areFieldsFilled ) {
      toast.error("Le champ référence est obligatoire.");
      return;
    }

    // Check for the presence of a file to be uploaded.
      const formData = new FormData();

      // Append scalar fields directly
      formData.append("reference", facture.reference);
      formData.append("dossier_client", facture.dossier_client);
      formData.append("livre_specific", facture.livre_specific);
      formData.append("journal_cabinet", facture.journal_cabinet);
      formData.append("numero_facture", facture.numero_facture);

      // Perform the POST request
      try {
       const livre_created =  await addLivreJournal({
          dossier_client: dossier_id, 
          livre_specific: facture.livre_specific,
          journal_cabinet: facture.journal_cabinet,
          reference: facture.reference,
          numero_facture: facture.numero_facture,
          formData: formData
        }).unwrap();
        refetch();
        const title = codeJournalResponse.filter((code) => code.id == facture.livre_specific)
        navigate("saisie/",{
                            state:{
                              journalId:livre_created.id,
                              codeJournalId:facture.livre_specific,
                              dossierId: id,
                              title: title[0].type_journal,
                            }
                          })
      } catch (error) {
        console.error("Requête échouée:", error);
        toast.error(
          `Erreur: ${error.data?.message}` || "Echec d'ajout de dépense"
        );
      }

  };

 


  return (
    <div style={{overflow: "scroll"}}>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
            <div
            className="modal-content bg-white"
            onClick={(e) => e.stopPropagation()}
            style={{ borderRadius: '20px' }}
            >
                <h2 className="modal-header">Ecritures comptables</h2>
                <hr />
               
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                  <div className="d-flex flex-column align-items-center gap-5">
                    <div className="w-100 d-flex flex-column gap-2">
                      <Row className="row-gap-2">
                        <Col sm={12}>
                          <Form.Group controlId="formFournisseur">
                            <Form.Label className="mb-2">Numero Facture</Form.Label>
                            <Form.Control
                              type="text"
                              name="numero_facture"
                              value={facture.numero_facture}
                              onChange={handleInputChange}
                              className="rounded rounded-pill"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <Form.Group controlId="formIntitule">
                            <Form.Label className="mb-2">Référence</Form.Label>
                            <Form.Control
                              type="text"
                              name="reference"
                              value={facture.reference}
                              onChange={handleInputChange}
                              className="rounded rounded-pill"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {/* ... Other input fields ... */}
                      <Row className="row-gap-2">
                        <Col sm={12}>
                          <Form.Group controlId="formSourcePaiement">
                            <Form.Label className="mb-2">Code Journal</Form.Label>
                            <Form.Control
                              as="select"
                              name="livre_specific"
                              value={facture.livre_specific}
                              onChange={handleInputChange}
                              className="rounded rounded-pill">
                                <option value={7} key={0} selected>Selectionner</option>
                              {
                               codeJournalResponse.map((code,index) => {
                                  return(
                                    <option value={code.id} key={index+1}>{code.code_journal}</option>
                                  )
                                })
                              }

                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                    </div>

                    <Row className="mt-2">
                      <Col sm={12} className="text-center">
                        <div className="d-flex gap-3">
                          <button
                            onClick={() => onDossierCreated()}
                            className="btn btn-danger rounded rounded-pill px-4">
                            Annuler
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary rounded rounded-pill px-4"
                            style={{
                              backgroundColor: "#068f7a",
                              borderColor: "#068f7a",
                            }}
                            disabled={
                              !facture.numero_facture || !facture.reference 
                            } // Add other fields as necessary
                          >
                            Créer
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {/* ... Other input fields ... */}
                </Form>
              
            
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalJournal;
