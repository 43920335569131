import { ApiMananger } from "actions/rtkquery";
import { GET_SALARIES_API_ROUTE} from "routes/api/endpoints";

const salarieApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({

    getSalaries: build.query({
        query: ({ dossier_id }) => `${GET_SALARIES_API_ROUTE}?dossier_id=${dossier_id}`,
        providesTags: ["INVALIDATE_SALARIES"],
    }),
    getSalarieId: build.query({
        query: ({ dossier_id, id }) => `${GET_SALARIES_API_ROUTE}?dossier_id=${dossier_id}&id=${id}`,
        providesTags: ["INVALIDATE_SALARIES"],
    }),
}),
});

export const {
useGetSalariesQuery,
useGetSalarieIdQuery
} = salarieApi;
