import { ApiMananger } from "actions/rtkquery";
import { GET_OCR_API_ROUTE, GET_ONE_OCR_API_ROUTE } from "routes/api/endpoints";

const ocrApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
       getOcr: build.query({
        query: () => GET_OCR_API_ROUTE,
        providesTags: ["INVALIDATE_OCR","INVALIDATE_DOSSIER"],
    }),
    getOcrId: build.query({
        query: ({ id }) => `${GET_ONE_OCR_API_ROUTE}${id}`,
        providesTags: ["INVALIDATE_OCR","INVALIDATE_DOSSIER"],
    }),
    
}),
});

export const {
//dossier
useGetOcrQuery,
useGetOcrIdQuery,
} = ocrApi;
