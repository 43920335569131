import React, {useState} from "react";
import HeaderFirst from "utils/HeaderFirst";
import "./style.css";
import TabSwitcher from "./TabSwitcher/TabSwitcher";
import LivreJournal from "./components/LivreJournal";
import GrandLivre from "./components/Grand_Livre";
import Balance from "./components/Balance";
import {useParams} from "react-router-dom";

const GenererEtats = () => {
  const {clientID: dossier_id} = useParams();
  const [currentTab, setCurrentTab] = useState("livre_journal");

  const nom = (
    <div style={{display: "flex"}}>
      <h4 className="titre-dossier" style={{}}>
        Génération des états
      </h4>
    </div>
  );
  const description = <div>Voici vos états financiers</div>;
  return (
    <div
      className="global"
      style={{
        backgroundColor: "white",
        minHeight: "500px",
        borderRadius: "20px",
        padding: "15px",
      }}>
      <div
        className="header pb-3"
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
          // padding: "13px",
        }}>
        <HeaderFirst nom={nom} description={description} />
      </div>

      <TabSwitcher onTabChange={setCurrentTab} />
      {currentTab === "livre_journal" && (
        <div style={{padding: "10px 0px"}}>

          <LivreJournal dossier_id={dossier_id} />
        </div>
      )}
      {currentTab === "grand_livre" && (
        <div style={{padding: "10px 0px"}}>
          <GrandLivre dossier_id={dossier_id} />
        </div>
      )}
      {currentTab === "balance" && (
        <div style={{padding: "10px 0px"}}>
          <Balance dossier_id={dossier_id} />
        </div>
      )}
    </div>
  );
};

export default GenererEtats;
