//import SpliTemplateScreen from 'layouts'
import SpliTemplateScreenCabinet from 'layouts/SplitTemplates/SpliTemplateScreenCabinet'
// import { useAppContext } from 'layouts/appConexte'
//import NavbarComponent from 'layouts/navbar/NavbarComponent'
import NavbarComponentCabinet from 'layouts/navbar/NavbarComponentCabinet'
import SidebarCabinetDossierComponent from 'layouts/sidebarDossierCient/SidebarCabinetDossierComponent'
// import SidebarDossierComponent from 'layouts/sidebarDossierCient/SidebarDossierComponent'
import React from 'react'
import { Outlet } from 'react-router-dom'

const DashboardAppShellCabinetClient = () => {
 
  return (
    <SpliTemplateScreenCabinet>
        <NavbarComponentCabinet />
        <SidebarCabinetDossierComponent
            activeLinkColor={"#FFFFFF"}
            activeLinkBgColor={"#009688"}
            colorOnHover={"white"}
            linkColor="#4B5563"
            color="#4B5563"
        />
      <Outlet />
    </SpliTemplateScreenCabinet>
      
  )
}

export default DashboardAppShellCabinetClient