// TransactionList.jsx
import React from 'react';
import TransactionTable from '../TransactionTable';
import TabSwitcher from '../TabSwitcher';
import TablePaginators from 'components/Pagination/TablePaginator';

const TransactionList = ({
  combinedData,
  paginationIndex,
  setPaginationIndex,
  dataLength,
  pageSize,
  setPageSize,
  onActionSelected,
  selectedRows,
  handleRowSelect,
  onEyeClick,
  onDeleteClick,
  currentTab,
  setCurrentTab,
  handleFormDepense,
  handleFormVente,
  ajoutFou,
  viewArchived,
}) => (
  <>
    <div className="HolderButtons">
      <TabSwitcher onTabChange={setCurrentTab} />
      {currentTab === 'out' && (
        <button
          type="button"
          className="btn btn-outline-dark"
          style={{
            borderRadius: '31px',
            border: '1px solid #AEAEAE',
            padding: '8px',
            ...ajoutFou,
          }}
          onClick={handleFormDepense}
        >
          <span>+ Ajouter une sortie</span>
        </button>
      )}
      {currentTab === 'in' && (
        <button
          type="button"
          className="btn btn-outline-dark"
          style={{
            borderRadius: '31px',
            border: '1px solid #AEAEAE',
            padding: '8px',
            ...ajoutFou,
          }}
          onClick={handleFormVente}
        >
          <span>+ Ajouter une entrée</span>
        </button>
      )}
    </div>

    {combinedData?.length > 0 ? (
      <TransactionTable
        data={combinedData}
        selectedRows={selectedRows}
        onSelectRow={handleRowSelect}
        onEyeClick={onEyeClick}
        onDeleteClick={onDeleteClick}
      />
    ) : (
      <div className="flex align-items-center text-center justify-content-center fs-5">
        {combinedData ? (
          <p>Aucune transaction à afficher.</p>
        ) : (
          <p>Chargement des données...</p>
        )}
      </div>
    )}

    {combinedData && dataLength > 0 && (
      <TablePaginators
        paginationIndex={paginationIndex}
        setPaginationIndex={setPaginationIndex}
        dataLength={dataLength}
        pageSize={pageSize}
        setPageSize={setPageSize}
        onActionSelected={onActionSelected}
        isViewingArchived={viewArchived}
      />
    )}
  </>
);

export default TransactionList;
