import React, { useState, useRef, useEffect } from 'react';
import banniereDefault from '../image/cover.png';
import profilImageDefault from '../image/profil.png';
import './Cover.css';
import { getUser } from 'redux/features/userSliceWithTokenValidation';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useGetDossierIdQuery } from 'actions/dossiers/queries';
import { useUpdateDossierClientMutation } from 'actions/dossiers/mutations';

const Cover = () => {
  const userStatus = useSelector(getUser);
  const comptableId = userStatus?.id;
  const { clientID: id } = useParams();
  const { data, refetch } = useGetDossierIdQuery({ comptableId, id });
  const [updateDossierMutation] = useUpdateDossierClientMutation();

  const [banniereImage, setBanniereImage] = useState(banniereDefault);
  const [profilImageUrl, setProfilImageUrl] = useState(profilImageDefault);
  const inputRef = useRef(null);
  const inputProfilRef = useRef(null);

  useEffect(() => {
    if (data && data.data && data.data.length > 0) {
      setProfilImageUrl(data.data[0].logo || profilImageDefault);
      setBanniereImage(data.data[0].bannier || banniereDefault);
    }
    refetch();
  }, [data]);

  const handleImageChange = async (event, type) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      const imageUrl = URL.createObjectURL(selectedImage);

      const formData = new FormData();
      formData.append(type === 'profil' ? 'logo' : 'bannier', selectedImage); // Adjusted key based on type
      formData.append('id', data.data[0].id);

      try {
        const response = await updateDossierMutation({
          id: id,
          body: formData,
          token: userStatus.access,
        }).unwrap();

        // Check for the expected response structure and content.
        if (response && response.status === 'success') {
          if (type === 'profil' && response.logo) {
            setProfilImageUrl(imageUrl); // Optimistically set URL, assuming success
          } else if (type === 'banniere' && response.bannier) {
            setBanniereImage(imageUrl);
          }
          toast.success(
            `${
              type.charAt(0).toUpperCase() + type.slice(1)
            } mise à jour réussie.`
          );
          refetch(); // Refetch data to confirm changes.
        } else {
          throw new Error(
            `Réponse inattendue: ${JSON.stringify(response)}`
          );
        }
      } catch (error) {
        console.error(`Echec de la mise à jour ${type}:`, error);
        // No need to revert imageUrl here as it's already optimistically set.
        toast.error(`Echec de la mise à jour ${type}.`);
      }
    }
  };

  return (
    <div className="header-title" style={{ padding: '15px', flex: 1 }}>
      <div style={{ position: 'relative', height: '210px' }}>
        <img
          src={banniereImage}
          alt="Bannière"
          style={{
            width: '100%',
            height: '170px',
            cursor: 'pointer',
            objectFit: 'cover',
          }}
          onClick={() => inputRef.current.click()}
        />
        <img
          src={profilImageUrl}
          className="profiles"
          alt="Profil"
          style={{
            width: '157px',
            height: '157px',
            borderRadius: '50%',
            border: '2px solid #fff',
            cursor: 'pointer',
          }}
          onClick={() => inputProfilRef.current.click()}
        />
      </div>

      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={(e) => handleImageChange(e, 'banniere')}
        ref={inputRef}
      />

      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={(e) => handleImageChange(e, 'profil')}
        ref={inputProfilRef}
      />
    </div>
  );
};

export default Cover;
