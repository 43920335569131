import { ApiMananger } from 'actions/rtkquery';
import { DELETE_ONE_TRANSACTIONS_API_ROUTE } from 'routes/api/endpoints';

const TransactionApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    deleteTransaction: build.mutation({
      query({ id, type }) {
        // Confirming the base URL is correct and appending the type and id for the specific transaction
        return {
          url: `${DELETE_ONE_TRANSACTIONS_API_ROUTE}${type}/${id}/`,
          method: 'DELETE',
        };
      },
      // Adjusting invalidatesTags to reflect the change in a specific type of transaction list or detail
      invalidatesTags: ['Transactions'],
    }),
  }),
});

export const { useDeleteTransactionMutation } = TransactionApi;
