import { useEffect, useState } from 'react';
import { useGetVenteQuery } from 'actions/ventes/queries';
import { useGetClientQuery } from 'actions/clients/queries'; // Assuming you have a similar hook for fetching client details
import { useUpdateVenteMutation } from 'actions/ventes/mutations';
import { toast } from 'react-toastify';

const useVentesData = (dossierId) => {
  const { data: venteResponse, isLoading: isLoadingVentes, refetch } = useGetVenteQuery({
    dossier_id: dossierId
    
  });
  const { data: clientResponse, isLoading: isLoadingClients } =
    useGetClientQuery({ dossier_id: dossierId }); // Fetch clients related to the dossier

  const [updatedVentes, setUpdatedVentes] = useState([]);
  const [updateVente] = useUpdateVenteMutation();

  useEffect(() => {
    const ventesData = venteResponse?.data;
    const clientData = clientResponse?.data;

    if (
      !isLoadingVentes &&
      !isLoadingClients &&
      ventesData &&
      Array.isArray(clientData)
    ) {
      const clientMap = clientData.reduce((acc, client) => {
        acc[client.id] = client.nom; // Assuming 'nom' is the name of the client
        return acc;
      }, {});

      const enhancedVentes = ventesData.map((vente) => ({
        ...vente,
        clientNom: clientMap[vente.clientId] || 'N/A', // Assuming each vente has a 'clientId' field
      }));

      setUpdatedVentes(enhancedVentes);
      refetch()
    }

    else{
      setUpdatedVentes([])
    }
  }, [isLoadingVentes, isLoadingClients, venteResponse, clientResponse]);

  

  const handleUpdateVente = async (venteId, updateData) => {
    try {
      await updateVente({ id: venteId, ...updateData }).unwrap();
      refetch()
    } catch (error) {
      toast.error(
        `Erreur lors de la mise à jour: ${error.data?.error || error.message}`
      );
      console.error(error);
    }
  };

  return { updatedVentes, handleUpdateVente };
};

export default useVentesData;
