import React, { useState } from "react";
import bleu from "./../../image/jaune.png";
import Rectangle from "../../image/bleu.png";
import Rectangl from "../../image/rouge.png";
import Rect from "../../image/rose.png";
import vertical from "../../image/vertical.png";
import Modal from "components/Modal";
import arrow from "../../image/arrow-left.png";
import { FormProvider, useForm } from "react-hook-form";
import { getUser } from "redux/features/userSliceWithTokenValidation";
import { useSelector } from "react-redux";
import { useGetDossierActivatedQuery, useGetDossierArchivedQuery, useGetDossierQuery } from "actions/dossiers/queries";
import { useNavigate } from "react-router-dom";
import { ARCHIVE_PATH, DESACTIVES_PATH, PRODUCTS_PATH } from "routes/navigation/navigationPaths";
import "./home.css"
import GridList from "react-flexible-list";
import SkeletonComponent from "utils/skeleton/skeleton";

function HomePage() {
  const navigate = useNavigate();
  const userStatus = useSelector(getUser);
  //  const {data} = useGetCurrentUser();
  //  console.log(data)
  const comptableId = userStatus?.id; 
  const token = userStatus?.access; 

  const { data, refetch, isLoading } = useGetDossierQuery({ comptableId, token }); 
  const { data:dataArchived } = useGetDossierArchivedQuery({ comptableId, archived: 'True' });
  const { data:dataActivated } = useGetDossierActivatedQuery({ comptableId, activated: 'True' });

  const datas = data?.count || 0;
  const dataArchive = dataArchived?.count || 0;
  const dataActivate = dataActivated?.count || 0;
 
  // / Filtrer les dossiers non archivés et non activés
const nonArchivedNonActivatedDossiers = data?.data.filter(dossier => !dossier.archived && !dossier.activated) || [];
const dossierActif = nonArchivedNonActivatedDossiers?.length;
  // const dossierActif = dataDearchive + dataDesctivate
  



  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const refetchData = async () => {
    if (data) {
      await refetch();
    }
  };

  

  const methods = useForm();
  return (
    <div
      className="global"
      style={{ backgroundColor: "white", minHeight: "800px", flexWrap: "wrap",borderRadius: '20px' }}
    >
      <div
        className="header"
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <div className="header-title" style={{ padding: "15px", flex: 1 }}>
          <h4 style={{ fontSize: "18px", margin: 0 }}>Bonjour {userStatus?.first_name} {userStatus?.last_name}</h4>
          <p style={{ fontSize: "16px" }}>
            Voici ce qui ce passe avec vos données financières
          </p>
        </div>
        <div>
        <button
            type="button"
            className="btn "
            style={{
              borderRadius: "31px",
              border: "1px solid #AEAEAE",
              padding: "8px",
            }}
            onClick={openModal}
          >
            <span style={ajoutFou}>+ Créer un dossier</span>
          </button>
        </div>
      </div>
      <FormProvider {...methods}>
      <Modal isOpen={isModalOpen} onClose={closeModal} onDossierCreated={refetchData}>
        <h2 style={{ color: " #009688", fontSize: "20px", fontWeight: 700 }}>
          <span>
            {" "}
            <img src={arrow} />
          </span>{" "}
          &nbsp;CREER UN NOUVEAU DOSSIER CLIENT
        </h2>
      </Modal>
      </FormProvider>

<div className="mt-3">
      {
      isLoading ? (
       <GridList cardWidth={200} resourceData={ [1,2,3,4,5]} resourceItem={ () => <SkeletonComponent />} />
         ) : (
          
          <GridList
          cardWidth={250}
          resourceData={[
            { id: 1, title: "Dossiers crées", image: bleu, image2:vertical, count: datas, onClick: PRODUCTS_PATH },
            { id: 2, title: "Dossiers actifs", image: Rectangle, image2:vertical, count: dossierActif, onClick: PRODUCTS_PATH },
            { id: 3, title: "Dossiers désactivés", image: Rect, image2:vertical, count: dataActivate, onClick: DESACTIVES_PATH },
            { id: 4, title: "Nombre de dossiers Archivés", image: Rectangl, image2:vertical ,count: dataArchive, onClick: ARCHIVE_PATH },
          ]}
          resourceItem={(item) => (
            <div className="cartes" style={cardStyle} onClick={() => navigate(item.onClick)}>
              <div style={{ display: "flex", alignItems: "center",justifyContent:'space-between', marginTop: '15px' }}>
                <div style={{display:"flex"}}>
                <div>
                  <img src={item.image} alt={item.title} style={{ marginRight: "10px"  }} />
                </div>
                <div>
                  <p style={{ fontWeight: "700", fontSize: "27px" }}>{item.count}</p>
                  <h2 style={{ fontSize: "12px", fontWeight: "700" }}>{item.title}</h2>
                </div>
                </div>
                <div style={{ }}>
                  <img src={item.image2} />
                </div>
              </div>
            </div>
          )}
        />
        
        )}
      </div>
    </div>
  );
}
const cardStyle = {
  backgroundColor: "#FFF",
  padding: "10px",
  borderRadius: "20px",
  // border: "1px solid gray",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)",
  minWidth: "200px",
  height: "120px",
  margin: "5px", // Adjust the margin to reduce spacing
};
const ajoutFou = {
  fontFamily: "Montserrat",
  fontWeight: '600',
  fontSize: "13px"
}

export default HomePage;
