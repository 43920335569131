import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetRolesQuery } from "actions/roles/queries";
import { useGetPermissionsQuery } from "actions/Permissions/queries";
import { useUpdatePermissionStatusMutation } from "actions/Permissions/mutations";
import Switch from "components/SwitchButton/Switch";

function Permissions() {
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const { data: roles } = useGetRolesQuery();
  const { data: rolePermissions, refetch } =
    useGetPermissionsQuery(selectedRoleId);
  const [updateRolePermissions] = useUpdatePermissionStatusMutation();

  useEffect(() => {
    if (roles && roles.length > 0 && !selectedRoleId) {
      const managerRole = roles.find((role) => role.title === "Associé");
      if (managerRole) {
        setSelectedRoleId(managerRole.id);
        refetch(managerRole.id);
      }
    }
  }, [roles, selectedRoleId, refetch]);

  useEffect(() => {
    if (rolePermissions) {
      setPermissions(rolePermissions.permissions);
    }
  }, [rolePermissions]);

  const handleRoleChange = (e) => {
    const roleId = e.target.value;
    setSelectedRoleId(roleId);
    if (roleId) {
      refetch(roleId);
    }
  };

  const handlePermissionToggle = async (permissionId) => {
    // Update local state immediately
    const updatedPermissions = permissions.map((permission) =>
      permission.permission__id === permissionId
        ? { ...permission, isActive: !permission.isActive }
        : permission
    );
    console.log(permissionId);
    setPermissions(updatedPermissions);
    console.log(updatedPermissions);
    // Find the toggled permission
    const toggledPermission = updatedPermissions.find(
      (permission) => permission.permission__id === permissionId
    );
    console.log(toggledPermission);

    if (!toggledPermission) {
      console.error("Permission non trouvée :", permissionId);
      return;
    }

    try {
      // Update permission status on the backend
      await updateRolePermissions({
        roleId: selectedRoleId,
        permId: permissionId,
        updateData: { isActive: toggledPermission.isActive },
      }).unwrap();
      toast.success("Permission mise à jour avec succès !");
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la permission :", error);
      toast.error("Échec de la mise à jour de la permission.");
      // Revert the change in the UI if the update fails
      setPermissions(permissions);
    }
  };

  const savePermissions = async () => {
    if (selectedRoleId) {
      try {
        const updatePromises = permissions.map((permission) =>
          updateRolePermissions({
            roleId: selectedRoleId,
            permId: permission.id,
            updateData: { isActive: permission.isActive },
          }).unwrap()
        );
        await Promise.all(updatePromises);
        toast.success("Permissions updated successfully!");
      } catch (error) {
        console.error("Error updating permissions:", error);
        toast.error("Failed to update permissions.");
      }
    }
  };

  return (
    <div>
      <div className="card row m-0 p-0 mx-3">
        <div className="card-header d-flex align-items-center justify-content-between p-4">
          <h3>Les permissions</h3>
          <div className="d-flex align-items-center gap-3">
            <label>Rôle</label>
            <select onChange={handleRoleChange} className="form-select">
              <option value="">Sélectionner un rôle</option>
              {roles
                .filter(
                  (role) => role.description && role.description.trim() !== ""
                )
                .map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.title}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="card-body">
          {selectedRoleId && (
            <div className="card p-4">
              <h4 className="my-3">Gestion générale des accès</h4>
              <div className="d-flex m-0 p-0 row-md-cols-2 row">
                {permissions.map((permission) => (
                  <div
                    key={permission.permission__id}
                    className=" ms-0 ps-0 col-lg-6 col-12 mb-3">
                    <div className="card h-lg-75 h-100 px-4 py-3 m-1 d-flex align-items-center flex-row justify-content-between gap-2">
                      <div className="flex flex-column">
                        <label>{permission.permission__name}</label>
                        <label style={{ color: "#909DAD" }}>
                          {permission.permission__description}
                        </label>
                      </div>
                      <div className="form-check form-switch d-flex align-items-center gap-2">
                        <Switch
                          isOn={permission.isActive}
                          handleToggle={() =>
                            handlePermissionToggle(permission.permission__id)}
                          colorOne="#068f7a"
                          colorTwo="#ccc"
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`permission-${permission.id}`}>
                          {permission.isActive ? "Actif" : "Inactif"}
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-end gap-2">
                <button
                  className="btn btn-primary d-none"
                  onClick={savePermissions}>
                  Sauvegarder
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Permissions;
